import {
  EnrollPreviewOutput,
  PurchaseBriefLineItemOutput,
  PurchaseBriefLineItemsGroupOutput,
  TimePeriodOutput,
} from 'services/api/client';
import dayjs, { OpUnitType, QUnitType } from 'dayjs';
import { DurationUnitsObjectType } from 'dayjs/plugin/duration';

const MonthlyPlanCost = 999;

const unitMapping: { [key: string]: keyof DurationUnitsObjectType } = {
  year: 'years',
  month: 'months',
  ms: 'milliseconds',
  second: 'seconds',
  minute: 'minutes',
  hour: 'hours',
  day: 'days',
  week: 'weeks',
};

function period2Months({ unit, number }: TimePeriodOutput, from?: number): number {
  const fromDate = from ? dayjs(from) : dayjs();
  if (!unitMapping[unit]) {
    throw new Error('Invalid unit');
  }
  return +fromDate.add(number, unitMapping[unit]).diff(fromDate, 'month', true).toFixed(2);
}

function dateDiff2Unit(unit: QUnitType | OpUnitType, to?: number, from?: number): number {
  const toDate = to ? dayjs(to) : dayjs();
  const fromDate = from ? dayjs(from) : dayjs();
  return +toDate.diff(fromDate, unit, true).toFixed(2);
}

function formatDateDiff(to: number, from?: number): string {
  const toDate = to ? dayjs(to) : dayjs();
  let fromDate = from ? dayjs(from) : dayjs();
  const date = [];
  const years = toDate.diff(fromDate, 'year');
  fromDate = fromDate.add(years, 'year');
  if (years) {
    date.push(`${years} year${years !== 1 ? 's' : ''}`);
  }
  // const months = toDate.diff(fromDate, 'month');
  // fromDate = fromDate.add(months, 'month');
  // if (months) {
  //   date.push(`${months} month${months > 1 ? 's' : ''}`);
  // }
  const days = Math.ceil(toDate.diff(fromDate, 'day', true));
  !!days && date.push(`${days} day${days !== 1 ? 's' : ''}`);
  return date.join(', ');
}

function planRetailPrice(months: number): number {
  return MonthlyPlanCost * months;
}

const fromCents = (price: number): number => +fromCentsFixed(price);
const fromCentsFixed = (price: number): string => (price / 100).toFixed(2);
const fromCentsLine = (price: number): string => {
  const negative = price < 0;
  if (negative) price *= -1;
  return `${negative ? '-' : ''}$${fromCentsFixed(price)}`;
};

const convertPrice = (price: number, isFeatured = false, period = 12): number => (isFeatured ? price / period : price);

const getConvertedPrice = (price: number, isFeatured = false, period = 12): string => {
  const negative = price < 0;
  if (negative) {
    price *= -1;
  }
  return `${negative ? '-' : ''}$${(Math.floor(convertPrice(price, isFeatured, period)) / 100).toFixed(2)}`;
};

const calculatePrice = (
  isValidating: boolean | undefined,
  enrollPreview: EnrollPreviewOutput | undefined,
  donateCheckbox: string,
  donateInput: number
): Record<string, number | string> => {
  if (isValidating || !enrollPreview)
    return {
      totalPriceConverted: '',
      subTotalHouseHolder: 0,
      subTotalUserConverted: '',
    };
  const { lines } = enrollPreview;

  const subTotalUserConverted = getConvertedPrice(lines.primary.total.amount);
  const subTotalHouseHolder = lines.household_members.reduce(
    (prevValue, currValue) => prevValue + currValue.total.amount,
    0
  );
  const contribution = donateCheckbox !== 'other' ? Number(donateCheckbox) * 100 : donateInput * 100;

  const contributionPrice = lines?.contribution[0] ? lines?.contribution[0].price.amount : 0;
  const totalPrice = lines.total.amount + Number(contribution) - contributionPrice;
  const totalPriceConverted = getConvertedPrice(totalPrice);

  return {
    totalPriceConverted,
    subTotalHouseHolder,
    subTotalUserConverted,
  };
};

const setDateValue = (value: number, includeTime: boolean = false, override?: Intl.DateTimeFormatOptions): string => {
  return new Date(value).toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    ...(includeTime
      ? {
          hour: '2-digit',
          minute: '2-digit',
        }
      : {}),
    ...(override ?? {}),
  });
};

const findPrice = (
  member: PurchaseBriefLineItemsGroupOutput,
  type: string,
  free: boolean = false
): PurchaseBriefLineItemOutput =>
  member.items.find((item) => item.type === type && (free || item.price.amount !== 0)) as PurchaseBriefLineItemOutput;

const states = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  AS: 'American Samoa',
  GU: 'Guam',
  MH: 'Marshall Islands',
  FM: 'Micronesia',
  MP: 'Northern Mariana Islands',
  PW: 'Palau',
  PR: 'Puerto Rico',
  VI: 'Virgin Islands',
};

const stateForces = {
  AA: 'Armed Forces America (AA)',
  AE: 'Armed Forces (AE)',
  AP: 'Armed Forces Pacific (AP)',
};

const validationPattern = {
  first_name: {
    value: /^[ a-zA-Z'.-]*$/i,
    message: 'Please enter a valid First Name. Should contain only alphabetical characters and spaces.',
  },
  last_name: {
    value: /^[ a-zA-Z'.-]*$/i,
    message: 'Please enter a valid Last Name. Should contain only alphabetical characters and spaces.',
  },
  email: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'Please enter a valid Email address.',
  },
  postal_code: {
    value: /^\d{5}(-\d{4})?$/i,
    message: 'Value has invalid type. ZIP or ZIP+4 code expected.',
  },
  donate_other: {
    value: /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/i,
    message: 'Value should be greater than 0.',
  },
};

function sleep(ms: number): Promise<NodeJS.Timeout> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const isObject = (data: unknown, checkEmpty?: boolean): boolean => {
  return (
    !!data && 'object' === typeof data && Object === data!.constructor && (!checkEmpty || !!Object.keys(data!).length)
  );
};

const filterEmptyData = (data: Data, level = 0): any => {
  if (isObject(data)) {
    const ret: Data = Object.keys(data).reduce((obj, attr) => {
      const ret = filterEmptyData(data[attr], level + 1);
      return { ...obj, [attr]: ret };
    }, {});
    return Object.keys(ret).length || level === 0 ? ret : null;
  } else if (Array.isArray(data)) {
    const ret: Data[] = data.reduce((arr, item) => {
      const ret = filterEmptyData(item, level + 1);
      return ret || ret === false ? [...arr, ret] : arr;
    }, []);
    return ret.length || level === 0 ? ret : null;
  }
  return data || data === false ? data : null;
};

// eslint-disable-next-line
function generateUniqueId(input: any): string {
  const inputString = JSON.stringify(input);
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < inputString.length; i++) {
    const char = inputString.charCodeAt(i);
    result += characters.charAt(char % characters.length);
  }
  return result;
}

export function isEnumValue<T>(value: T, enumObject: Record<string, T>): value is T {
  return Object.values(enumObject).includes(value);
}

export {
  fromCents,
  fromCentsFixed,
  fromCentsLine,
  getConvertedPrice,
  calculatePrice,
  findPrice,
  setDateValue,
  sleep,
  generateUniqueId,
  states,
  stateForces,
  validationPattern,
  isObject,
  filterEmptyData,
  period2Months,
  dateDiff2Unit,
  formatDateDiff,
  planRetailPrice,
};
