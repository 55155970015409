/* tslint:disable */
/* eslint-disable */
/**
 * WeSalute
 * The WeSalute API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AbenitySsoOutput
 */
export interface AbenitySsoOutput {
    /**
     * Abenity SSO token url.
     * @type {string}
     * @memberof AbenitySsoOutput
     */
    'token_url': string;
}
/**
 * 
 * @export
 * @interface AddressInput
 */
export interface AddressInput {
    /**
     * Address type.
     * @type {string}
     * @memberof AddressInput
     */
    'type': AddressInputTypeEnum;
    /**
     * Street address.
     * @type {string}
     * @memberof AddressInput
     */
    'line1': string;
    /**
     * Apartment, suite, box number, etc.
     * @type {string}
     * @memberof AddressInput
     */
    'line2'?: string | null;
    /**
     * Postal code, ZIP (or ZIP+4) code.
     * @type {string}
     * @memberof AddressInput
     */
    'postal_code': string;
    /**
     * Depending on address type: - home: city or town; - post: \"APO\", \"FPO\", or \"DPO\".
     * @type {string}
     * @memberof AddressInput
     */
    'city': string;
    /**
     * State, province, region (ISO code when available).
     * @type {string}
     * @memberof AddressInput
     */
    'state': string;
    /**
     * Country (2 character ISO code).
     * @type {string}
     * @memberof AddressInput
     */
    'country'?: string | null;
}

export const AddressInputTypeEnum = {
    Home: 'home',
    Post: 'post'
} as const;

export type AddressInputTypeEnum = typeof AddressInputTypeEnum[keyof typeof AddressInputTypeEnum];

/**
 * 
 * @export
 * @interface AddressOutput
 */
export interface AddressOutput {
    /**
     * Address type.
     * @type {string}
     * @memberof AddressOutput
     */
    'type': AddressOutputTypeEnum;
    /**
     * Street address.
     * @type {string}
     * @memberof AddressOutput
     */
    'line1': string;
    /**
     * Apartment, suite, box number, etc.
     * @type {string}
     * @memberof AddressOutput
     */
    'line2': string | null;
    /**
     * Postal code, ZIP (or ZIP+4) code.
     * @type {string}
     * @memberof AddressOutput
     */
    'postal_code': string;
    /**
     * Depending on address type: - home: city or town; - post: \"APO\", \"FPO\", or \"DPO\".
     * @type {string}
     * @memberof AddressOutput
     */
    'city': string;
    /**
     * State, province, region (ISO code when available).
     * @type {string}
     * @memberof AddressOutput
     */
    'state': string;
    /**
     * Country (2 character ISO code).
     * @type {string}
     * @memberof AddressOutput
     */
    'country': string;
}

export const AddressOutputTypeEnum = {
    Home: 'home',
    Post: 'post'
} as const;

export type AddressOutputTypeEnum = typeof AddressOutputTypeEnum[keyof typeof AddressOutputTypeEnum];

/**
 * 
 * @export
 * @interface BrandQueueOutput
 */
export interface BrandQueueOutput {
    /**
     * Brand queue id.
     * @type {string}
     * @memberof BrandQueueOutput
     */
    'id': string;
    /**
     * Brand queue title.
     * @type {string}
     * @memberof BrandQueueOutput
     */
    'title': string;
    /**
     * Brand queue machine name.
     * @type {string}
     * @memberof BrandQueueOutput
     */
    'machine_name': string;
    /**
     * Brand queue items.
     * @type {Array<BrandQueueOutputBrand>}
     * @memberof BrandQueueOutput
     */
    'brands': Array<BrandQueueOutputBrand>;
}
/**
 * 
 * @export
 * @interface BrandQueueOutputBrand
 */
export interface BrandQueueOutputBrand {
    /**
     * Brand queue item action.
     * @type {string}
     * @memberof BrandQueueOutputBrand
     */
    'action': string;
    /**
     * 
     * @type {BrandQueueOutputBrandImage}
     * @memberof BrandQueueOutputBrand
     */
    'image': BrandQueueOutputBrandImage;
    /**
     * Brand queue item URL.
     * @type {string}
     * @memberof BrandQueueOutputBrand
     */
    'url': string;
}
/**
 * Brand queue item image.
 * @export
 * @interface BrandQueueOutputBrandImage
 */
export interface BrandQueueOutputBrandImage {
    /**
     * Brand queue item image alt.
     * @type {string}
     * @memberof BrandQueueOutputBrandImage
     */
    'alt'?: string;
    /**
     * Brand queue item image href.
     * @type {string}
     * @memberof BrandQueueOutputBrandImage
     */
    'href': string;
}
/**
 * 
 * @export
 * @interface CardReplacement200Response
 */
export interface CardReplacement200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof CardReplacement200Response
     */
    'status': CardReplacement200ResponseStatusEnum;
    /**
     * 
     * @type {CardReplacement200ResponseAllOfData}
     * @memberof CardReplacement200Response
     */
    'data': CardReplacement200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof CardReplacement200Response
     */
    'warnings'?: Array<string>;
}

export const CardReplacement200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type CardReplacement200ResponseStatusEnum = typeof CardReplacement200ResponseStatusEnum[keyof typeof CardReplacement200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface CardReplacement200ResponseAllOf
 */
export interface CardReplacement200ResponseAllOf {
    /**
     * 
     * @type {CardReplacement200ResponseAllOfData}
     * @memberof CardReplacement200ResponseAllOf
     */
    'data'?: CardReplacement200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface CardReplacement200ResponseAllOfData
 */
export interface CardReplacement200ResponseAllOfData {
    /**
     * 
     * @type {EnrollPreviewOutputLines}
     * @memberof CardReplacement200ResponseAllOfData
     */
    'lines': EnrollPreviewOutputLines;
    /**
     * 
     * @type {CardReplacementOutputExtra}
     * @memberof CardReplacement200ResponseAllOfData
     */
    'extra'?: CardReplacementOutputExtra;
}
/**
 * 
 * @export
 * @interface CardReplacementInfo200Response
 */
export interface CardReplacementInfo200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof CardReplacementInfo200Response
     */
    'status': CardReplacementInfo200ResponseStatusEnum;
    /**
     * 
     * @type {CardReplacementInfo200ResponseAllOfData}
     * @memberof CardReplacementInfo200Response
     */
    'data': CardReplacementInfo200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof CardReplacementInfo200Response
     */
    'warnings'?: Array<string>;
}

export const CardReplacementInfo200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type CardReplacementInfo200ResponseStatusEnum = typeof CardReplacementInfo200ResponseStatusEnum[keyof typeof CardReplacementInfo200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface CardReplacementInfo200ResponseAllOf
 */
export interface CardReplacementInfo200ResponseAllOf {
    /**
     * 
     * @type {CardReplacementInfo200ResponseAllOfData}
     * @memberof CardReplacementInfo200ResponseAllOf
     */
    'data'?: CardReplacementInfo200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface CardReplacementInfo200ResponseAllOfData
 */
export interface CardReplacementInfo200ResponseAllOfData {
    /**
     * Is member possible to make card replacement.
     * @type {boolean}
     * @memberof CardReplacementInfo200ResponseAllOfData
     */
    'replacement_allowed': boolean;
    /**
     * The list of Users.
     * @type {Array<Users>}
     * @memberof CardReplacementInfo200ResponseAllOfData
     */
    'users': Array<Users>;
    /**
     * Config title.
     * @type {string}
     * @memberof CardReplacementInfo200ResponseAllOfData
     */
    'title': string;
    /**
     * Config description.
     * @type {string}
     * @memberof CardReplacementInfo200ResponseAllOfData
     */
    'description': string;
    /**
     * Config process fee.
     * @type {number}
     * @memberof CardReplacementInfo200ResponseAllOfData
     */
    'process_fee': number;
    /**
     * Config replacement fee.
     * @type {number}
     * @memberof CardReplacementInfo200ResponseAllOfData
     */
    'replacement_fee': number;
}
/**
 * 
 * @export
 * @interface CardReplacementInfoOutput
 */
export interface CardReplacementInfoOutput {
    /**
     * Is member possible to make card replacement.
     * @type {boolean}
     * @memberof CardReplacementInfoOutput
     */
    'replacement_allowed': boolean;
    /**
     * The list of Users.
     * @type {Array<Users>}
     * @memberof CardReplacementInfoOutput
     */
    'users': Array<Users>;
    /**
     * Config title.
     * @type {string}
     * @memberof CardReplacementInfoOutput
     */
    'title': string;
    /**
     * Config description.
     * @type {string}
     * @memberof CardReplacementInfoOutput
     */
    'description': string;
    /**
     * Config process fee.
     * @type {number}
     * @memberof CardReplacementInfoOutput
     */
    'process_fee': number;
    /**
     * Config replacement fee.
     * @type {number}
     * @memberof CardReplacementInfoOutput
     */
    'replacement_fee': number;
}
/**
 * 
 * @export
 * @interface CardReplacementOutput
 */
export interface CardReplacementOutput {
    /**
     * 
     * @type {EnrollPreviewOutputLines}
     * @memberof CardReplacementOutput
     */
    'lines': EnrollPreviewOutputLines;
    /**
     * 
     * @type {CardReplacementOutputExtra}
     * @memberof CardReplacementOutput
     */
    'extra'?: CardReplacementOutputExtra;
}
/**
 * Extra info.
 * @export
 * @interface CardReplacementOutputExtra
 */
export interface CardReplacementOutputExtra {
    /**
     * Invoice ID.
     * @type {string}
     * @memberof CardReplacementOutputExtra
     */
    'invoice_id': string | null;
    /**
     * 
     * @type {EnrollOutputSubscriptionOrderCompletedMessage}
     * @memberof CardReplacementOutputExtra
     */
    'order_completed_message'?: EnrollOutputSubscriptionOrderCompletedMessage;
}
/**
 * 
 * @export
 * @interface CardReplacementPreview200Response
 */
export interface CardReplacementPreview200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof CardReplacementPreview200Response
     */
    'status': CardReplacementPreview200ResponseStatusEnum;
    /**
     * 
     * @type {CardReplacementPreview200ResponseAllOfData}
     * @memberof CardReplacementPreview200Response
     */
    'data': CardReplacementPreview200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof CardReplacementPreview200Response
     */
    'warnings'?: Array<string>;
}

export const CardReplacementPreview200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type CardReplacementPreview200ResponseStatusEnum = typeof CardReplacementPreview200ResponseStatusEnum[keyof typeof CardReplacementPreview200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface CardReplacementPreview200ResponseAllOf
 */
export interface CardReplacementPreview200ResponseAllOf {
    /**
     * 
     * @type {CardReplacementPreview200ResponseAllOfData}
     * @memberof CardReplacementPreview200ResponseAllOf
     */
    'data'?: CardReplacementPreview200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface CardReplacementPreview200ResponseAllOfData
 */
export interface CardReplacementPreview200ResponseAllOfData {
    /**
     * 
     * @type {EnrollPreviewOutputLines}
     * @memberof CardReplacementPreview200ResponseAllOfData
     */
    'lines': EnrollPreviewOutputLines;
}
/**
 * 
 * @export
 * @interface CardReplacementPreviewInput
 */
export interface CardReplacementPreviewInput {
    /**
     * Primary would like to make card replacement.
     * @type {boolean}
     * @memberof CardReplacementPreviewInput
     */
    'primary': boolean;
    /**
     * Household ID list which would like to get card replacement.
     * @type {Array<string>}
     * @memberof CardReplacementPreviewInput
     */
    'household_members': Array<string>;
    /**
     * Payment method.
     * @type {string}
     * @memberof CardReplacementPreviewInput
     */
    'payment_method'?: string | null;
    /**
     * 
     * @type {CardReplacementPreviewInputAdmin}
     * @memberof CardReplacementPreviewInput
     */
    'admin'?: CardReplacementPreviewInputAdmin;
}
/**
 * Admin settings during purchase flow.
 * @export
 * @interface CardReplacementPreviewInputAdmin
 */
export interface CardReplacementPreviewInputAdmin {
    /**
     * Indicator whether there should be no charge.
     * @type {boolean}
     * @memberof CardReplacementPreviewInputAdmin
     */
    'no_charge': boolean;
}
/**
 * 
 * @export
 * @interface CardReplacementPreviewInputAdminConfig
 */
export interface CardReplacementPreviewInputAdminConfig {
    /**
     * Indicator whether there should be no charge.
     * @type {boolean}
     * @memberof CardReplacementPreviewInputAdminConfig
     */
    'no_charge': boolean;
}
/**
 * 
 * @export
 * @interface CardReplacementPreviewOutput
 */
export interface CardReplacementPreviewOutput {
    /**
     * 
     * @type {EnrollPreviewOutputLines}
     * @memberof CardReplacementPreviewOutput
     */
    'lines': EnrollPreviewOutputLines;
}
/**
 * 
 * @export
 * @interface CheckoutSegmentProductOutput
 */
export interface CheckoutSegmentProductOutput {
    /**
     * Product category.
     * @type {string}
     * @memberof CheckoutSegmentProductOutput
     */
    'category': string;
    /**
     * Product description.
     * @type {string}
     * @memberof CheckoutSegmentProductOutput
     */
    'description': string;
    /**
     * SKU.
     * @type {string}
     * @memberof CheckoutSegmentProductOutput
     */
    'sku': string;
    /**
     * Member ID.
     * @type {string}
     * @memberof CheckoutSegmentProductOutput
     */
    'memberId': string;
    /**
     * Product price.
     * @type {number}
     * @memberof CheckoutSegmentProductOutput
     */
    'price': number;
    /**
     * Product quantity.
     * @type {number}
     * @memberof CheckoutSegmentProductOutput
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface ContributionInput
 */
export interface ContributionInput {
    /**
     * Payment method ID.
     * @type {string}
     * @memberof ContributionInput
     */
    'payment_method'?: string;
    /**
     * Contribution amount in cents.
     * @type {number}
     * @memberof ContributionInput
     */
    'contribution'?: number;
}
/**
 * 
 * @export
 * @interface DataPrivacy200Response
 */
export interface DataPrivacy200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof DataPrivacy200Response
     */
    'status': DataPrivacy200ResponseStatusEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DataPrivacy200Response
     */
    'data': { [key: string]: string; };
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof DataPrivacy200Response
     */
    'warnings'?: Array<string>;
}

export const DataPrivacy200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type DataPrivacy200ResponseStatusEnum = typeof DataPrivacy200ResponseStatusEnum[keyof typeof DataPrivacy200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface DataPrivacy200ResponseAllOf
 */
export interface DataPrivacy200ResponseAllOf {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DataPrivacy200ResponseAllOf
     */
    'data'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Delete200Response
 */
export interface Delete200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof Delete200Response
     */
    'status': Delete200ResponseStatusEnum;
    /**
     * 
     * @type {object}
     * @memberof Delete200Response
     */
    'data': object;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof Delete200Response
     */
    'warnings'?: Array<string>;
}

export const Delete200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type Delete200ResponseStatusEnum = typeof Delete200ResponseStatusEnum[keyof typeof Delete200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface Delete200ResponseAllOf
 */
export interface Delete200ResponseAllOf {
    /**
     * 
     * @type {object}
     * @memberof Delete200ResponseAllOf
     */
    'data'?: object;
}
/**
 * 
 * @export
 * @interface EmailsInfo200Response
 */
export interface EmailsInfo200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof EmailsInfo200Response
     */
    'status': EmailsInfo200ResponseStatusEnum;
    /**
     * 
     * @type {EmailsInfo200ResponseAllOfData}
     * @memberof EmailsInfo200Response
     */
    'data': EmailsInfo200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof EmailsInfo200Response
     */
    'warnings'?: Array<string>;
}

export const EmailsInfo200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type EmailsInfo200ResponseStatusEnum = typeof EmailsInfo200ResponseStatusEnum[keyof typeof EmailsInfo200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface EmailsInfo200ResponseAllOf
 */
export interface EmailsInfo200ResponseAllOf {
    /**
     * 
     * @type {EmailsInfo200ResponseAllOfData}
     * @memberof EmailsInfo200ResponseAllOf
     */
    'data'?: EmailsInfo200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface EmailsInfo200ResponseAllOfData
 */
export interface EmailsInfo200ResponseAllOfData {
    /**
     * Status code.
     * @type {number}
     * @memberof EmailsInfo200ResponseAllOfData
     */
    'statusCode': number;
}
/**
 * 
 * @export
 * @interface EnrollInfo200Response
 */
export interface EnrollInfo200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof EnrollInfo200Response
     */
    'status': EnrollInfo200ResponseStatusEnum;
    /**
     * 
     * @type {EnrollInfo200ResponseAllOfData}
     * @memberof EnrollInfo200Response
     */
    'data': EnrollInfo200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof EnrollInfo200Response
     */
    'warnings'?: Array<string>;
}

export const EnrollInfo200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type EnrollInfo200ResponseStatusEnum = typeof EnrollInfo200ResponseStatusEnum[keyof typeof EnrollInfo200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface EnrollInfo200ResponseAllOf
 */
export interface EnrollInfo200ResponseAllOf {
    /**
     * 
     * @type {EnrollInfo200ResponseAllOfData}
     * @memberof EnrollInfo200ResponseAllOf
     */
    'data'?: EnrollInfo200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface EnrollInfo200ResponseAllOfData
 */
export interface EnrollInfo200ResponseAllOfData {
    /**
     * Military ranks info.
     * @type {{ [key: string]: string; }}
     * @memberof EnrollInfo200ResponseAllOfData
     */
    'ranks': { [key: string]: string; };
    /**
     * Military branches info.
     * @type {{ [key: string]: string; }}
     * @memberof EnrollInfo200ResponseAllOfData
     */
    'branches': { [key: string]: string; };
    /**
     * 
     * @type {PurchaseBriefOverviewOutputUser}
     * @memberof EnrollInfo200ResponseAllOfData
     */
    'user': PurchaseBriefOverviewOutputUser;
    /**
     * 
     * @type {PurchaseBriefOverviewOutputHouseholdMembers}
     * @memberof EnrollInfo200ResponseAllOfData
     */
    'household_members': PurchaseBriefOverviewOutputHouseholdMembers;
    /**
     * 
     * @type {PurchaseBriefOverviewOutputCard}
     * @memberof EnrollInfo200ResponseAllOfData
     */
    'card': PurchaseBriefOverviewOutputCard | null;
    /**
     * Available plans.
     * @type {Array<PurchaseBriefPlanOutput>}
     * @memberof EnrollInfo200ResponseAllOfData
     */
    'plans': Array<PurchaseBriefPlanOutput>;
    /**
     * 
     * @type {PurchaseBriefOverviewOutputBrandQueue}
     * @memberof EnrollInfo200ResponseAllOfData
     */
    'brand_queue': PurchaseBriefOverviewOutputBrandQueue | null;
    /**
     * 
     * @type {PurchaseBriefOverviewOutputPromotion}
     * @memberof EnrollInfo200ResponseAllOfData
     */
    'promotion': PurchaseBriefOverviewOutputPromotion | null;
    /**
     * 
     * @type {PurchaseBriefOverviewOutputConfig}
     * @memberof EnrollInfo200ResponseAllOfData
     */
    'config': PurchaseBriefOverviewOutputConfig;
}
/**
 * 
 * @export
 * @interface EnrollInput
 */
export interface EnrollInput {
    /**
     * Flag indicating whether to cancel auto-renew of subscription/TP.
     * @type {string}
     * @memberof EnrollInput
     */
    'cancel'?: EnrollInputCancelEnum;
    /**
     * Household members info.
     * @type {Array<EnrollInputHouseholdMembersInner>}
     * @memberof EnrollInput
     */
    'household_members'?: Array<EnrollInputHouseholdMembersInner>;
    /**
     * Plan ID.
     * @type {string}
     * @memberof EnrollInput
     */
    'plan': string;
    /**
     * Subscription extension product ID.
     * @type {string}
     * @memberof EnrollInput
     */
    'subscription_extension'?: string;
    /**
     * Travel protection product ID.
     * @type {string}
     * @memberof EnrollInput
     */
    'travel_protection'?: string | null;
    /**
     * Contribution amount in cents.
     * @type {number}
     * @memberof EnrollInput
     */
    'contribution'?: number;
    /**
     * 
     * @type {EnrollInputAdmin}
     * @memberof EnrollInput
     */
    'admin'?: EnrollInputAdmin;
}

export const EnrollInputCancelEnum = {
    Plan: 'plan',
    TravelProtection: 'travel_protection'
} as const;

export type EnrollInputCancelEnum = typeof EnrollInputCancelEnum[keyof typeof EnrollInputCancelEnum];

/**
 * Admin settings during purchase flow.
 * @export
 * @interface EnrollInputAdmin
 */
export interface EnrollInputAdmin {
    /**
     * Indicator whether there should be no charge.
     * @type {boolean}
     * @memberof EnrollInputAdmin
     */
    'no_charge': boolean;
}
/**
 * @type EnrollInputHouseholdMembersInner
 * @export
 */
export type EnrollInputHouseholdMembersInner = MemberSubscriptionHouseholdMemberExistingInput | PurchaseHouseholdMemberNewInput;

/**
 * 
 * @export
 * @interface EnrollMrpOutput
 */
export interface EnrollMrpOutput {
    /**
     * The MRP program UUID.
     * @type {string}
     * @memberof EnrollMrpOutput
     */
    'mrp'?: string;
    /**
     * Redirect URL.
     * @type {string}
     * @memberof EnrollMrpOutput
     */
    'redirect_url'?: string;
}
/**
 * 
 * @export
 * @interface EnrollOutput
 */
export interface EnrollOutput {
    /**
     * 
     * @type {EnrollOutputUser}
     * @memberof EnrollOutput
     */
    'user': EnrollOutputUser;
    /**
     * 
     * @type {EnrollOutputSubscription}
     * @memberof EnrollOutput
     */
    'subscription': EnrollOutputSubscription;
}
/**
 * Subscription info.
 * @export
 * @interface EnrollOutputSubscription
 */
export interface EnrollOutputSubscription {
    /**
     * Invoice ID.
     * @type {string}
     * @memberof EnrollOutputSubscription
     */
    'invoice_id': string | null;
    /**
     * 
     * @type {EnrollOutputSubscriptionOrderCompletedMessage}
     * @memberof EnrollOutputSubscription
     */
    'order_completed_message'?: EnrollOutputSubscriptionOrderCompletedMessage;
    /**
     * Plan type.
     * @type {string}
     * @memberof EnrollOutputSubscription
     */
    'plan_type': EnrollOutputSubscriptionPlanTypeEnum;
}

export const EnrollOutputSubscriptionPlanTypeEnum = {
    Trial: 'trial',
    Year: 'year',
    Month: 'month'
} as const;

export type EnrollOutputSubscriptionPlanTypeEnum = typeof EnrollOutputSubscriptionPlanTypeEnum[keyof typeof EnrollOutputSubscriptionPlanTypeEnum];

/**
 * Order completed segment event message.
 * @export
 * @interface EnrollOutputSubscriptionOrderCompletedMessage
 */
export interface EnrollOutputSubscriptionOrderCompletedMessage {
    /**
     * 
     * @type {OrderCompletedSegmentMessageOutputProperties}
     * @memberof EnrollOutputSubscriptionOrderCompletedMessage
     */
    'properties': OrderCompletedSegmentMessageOutputProperties;
    /**
     * Event name.
     * @type {string}
     * @memberof EnrollOutputSubscriptionOrderCompletedMessage
     */
    'event': string;
    /**
     * The member ID of the user.
     * @type {string}
     * @memberof EnrollOutputSubscriptionOrderCompletedMessage
     */
    'userId': string;
}
/**
 * User data.
 * @export
 * @interface EnrollOutputUser
 */
export interface EnrollOutputUser {
    /**
     * User affinity.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'affinity': EnrollOutputUserAffinityEnum;
    /**
     * User gender.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'gender': EnrollOutputUserGenderEnum;
    /**
     * User gender (other). Required if gender is \"other\".
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'gender_other': string | null;
    /**
     * User honorific.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'honorific': EnrollOutputUserHonorificEnum;
    /**
     * User ID.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'id': string;
    /**
     * 
     * @type {UserOutputActiveDuty}
     * @memberof EnrollOutputUser
     */
    'active_duty': UserOutputActiveDuty;
    /**
     * User tier.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'tier': EnrollOutputUserTierEnum;
    /**
     * User first name.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'last_name': string;
    /**
     * User middle name.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'middle_name': string | null;
    /**
     * 
     * @type {FulfillmentRecordOutputAddress}
     * @memberof EnrollOutputUser
     */
    'address': FulfillmentRecordOutputAddress | null;
    /**
     * User phone.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'phone': string | null;
    /**
     * 
     * @type {UserOutputBilling}
     * @memberof EnrollOutputUser
     */
    'billing': UserOutputBilling | null;
    /**
     * User date of birth.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'dob': string | null;
    /**
     * 
     * @type {UserOutputProfilePhoto}
     * @memberof EnrollOutputUser
     */
    'profile_photo': UserOutputProfilePhoto;
    /**
     * 
     * @type {UserOutputReplacementCard}
     * @memberof EnrollOutputUser
     */
    'replacement_card': UserOutputReplacementCard;
    /**
     * User timezone.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'timezone': string | null;
    /**
     * 
     * @type {UserOutputMilitary}
     * @memberof EnrollOutputUser
     */
    'military': UserOutputMilitary;
    /**
     * 
     * @type {UserOutputMultiFactorAuth}
     * @memberof EnrollOutputUser
     */
    'multi_factor_auth': UserOutputMultiFactorAuth;
    /**
     * 
     * @type {UserOutputVerification}
     * @memberof EnrollOutputUser
     */
    'verification': UserOutputVerification;
    /**
     * 
     * @type {UserOutputIdentification}
     * @memberof EnrollOutputUser
     */
    'identification': UserOutputIdentification;
    /**
     * 
     * @type {UserOutputHouseholdMembers}
     * @memberof EnrollOutputUser
     */
    'household_members': UserOutputHouseholdMembers;
    /**
     * 
     * @type {UserOutputHouseholdMember}
     * @memberof EnrollOutputUser
     */
    'household_member': UserOutputHouseholdMember | null;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'email': string | null;
    /**
     * User MRP program.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'mrp': string | null;
    /**
     * User MRP brand.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'mrp_brand': string | null;
    /**
     * User MRP company.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'mrp_company': string | null;
    /**
     * User MRP name.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'mrp_name': string | null;
    /**
     * 
     * @type {UserOutputPromotion}
     * @memberof EnrollOutputUser
     */
    'promotion': UserOutputPromotion;
    /**
     * 
     * @type {UserOutputSubscription}
     * @memberof EnrollOutputUser
     */
    'subscription': UserOutputSubscription | null;
    /**
     * The flag indicating whether the last user payment attempt failed.
     * @type {boolean}
     * @memberof EnrollOutputUser
     */
    'payment_failed': boolean;
    /**
     * An array of extra roles of the user.
     * @type {Array<string>}
     * @memberof EnrollOutputUser
     */
    'roles': Array<string>;
    /**
     * User member ID.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'member_id': string;
    /**
     * User travel code.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'travel_code': string;
    /**
     * Username of the user. Email or auto generated string (first name + last name + random digits) for household member that are not above 13 y.o.
     * @type {string}
     * @memberof EnrollOutputUser
     */
    'username': string;
    /**
     * The flag indicating whether the user has purchased a subscription.
     * @type {boolean}
     * @memberof EnrollOutputUser
     */
    'subscription_purchased': boolean;
    /**
     * The time when the user has purchased a subscription.
     * @type {number}
     * @memberof EnrollOutputUser
     */
    'subscription_purchased_time': number;
    /**
     * The flag indicating whether the user has purchased a travel protection product.
     * @type {boolean}
     * @memberof EnrollOutputUser
     */
    'tp_purchased': boolean;
    /**
     * The time when the user has purchased a travel protection product.
     * @type {number}
     * @memberof EnrollOutputUser
     */
    'tp_purchased_time': number;
    /**
     * The time when the user has purchased a subscription extension product.
     * @type {number}
     * @memberof EnrollOutputUser
     */
    'pe_purchased_time': number;
    /**
     * The flag indicating whether the user has used a trial subscription.
     * @type {boolean}
     * @memberof EnrollOutputUser
     */
    'trial_used': boolean;
    /**
     * 
     * @type {UserOutputContactPreferences}
     * @memberof EnrollOutputUser
     */
    'contact_preferences': UserOutputContactPreferences;
    /**
     * The date the user was created.
     * @type {number}
     * @memberof EnrollOutputUser
     */
    'created': number;
    /**
     * The date the user was last updated.
     * @type {number}
     * @memberof EnrollOutputUser
     */
    'updated': number;
    /**
     * 
     * @type {UserOutputSegment}
     * @memberof EnrollOutputUser
     */
    'segment': UserOutputSegment;
    /**
     * 
     * @type {UserOutputMarketing}
     * @memberof EnrollOutputUser
     */
    'marketing': UserOutputMarketing;
    /**
     * 
     * @type {UserOutputVerified}
     * @memberof EnrollOutputUser
     */
    'verified': UserOutputVerified;
}

export const EnrollOutputUserAffinityEnum = {
    Veteran: 'veteran',
    Relative: 'relative',
    Not: 'not',
    Commissioned: 'commissioned',
    ActiveDuty: 'active_duty',
    Academy: 'academy'
} as const;

export type EnrollOutputUserAffinityEnum = typeof EnrollOutputUserAffinityEnum[keyof typeof EnrollOutputUserAffinityEnum];
export const EnrollOutputUserGenderEnum = {
    Male: 'male',
    Female: 'female',
    Other: 'other'
} as const;

export type EnrollOutputUserGenderEnum = typeof EnrollOutputUserGenderEnum[keyof typeof EnrollOutputUserGenderEnum];
export const EnrollOutputUserHonorificEnum = {
    Dr: 'dr',
    Miss: 'miss',
    Mr: 'mr',
    Mrs: 'mrs',
    Ms: 'ms',
    Mstr: 'mstr',
    Prof: 'prof',
    Rev: 'rev',
    Sir: 'sir',
    Sr: 'sr'
} as const;

export type EnrollOutputUserHonorificEnum = typeof EnrollOutputUserHonorificEnum[keyof typeof EnrollOutputUserHonorificEnum];
export const EnrollOutputUserTierEnum = {
    VaUnconfirmed: 'va_unconfirmed',
    VaSupporter: 'va_supporter',
    VaMember: 'va_member',
    VaFamily: 'va_family',
    VaActiveDuty: 'va_active_duty',
    VrMember: 'vr_member',
    VrMemberD: 'vr_member_d',
    VrMemberDw: 'vr_member_dw',
    VrFamily: 'vr_family',
    VrFamilyD: 'vr_family_d',
    VrFamilyDw: 'vr_family_dw',
    VrActiveDuty: 'vr_active_duty',
    VrHousehold: 'vr_household'
} as const;

export type EnrollOutputUserTierEnum = typeof EnrollOutputUserTierEnum[keyof typeof EnrollOutputUserTierEnum];

/**
 * 
 * @export
 * @interface EnrollPreview200Response
 */
export interface EnrollPreview200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof EnrollPreview200Response
     */
    'status': EnrollPreview200ResponseStatusEnum;
    /**
     * 
     * @type {EnrollPreview200ResponseAllOfData}
     * @memberof EnrollPreview200Response
     */
    'data': EnrollPreview200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof EnrollPreview200Response
     */
    'warnings'?: Array<string>;
}

export const EnrollPreview200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type EnrollPreview200ResponseStatusEnum = typeof EnrollPreview200ResponseStatusEnum[keyof typeof EnrollPreview200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface EnrollPreview200ResponseAllOf
 */
export interface EnrollPreview200ResponseAllOf {
    /**
     * 
     * @type {EnrollPreview200ResponseAllOfData}
     * @memberof EnrollPreview200ResponseAllOf
     */
    'data'?: EnrollPreview200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface EnrollPreview200ResponseAllOfData
 */
export interface EnrollPreview200ResponseAllOfData {
    /**
     * 
     * @type {EnrollPreviewOutputLines}
     * @memberof EnrollPreview200ResponseAllOfData
     */
    'lines': EnrollPreviewOutputLines;
    /**
     * 
     * @type {EnrollPreviewOutputSubscription}
     * @memberof EnrollPreview200ResponseAllOfData
     */
    'subscription': EnrollPreviewOutputSubscription;
}
/**
 * 
 * @export
 * @interface EnrollPreviewOutput
 */
export interface EnrollPreviewOutput {
    /**
     * 
     * @type {EnrollPreviewOutputLines}
     * @memberof EnrollPreviewOutput
     */
    'lines': EnrollPreviewOutputLines;
    /**
     * 
     * @type {EnrollPreviewOutputSubscription}
     * @memberof EnrollPreviewOutput
     */
    'subscription': EnrollPreviewOutputSubscription;
}
/**
 * Line items info.
 * @export
 * @interface EnrollPreviewOutputLines
 */
export interface EnrollPreviewOutputLines {
    /**
     * 
     * @type {PurchaseBriefLineItemsInfoOutputPrimary}
     * @memberof EnrollPreviewOutputLines
     */
    'primary': PurchaseBriefLineItemsInfoOutputPrimary;
    /**
     * Household members line items groups.
     * @type {Array<PurchaseBriefLineItemsGroupOutput>}
     * @memberof EnrollPreviewOutputLines
     */
    'household_members': Array<PurchaseBriefLineItemsGroupOutput>;
    /**
     * Contrubution line item.
     * @type {Array<PurchaseBriefLineItemOutput>}
     * @memberof EnrollPreviewOutputLines
     */
    'contribution': Array<PurchaseBriefLineItemOutput>;
    /**
     * 
     * @type {PurchaseBriefLineItemsGroupOutputTotal}
     * @memberof EnrollPreviewOutputLines
     */
    'total': PurchaseBriefLineItemsGroupOutputTotal;
}
/**
 * Subscription info.
 * @export
 * @interface EnrollPreviewOutputSubscription
 */
export interface EnrollPreviewOutputSubscription {
    /**
     * The date of subscription expiration in milliseconds.
     * @type {number}
     * @memberof EnrollPreviewOutputSubscription
     */
    'expires': number;
    /**
     * The flag indicating whether the subscription autorenew is enabled.
     * @type {boolean}
     * @memberof EnrollPreviewOutputSubscription
     */
    'autorenew': boolean;
}
/**
 * 
 * @export
 * @interface EnrollPreviewOutputSubscriptionInfo
 */
export interface EnrollPreviewOutputSubscriptionInfo {
    /**
     * The date of subscription expiration in milliseconds.
     * @type {number}
     * @memberof EnrollPreviewOutputSubscriptionInfo
     */
    'expires': number;
    /**
     * The flag indicating whether the subscription autorenew is enabled.
     * @type {boolean}
     * @memberof EnrollPreviewOutputSubscriptionInfo
     */
    'autorenew': boolean;
}
/**
 * 
 * @export
 * @interface EnrollPromoOutput
 */
export interface EnrollPromoOutput {
    /**
     * The Promotion entity UUID.
     * @type {string}
     * @memberof EnrollPromoOutput
     */
    'promo'?: string;
    /**
     * Redirect URL.
     * @type {string}
     * @memberof EnrollPromoOutput
     */
    'redirect_url'?: string;
}
/**
 * 
 * @export
 * @interface EnrollRun200Response
 */
export interface EnrollRun200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof EnrollRun200Response
     */
    'status': EnrollRun200ResponseStatusEnum;
    /**
     * 
     * @type {EnrollRun200ResponseAllOfData}
     * @memberof EnrollRun200Response
     */
    'data': EnrollRun200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof EnrollRun200Response
     */
    'warnings'?: Array<string>;
}

export const EnrollRun200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type EnrollRun200ResponseStatusEnum = typeof EnrollRun200ResponseStatusEnum[keyof typeof EnrollRun200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface EnrollRun200ResponseAllOf
 */
export interface EnrollRun200ResponseAllOf {
    /**
     * 
     * @type {EnrollRun200ResponseAllOfData}
     * @memberof EnrollRun200ResponseAllOf
     */
    'data'?: EnrollRun200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface EnrollRun200ResponseAllOfData
 */
export interface EnrollRun200ResponseAllOfData {
    /**
     * 
     * @type {EnrollOutputUser}
     * @memberof EnrollRun200ResponseAllOfData
     */
    'user': EnrollOutputUser;
    /**
     * 
     * @type {EnrollOutputSubscription}
     * @memberof EnrollRun200ResponseAllOfData
     */
    'subscription': EnrollOutputSubscription;
}
/**
 * 
 * @export
 * @interface EnrollUpsellOutput
 */
export interface EnrollUpsellOutput {
    /**
     * Redirect URL.
     * @type {string}
     * @memberof EnrollUpsellOutput
     */
    'redirect_url'?: string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * Action status.
     * @type {string}
     * @memberof ErrorResponse
     */
    'status': ErrorResponseStatusEnum;
    /**
     * Action error.
     * @type {object}
     * @memberof ErrorResponse
     */
    'error': object;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'warnings'?: Array<string>;
}

export const ErrorResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type ErrorResponseStatusEnum = typeof ErrorResponseStatusEnum[keyof typeof ErrorResponseStatusEnum];

/**
 * 
 * @export
 * @interface FulfillmentBatchInput
 */
export interface FulfillmentBatchInput {
    /**
     * Action name.
     * @type {string}
     * @memberof FulfillmentBatchInput
     */
    'action': string;
    /**
     * Fulfillment Batch ID.
     * @type {string}
     * @memberof FulfillmentBatchInput
     */
    'batch': string;
    /**
     * Value.
     * @type {string}
     * @memberof FulfillmentBatchInput
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface FulfillmentBatchOutput
 */
export interface FulfillmentBatchOutput {
    /**
     * Fulfillment batch ID.
     * @type {string}
     * @memberof FulfillmentBatchOutput
     */
    'id': string;
    /**
     * Time of fulfillment batch creation in milliseconds.
     * @type {number}
     * @memberof FulfillmentBatchOutput
     */
    'created': number;
    /**
     * User ID of who created the fulfillment batch.
     * @type {string}
     * @memberof FulfillmentBatchOutput
     */
    'created_by'?: string | null;
    /**
     * Time of when email confirmations were sent in milliseconds.
     * @type {number}
     * @memberof FulfillmentBatchOutput
     */
    'mailed': number;
    /**
     * User ID of who has sent email confirmations.
     * @type {string}
     * @memberof FulfillmentBatchOutput
     */
    'mailed_by'?: string | null;
    /**
     * Time of when the fulfilment batch was sent to FTP in milliseconds.
     * @type {number}
     * @memberof FulfillmentBatchOutput
     */
    'sent': number;
    /**
     * User ID of who has sent the fulfilment batch to FTP.
     * @type {string}
     * @memberof FulfillmentBatchOutput
     */
    'sent_by'?: string | null;
    /**
     * Fulfillment batch notes.
     * @type {string}
     * @memberof FulfillmentBatchOutput
     */
    'notes': string;
    /**
     * Fulfilment batch records count.
     * @type {number}
     * @memberof FulfillmentBatchOutput
     */
    'records'?: number;
}
/**
 * 
 * @export
 * @interface FulfillmentBatchesList200Response
 */
export interface FulfillmentBatchesList200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof FulfillmentBatchesList200Response
     */
    'status': FulfillmentBatchesList200ResponseStatusEnum;
    /**
     * 
     * @type {FulfillmentBatchesList200ResponseAllOfData}
     * @memberof FulfillmentBatchesList200Response
     */
    'data': FulfillmentBatchesList200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof FulfillmentBatchesList200Response
     */
    'warnings'?: Array<string>;
}

export const FulfillmentBatchesList200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type FulfillmentBatchesList200ResponseStatusEnum = typeof FulfillmentBatchesList200ResponseStatusEnum[keyof typeof FulfillmentBatchesList200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface FulfillmentBatchesList200ResponseAllOf
 */
export interface FulfillmentBatchesList200ResponseAllOf {
    /**
     * 
     * @type {FulfillmentBatchesList200ResponseAllOfData}
     * @memberof FulfillmentBatchesList200ResponseAllOf
     */
    'data'?: FulfillmentBatchesList200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface FulfillmentBatchesList200ResponseAllOfData
 */
export interface FulfillmentBatchesList200ResponseAllOfData {
    /**
     * 
     * @type {Array<FulfillmentBatchOutput>}
     * @memberof FulfillmentBatchesList200ResponseAllOfData
     */
    'items': Array<FulfillmentBatchOutput>;
    /**
     * Indicates whether there are additional items beyond the end of this list.
     * @type {boolean}
     * @memberof FulfillmentBatchesList200ResponseAllOfData
     */
    'has_more': boolean;
    /**
     * The total number of items in the list (optional).
     * @type {number}
     * @memberof FulfillmentBatchesList200ResponseAllOfData
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface FulfillmentBatchesList200ResponseAllOfDataAllOf
 */
export interface FulfillmentBatchesList200ResponseAllOfDataAllOf {
    /**
     * 
     * @type {Array<FulfillmentBatchOutput>}
     * @memberof FulfillmentBatchesList200ResponseAllOfDataAllOf
     */
    'items'?: Array<FulfillmentBatchOutput>;
}
/**
 * 
 * @export
 * @interface FulfillmentInput
 */
export interface FulfillmentInput {
    /**
     * Action name.
     * @type {string}
     * @memberof FulfillmentInput
     */
    'action': string;
    /**
     * Value.
     * @type {string}
     * @memberof FulfillmentInput
     */
    'value': string;
    /**
     * Fulfillment Batch ID.
     * @type {string}
     * @memberof FulfillmentInput
     */
    'batch'?: string;
    /**
     * Fulfillment record IDs list.
     * @type {Array<string>}
     * @memberof FulfillmentInput
     */
    'records'?: Array<string>;
}
/**
 * 
 * @export
 * @interface FulfillmentRecordOutput
 */
export interface FulfillmentRecordOutput {
    /**
     * Fulfillment record ID.
     * @type {string}
     * @memberof FulfillmentRecordOutput
     */
    'id': string;
    /**
     * Time of fulfillment record creation in milliseconds.
     * @type {number}
     * @memberof FulfillmentRecordOutput
     */
    'created': number;
    /**
     * Fulfillment record status.
     * @type {string}
     * @memberof FulfillmentRecordOutput
     */
    'status': FulfillmentRecordOutputStatusEnum;
    /**
     * Time of fulfillment record approval in milliseconds.
     * @type {number}
     * @memberof FulfillmentRecordOutput
     */
    'approved'?: number | null;
    /**
     * User ID of who approved the fulfillment record.
     * @type {string}
     * @memberof FulfillmentRecordOutput
     */
    'approved_by'?: string | null;
    /**
     * Time of fulfillment record was set on hold in milliseconds.
     * @type {number}
     * @memberof FulfillmentRecordOutput
     */
    'hold'?: number | null;
    /**
     * User ID of who set the fulfillment record on hold.
     * @type {string}
     * @memberof FulfillmentRecordOutput
     */
    'hold_by'?: string | null;
    /**
     * Time of fulfillment record was included into batch in milliseconds.
     * @type {number}
     * @memberof FulfillmentRecordOutput
     */
    'batched'?: number | null;
    /**
     * User ID of who has included the fulfillment record into batch.
     * @type {string}
     * @memberof FulfillmentRecordOutput
     */
    'batched_by'?: string | null;
    /**
     * Fulfillment batch ID.
     * @type {string}
     * @memberof FulfillmentRecordOutput
     */
    'batch'?: string | null;
    /**
     * User ID to whom the fulfillment record was created.
     * @type {string}
     * @memberof FulfillmentRecordOutput
     */
    'uid': string;
    /**
     * Fulfillment record flag (type).
     * @type {string}
     * @memberof FulfillmentRecordOutput
     */
    'type': FulfillmentRecordOutputTypeEnum;
    /**
     * Military branch name.
     * @type {string}
     * @memberof FulfillmentRecordOutput
     */
    'branch': string;
    /**
     * Subscription ID.
     * @type {string}
     * @memberof FulfillmentRecordOutput
     */
    'subscription': string;
    /**
     * Subscription expiry timestamp in milliseconds.
     * @type {number}
     * @memberof FulfillmentRecordOutput
     */
    'expiry_date': number;
    /**
     * Invoice ID.
     * @type {string}
     * @memberof FulfillmentRecordOutput
     */
    'invoice': string;
    /**
     * 
     * @type {FulfillmentRecordOutputMrp}
     * @memberof FulfillmentRecordOutput
     */
    'mrp': FulfillmentRecordOutputMrp;
    /**
     * Errors info.
     * @type {Array<FulfillmentRecordOutputErrorInfo>}
     * @memberof FulfillmentRecordOutput
     */
    'errors': Array<FulfillmentRecordOutputErrorInfo>;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof FulfillmentRecordOutput
     */
    'email': string | null;
    /**
     * User first name.
     * @type {string}
     * @memberof FulfillmentRecordOutput
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof FulfillmentRecordOutput
     */
    'last_name': string;
    /**
     * User member ID.
     * @type {string}
     * @memberof FulfillmentRecordOutput
     */
    'member_id': string;
    /**
     * 
     * @type {FulfillmentRecordOutputAddress}
     * @memberof FulfillmentRecordOutput
     */
    'address': FulfillmentRecordOutputAddress | null;
}

export const FulfillmentRecordOutputStatusEnum = {
    Backlog: 'backlog',
    OnHold: 'on_hold',
    Approved: 'approved',
    Batched: 'batched',
    Sent: 'sent'
} as const;

export type FulfillmentRecordOutputStatusEnum = typeof FulfillmentRecordOutputStatusEnum[keyof typeof FulfillmentRecordOutputStatusEnum];
export const FulfillmentRecordOutputTypeEnum = {
    New: 'NEW',
    VrUpgrade: 'VR UPGRADE',
    Otp: 'OTP',
    NewTrial: 'NEW TRIAL',
    NewHh: 'NEW - HH',
    Trial: 'TRIAL',
    Autorenew: 'AUTORENEW',
    Replacement: 'REPLACEMENT',
    Extension: 'EXTENSION',
    Memberid: 'MEMBERID'
} as const;

export type FulfillmentRecordOutputTypeEnum = typeof FulfillmentRecordOutputTypeEnum[keyof typeof FulfillmentRecordOutputTypeEnum];

/**
 * User shipping address.
 * @export
 * @interface FulfillmentRecordOutputAddress
 */
export interface FulfillmentRecordOutputAddress {
    /**
     * Address type.
     * @type {string}
     * @memberof FulfillmentRecordOutputAddress
     */
    'type': FulfillmentRecordOutputAddressTypeEnum;
    /**
     * Street address.
     * @type {string}
     * @memberof FulfillmentRecordOutputAddress
     */
    'line1': string;
    /**
     * Apartment, suite, box number, etc.
     * @type {string}
     * @memberof FulfillmentRecordOutputAddress
     */
    'line2': string | null;
    /**
     * Postal code, ZIP (or ZIP+4) code.
     * @type {string}
     * @memberof FulfillmentRecordOutputAddress
     */
    'postal_code': string;
    /**
     * Depending on address type: - home: city or town; - post: \"APO\", \"FPO\", or \"DPO\".
     * @type {string}
     * @memberof FulfillmentRecordOutputAddress
     */
    'city': string;
    /**
     * State, province, region (ISO code when available).
     * @type {string}
     * @memberof FulfillmentRecordOutputAddress
     */
    'state': string;
    /**
     * Country (2 character ISO code).
     * @type {string}
     * @memberof FulfillmentRecordOutputAddress
     */
    'country': string;
}

export const FulfillmentRecordOutputAddressTypeEnum = {
    Home: 'home',
    Post: 'post'
} as const;

export type FulfillmentRecordOutputAddressTypeEnum = typeof FulfillmentRecordOutputAddressTypeEnum[keyof typeof FulfillmentRecordOutputAddressTypeEnum];

/**
 * 
 * @export
 * @interface FulfillmentRecordOutputErrorInfo
 */
export interface FulfillmentRecordOutputErrorInfo {
    /**
     * Field.
     * @type {string}
     * @memberof FulfillmentRecordOutputErrorInfo
     */
    'field': string;
}
/**
 * MRP info.
 * @export
 * @interface FulfillmentRecordOutputMrp
 */
export interface FulfillmentRecordOutputMrp {
    /**
     * MRP company.
     * @type {string}
     * @memberof FulfillmentRecordOutputMrp
     */
    'company': string | null;
    /**
     * MRP brand.
     * @type {string}
     * @memberof FulfillmentRecordOutputMrp
     */
    'brand': string | null;
    /**
     * MRP name.
     * @type {string}
     * @memberof FulfillmentRecordOutputMrp
     */
    'name': string | null;
}
/**
 * 
 * @export
 * @interface FulfillmentRecordOutputMrpInfo
 */
export interface FulfillmentRecordOutputMrpInfo {
    /**
     * MRP company.
     * @type {string}
     * @memberof FulfillmentRecordOutputMrpInfo
     */
    'company': string | null;
    /**
     * MRP brand.
     * @type {string}
     * @memberof FulfillmentRecordOutputMrpInfo
     */
    'brand': string | null;
    /**
     * MRP name.
     * @type {string}
     * @memberof FulfillmentRecordOutputMrpInfo
     */
    'name': string | null;
}
/**
 * 
 * @export
 * @interface FulfillmentRecordsList200Response
 */
export interface FulfillmentRecordsList200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof FulfillmentRecordsList200Response
     */
    'status': FulfillmentRecordsList200ResponseStatusEnum;
    /**
     * 
     * @type {FulfillmentRecordsList200ResponseAllOfData}
     * @memberof FulfillmentRecordsList200Response
     */
    'data': FulfillmentRecordsList200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof FulfillmentRecordsList200Response
     */
    'warnings'?: Array<string>;
}

export const FulfillmentRecordsList200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type FulfillmentRecordsList200ResponseStatusEnum = typeof FulfillmentRecordsList200ResponseStatusEnum[keyof typeof FulfillmentRecordsList200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface FulfillmentRecordsList200ResponseAllOf
 */
export interface FulfillmentRecordsList200ResponseAllOf {
    /**
     * 
     * @type {FulfillmentRecordsList200ResponseAllOfData}
     * @memberof FulfillmentRecordsList200ResponseAllOf
     */
    'data'?: FulfillmentRecordsList200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface FulfillmentRecordsList200ResponseAllOfData
 */
export interface FulfillmentRecordsList200ResponseAllOfData {
    /**
     * 
     * @type {Array<FulfillmentRecordOutput>}
     * @memberof FulfillmentRecordsList200ResponseAllOfData
     */
    'items': Array<FulfillmentRecordOutput>;
    /**
     * Indicates whether there are additional items beyond the end of this list.
     * @type {boolean}
     * @memberof FulfillmentRecordsList200ResponseAllOfData
     */
    'has_more': boolean;
    /**
     * The total number of items in the list (optional).
     * @type {number}
     * @memberof FulfillmentRecordsList200ResponseAllOfData
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface FulfillmentRecordsList200ResponseAllOfDataAllOf
 */
export interface FulfillmentRecordsList200ResponseAllOfDataAllOf {
    /**
     * 
     * @type {Array<FulfillmentRecordOutput>}
     * @memberof FulfillmentRecordsList200ResponseAllOfDataAllOf
     */
    'items'?: Array<FulfillmentRecordOutput>;
}
/**
 * 
 * @export
 * @interface GenerateSecret200Response
 */
export interface GenerateSecret200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof GenerateSecret200Response
     */
    'status': GenerateSecret200ResponseStatusEnum;
    /**
     * 
     * @type {GenerateSecret200ResponseAllOfData}
     * @memberof GenerateSecret200Response
     */
    'data': GenerateSecret200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof GenerateSecret200Response
     */
    'warnings'?: Array<string>;
}

export const GenerateSecret200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type GenerateSecret200ResponseStatusEnum = typeof GenerateSecret200ResponseStatusEnum[keyof typeof GenerateSecret200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface GenerateSecret200ResponseAllOf
 */
export interface GenerateSecret200ResponseAllOf {
    /**
     * 
     * @type {GenerateSecret200ResponseAllOfData}
     * @memberof GenerateSecret200ResponseAllOf
     */
    'data'?: GenerateSecret200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface GenerateSecret200ResponseAllOfData
 */
export interface GenerateSecret200ResponseAllOfData {
    /**
     * Secret base32 code.
     * @type {string}
     * @memberof GenerateSecret200ResponseAllOfData
     */
    'secret': string;
    /**
     * Generated image in base64 string for URL ready.
     * @type {string}
     * @memberof GenerateSecret200ResponseAllOfData
     */
    'qr_code': string | null;
}
/**
 * 
 * @export
 * @interface GoogleSecretDto
 */
export interface GoogleSecretDto {
    /**
     * Secret base32 code.
     * @type {string}
     * @memberof GoogleSecretDto
     */
    'secret': string;
    /**
     * Generated image in base64 string for URL ready.
     * @type {string}
     * @memberof GoogleSecretDto
     */
    'qr_code': string | null;
}
/**
 * 
 * @export
 * @interface IdentificationOutput
 */
export interface IdentificationOutput {
    /**
     * 
     * @type {IdentificationOutputIdentity}
     * @memberof IdentificationOutput
     */
    'identity': IdentificationOutputIdentity;
    /**
     * 
     * @type {IdentificationOutputMilitary}
     * @memberof IdentificationOutput
     */
    'military': IdentificationOutputMilitary;
}
/**
 * 
 * @export
 * @interface IdentificationOutputEmail
 */
export interface IdentificationOutputEmail {
    /**
     * Status.
     * @type {string}
     * @memberof IdentificationOutputEmail
     */
    'status': string;
    /**
     * Verification timestamp.
     * @type {number}
     * @memberof IdentificationOutputEmail
     */
    'verified_on': number;
    /**
     * Military email address.
     * @type {string}
     * @memberof IdentificationOutputEmail
     */
    'address': string;
}
/**
 * Identity info.
 * @export
 * @interface IdentificationOutputIdentity
 */
export interface IdentificationOutputIdentity {
    /**
     * Status.
     * @type {string}
     * @memberof IdentificationOutputIdentity
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface IdentificationOutputIdentityInfo
 */
export interface IdentificationOutputIdentityInfo {
    /**
     * Status.
     * @type {string}
     * @memberof IdentificationOutputIdentityInfo
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface IdentificationOutputLighthouse
 */
export interface IdentificationOutputLighthouse {
    /**
     * Status.
     * @type {string}
     * @memberof IdentificationOutputLighthouse
     */
    'status': string;
    /**
     * SSN hash.
     * @type {string}
     * @memberof IdentificationOutputLighthouse
     */
    'ssn_hash': string;
    /**
     * Decision.
     * @type {string}
     * @memberof IdentificationOutputLighthouse
     */
    'decision': string;
    /**
     * Verification timestamp.
     * @type {number}
     * @memberof IdentificationOutputLighthouse
     */
    'verified_on': number;
}
/**
 * 
 * @export
 * @interface IdentificationOutputManual
 */
export interface IdentificationOutputManual {
    /**
     * Status.
     * @type {string}
     * @memberof IdentificationOutputManual
     */
    'status': string;
    /**
     * Verified by.
     * @type {string}
     * @memberof IdentificationOutputManual
     */
    'verified_by': string;
    /**
     * Verification timestamp.
     * @type {number}
     * @memberof IdentificationOutputManual
     */
    'verified_on': number;
    /**
     * Notes.
     * @type {string}
     * @memberof IdentificationOutputManual
     */
    'notes': string;
}
/**
 * Military info.
 * @export
 * @interface IdentificationOutputMilitary
 */
export interface IdentificationOutputMilitary {
    /**
     * Affinity.
     * @type {string}
     * @memberof IdentificationOutputMilitary
     */
    'affinity': string | null;
    /**
     * Expiration.
     * @type {number}
     * @memberof IdentificationOutputMilitary
     */
    'expiration': number | null;
    /**
     * Status.
     * @type {string}
     * @memberof IdentificationOutputMilitary
     */
    'status': string;
    /**
     * 
     * @type {IdentificationOutputMilitaryInfoVerification}
     * @memberof IdentificationOutputMilitary
     */
    'verification': IdentificationOutputMilitaryInfoVerification | null;
}
/**
 * 
 * @export
 * @interface IdentificationOutputMilitaryInfo
 */
export interface IdentificationOutputMilitaryInfo {
    /**
     * Affinity.
     * @type {string}
     * @memberof IdentificationOutputMilitaryInfo
     */
    'affinity': string | null;
    /**
     * Expiration.
     * @type {number}
     * @memberof IdentificationOutputMilitaryInfo
     */
    'expiration': number | null;
    /**
     * Status.
     * @type {string}
     * @memberof IdentificationOutputMilitaryInfo
     */
    'status': string;
    /**
     * 
     * @type {IdentificationOutputMilitaryInfoVerification}
     * @memberof IdentificationOutputMilitaryInfo
     */
    'verification': IdentificationOutputMilitaryInfoVerification | null;
}
/**
 * Verification info.
 * @export
 * @interface IdentificationOutputMilitaryInfoVerification
 */
export interface IdentificationOutputMilitaryInfoVerification {
    /**
     * 
     * @type {IdentificationOutputVerificationEmail}
     * @memberof IdentificationOutputMilitaryInfoVerification
     */
    'email': IdentificationOutputVerificationEmail | null;
    /**
     * 
     * @type {IdentificationOutputVerificationQuiz}
     * @memberof IdentificationOutputMilitaryInfoVerification
     */
    'quiz': IdentificationOutputVerificationQuiz | null;
    /**
     * 
     * @type {IdentificationOutputVerificationLighthouse}
     * @memberof IdentificationOutputMilitaryInfoVerification
     */
    'lighthouse': IdentificationOutputVerificationLighthouse | null;
    /**
     * 
     * @type {IdentificationOutputVerificationManual}
     * @memberof IdentificationOutputMilitaryInfoVerification
     */
    'manual': IdentificationOutputVerificationManual | null;
}
/**
 * 
 * @export
 * @interface IdentificationOutputQuiz
 */
export interface IdentificationOutputQuiz {
    /**
     * Status.
     * @type {string}
     * @memberof IdentificationOutputQuiz
     */
    'status': string;
    /**
     * Score.
     * @type {number}
     * @memberof IdentificationOutputQuiz
     */
    'score': number;
    /**
     * Verification timestamp.
     * @type {number}
     * @memberof IdentificationOutputQuiz
     */
    'verified_on': number;
}
/**
 * 
 * @export
 * @interface IdentificationOutputVerification
 */
export interface IdentificationOutputVerification {
    /**
     * 
     * @type {IdentificationOutputVerificationEmail}
     * @memberof IdentificationOutputVerification
     */
    'email': IdentificationOutputVerificationEmail | null;
    /**
     * 
     * @type {IdentificationOutputVerificationQuiz}
     * @memberof IdentificationOutputVerification
     */
    'quiz': IdentificationOutputVerificationQuiz | null;
    /**
     * 
     * @type {IdentificationOutputVerificationLighthouse}
     * @memberof IdentificationOutputVerification
     */
    'lighthouse': IdentificationOutputVerificationLighthouse | null;
    /**
     * 
     * @type {IdentificationOutputVerificationManual}
     * @memberof IdentificationOutputVerification
     */
    'manual': IdentificationOutputVerificationManual | null;
}
/**
 * Email group.
 * @export
 * @interface IdentificationOutputVerificationEmail
 */
export interface IdentificationOutputVerificationEmail {
    /**
     * Status.
     * @type {string}
     * @memberof IdentificationOutputVerificationEmail
     */
    'status': string;
    /**
     * Verification timestamp.
     * @type {number}
     * @memberof IdentificationOutputVerificationEmail
     */
    'verified_on': number;
    /**
     * Military email address.
     * @type {string}
     * @memberof IdentificationOutputVerificationEmail
     */
    'address': string;
}
/**
 * Lighthouse group.
 * @export
 * @interface IdentificationOutputVerificationLighthouse
 */
export interface IdentificationOutputVerificationLighthouse {
    /**
     * Status.
     * @type {string}
     * @memberof IdentificationOutputVerificationLighthouse
     */
    'status': string;
    /**
     * SSN hash.
     * @type {string}
     * @memberof IdentificationOutputVerificationLighthouse
     */
    'ssn_hash': string;
    /**
     * Decision.
     * @type {string}
     * @memberof IdentificationOutputVerificationLighthouse
     */
    'decision': string;
    /**
     * Verification timestamp.
     * @type {number}
     * @memberof IdentificationOutputVerificationLighthouse
     */
    'verified_on': number;
}
/**
 * Manual group.
 * @export
 * @interface IdentificationOutputVerificationManual
 */
export interface IdentificationOutputVerificationManual {
    /**
     * Status.
     * @type {string}
     * @memberof IdentificationOutputVerificationManual
     */
    'status': string;
    /**
     * Verified by.
     * @type {string}
     * @memberof IdentificationOutputVerificationManual
     */
    'verified_by': string;
    /**
     * Verification timestamp.
     * @type {number}
     * @memberof IdentificationOutputVerificationManual
     */
    'verified_on': number;
    /**
     * Notes.
     * @type {string}
     * @memberof IdentificationOutputVerificationManual
     */
    'notes': string;
}
/**
 * Quiz group.
 * @export
 * @interface IdentificationOutputVerificationQuiz
 */
export interface IdentificationOutputVerificationQuiz {
    /**
     * Status.
     * @type {string}
     * @memberof IdentificationOutputVerificationQuiz
     */
    'status': string;
    /**
     * Score.
     * @type {number}
     * @memberof IdentificationOutputVerificationQuiz
     */
    'score': number;
    /**
     * Verification timestamp.
     * @type {number}
     * @memberof IdentificationOutputVerificationQuiz
     */
    'verified_on': number;
}
/**
 * 
 * @export
 * @interface InvoiceInfo200Response
 */
export interface InvoiceInfo200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof InvoiceInfo200Response
     */
    'status': InvoiceInfo200ResponseStatusEnum;
    /**
     * 
     * @type {InvoiceInfo200ResponseAllOfData}
     * @memberof InvoiceInfo200Response
     */
    'data': InvoiceInfo200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof InvoiceInfo200Response
     */
    'warnings'?: Array<string>;
}

export const InvoiceInfo200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type InvoiceInfo200ResponseStatusEnum = typeof InvoiceInfo200ResponseStatusEnum[keyof typeof InvoiceInfo200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface InvoiceInfo200ResponseAllOf
 */
export interface InvoiceInfo200ResponseAllOf {
    /**
     * 
     * @type {InvoiceInfo200ResponseAllOfData}
     * @memberof InvoiceInfo200ResponseAllOf
     */
    'data'?: InvoiceInfo200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface InvoiceInfo200ResponseAllOfData
 */
export interface InvoiceInfo200ResponseAllOfData {
    /**
     * Invoice status.
     * @type {string}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'status': InvoiceInfo200ResponseAllOfDataStatusEnum;
    /**
     * Invoice ID.
     * @type {string}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'id': string;
    /**
     * Charge ID.
     * @type {string}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'charge': string | null;
    /**
     * Total amount.
     * @type {number}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'total': number;
    /**
     * Invoice currency.
     * @type {string}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'currency': InvoiceInfo200ResponseAllOfDataCurrencyEnum;
    /**
     * Timestamp in milliseconds at which the invoice was created.
     * @type {number}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'created': number;
    /**
     * Timestamp in milliseconds at which the invoice was created.
     * @type {number}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'period_start': number;
    /**
     * Timestamp in milliseconds at which the invoice was created.
     * @type {number}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'period_end': number;
    /**
     * Invoice number.
     * @type {string}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'number': string | null;
    /**
     * Customer email.
     * @type {string}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'customer_email': string | null;
    /**
     * Customer name.
     * @type {string}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'customer_name': string | null;
    /**
     * 
     * @type {MemberInvoiceFullOutputCustomerAddress}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'customer_address': MemberInvoiceFullOutputCustomerAddress | null;
    /**
     * Customer phone.
     * @type {string}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'customer_phone': string | null;
    /**
     * Default payment method.
     * @type {string}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'default_payment_method': string | null;
    /**
     * Auto advance setting.
     * @type {boolean}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'auto_advance': boolean;
    /**
     * Invoice PDF URL.
     * @type {string}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'invoice_pdf': string | null;
    /**
     * Array of charges.
     * @type {Array<MemberInvoiceFullOutputChargeOutput>}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'charges': Array<MemberInvoiceFullOutputChargeOutput>;
    /**
     * Array of invoice line items.
     * @type {Array<MemberInvoiceFullOutputLineItemOutput>}
     * @memberof InvoiceInfo200ResponseAllOfData
     */
    'lines': Array<MemberInvoiceFullOutputLineItemOutput>;
}

export const InvoiceInfo200ResponseAllOfDataStatusEnum = {
    Deleted: 'deleted',
    Draft: 'draft',
    Open: 'open',
    Paid: 'paid',
    Uncollectible: 'uncollectible',
    Void: 'void'
} as const;

export type InvoiceInfo200ResponseAllOfDataStatusEnum = typeof InvoiceInfo200ResponseAllOfDataStatusEnum[keyof typeof InvoiceInfo200ResponseAllOfDataStatusEnum];
export const InvoiceInfo200ResponseAllOfDataCurrencyEnum = {
    Usd: 'usd'
} as const;

export type InvoiceInfo200ResponseAllOfDataCurrencyEnum = typeof InvoiceInfo200ResponseAllOfDataCurrencyEnum[keyof typeof InvoiceInfo200ResponseAllOfDataCurrencyEnum];

/**
 * 
 * @export
 * @interface KustomerTokenInfoOutput
 */
export interface KustomerTokenInfoOutput {
    /**
     * Kustomer auth token.
     * @type {string}
     * @memberof KustomerTokenInfoOutput
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface Masquerade400Response
 */
export interface Masquerade400Response {
    /**
     * Action status.
     * @type {string}
     * @memberof Masquerade400Response
     */
    'status': Masquerade400ResponseStatusEnum;
    /**
     * 
     * @type {Error}
     * @memberof Masquerade400Response
     */
    'error': Error;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof Masquerade400Response
     */
    'warnings'?: Array<string>;
}

export const Masquerade400ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type Masquerade400ResponseStatusEnum = typeof Masquerade400ResponseStatusEnum[keyof typeof Masquerade400ResponseStatusEnum];

/**
 * 
 * @export
 * @interface Masquerade400ResponseAllOf
 */
export interface Masquerade400ResponseAllOf {
    /**
     * 
     * @type {Error}
     * @memberof Masquerade400ResponseAllOf
     */
    'error'?: Error;
}
/**
 * 
 * @export
 * @interface MasqueradeApplyDataInput
 */
export interface MasqueradeApplyDataInput {
    /**
     * User ID.
     * @type {string}
     * @memberof MasqueradeApplyDataInput
     */
    'uid': string;
}
/**
 * 
 * @export
 * @interface MemberActiveProgram200Response
 */
export interface MemberActiveProgram200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberActiveProgram200Response
     */
    'status': MemberActiveProgram200ResponseStatusEnum;
    /**
     * 
     * @type {MemberActiveProgram200ResponseAllOfData}
     * @memberof MemberActiveProgram200Response
     */
    'data': MemberActiveProgram200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberActiveProgram200Response
     */
    'warnings'?: Array<string>;
}

export const MemberActiveProgram200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberActiveProgram200ResponseStatusEnum = typeof MemberActiveProgram200ResponseStatusEnum[keyof typeof MemberActiveProgram200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberActiveProgram200ResponseAllOf
 */
export interface MemberActiveProgram200ResponseAllOf {
    /**
     * 
     * @type {MemberActiveProgram200ResponseAllOfData}
     * @memberof MemberActiveProgram200ResponseAllOf
     */
    'data'?: MemberActiveProgram200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MemberActiveProgram200ResponseAllOfData
 */
export interface MemberActiveProgram200ResponseAllOfData {
    /**
     * Program brand.
     * @type {string}
     * @memberof MemberActiveProgram200ResponseAllOfData
     */
    'brand': string;
    /**
     * Program title.
     * @type {string}
     * @memberof MemberActiveProgram200ResponseAllOfData
     */
    'title': string;
    /**
     * Program description.
     * @type {string}
     * @memberof MemberActiveProgram200ResponseAllOfData
     */
    'description': string;
    /**
     * Program recipients.
     * @type {string}
     * @memberof MemberActiveProgram200ResponseAllOfData
     */
    'recipients': string;
    /**
     * Member email address.
     * @type {number}
     * @memberof MemberActiveProgram200ResponseAllOfData
     */
    'expires_on': number;
}
/**
 * 
 * @export
 * @interface MemberActiveProgramOutput
 */
export interface MemberActiveProgramOutput {
    /**
     * Program brand.
     * @type {string}
     * @memberof MemberActiveProgramOutput
     */
    'brand': string;
    /**
     * Program title.
     * @type {string}
     * @memberof MemberActiveProgramOutput
     */
    'title': string;
    /**
     * Program description.
     * @type {string}
     * @memberof MemberActiveProgramOutput
     */
    'description': string;
    /**
     * Program recipients.
     * @type {string}
     * @memberof MemberActiveProgramOutput
     */
    'recipients': string;
    /**
     * Member email address.
     * @type {number}
     * @memberof MemberActiveProgramOutput
     */
    'expires_on': number;
}
/**
 * 
 * @export
 * @interface MemberAdminRolesSetInput
 */
export interface MemberAdminRolesSetInput {
    /**
     * User ID.
     * @type {string}
     * @memberof MemberAdminRolesSetInput
     */
    'id'?: string;
    /**
     * Admin roles array.
     * @type {Array<string>}
     * @memberof MemberAdminRolesSetInput
     */
    'roles': Array<MemberAdminRolesSetInputRolesEnum>;
}

export const MemberAdminRolesSetInputRolesEnum = {
    Administrator: 'administrator',
    CustomerSupport: 'customer_support',
    CsSuperAgent: 'cs_super_agent',
    ContentPublisher: 'content_publisher'
} as const;

export type MemberAdminRolesSetInputRolesEnum = typeof MemberAdminRolesSetInputRolesEnum[keyof typeof MemberAdminRolesSetInputRolesEnum];

/**
 * 
 * @export
 * @interface MemberAuthEmailConfirmInput
 */
export interface MemberAuthEmailConfirmInput {
    /**
     * The JWT token.
     * @type {string}
     * @memberof MemberAuthEmailConfirmInput
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface MemberAuthEmailSetInput
 */
export interface MemberAuthEmailSetInput {
    /**
     * User ID.
     * @type {string}
     * @memberof MemberAuthEmailSetInput
     */
    'id'?: string;
    /**
     * The member email.
     * @type {string}
     * @memberof MemberAuthEmailSetInput
     */
    'email': string;
    /**
     * The password.
     * @type {string}
     * @memberof MemberAuthEmailSetInput
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface MemberAuthEmailVerifyInput
 */
export interface MemberAuthEmailVerifyInput {
    /**
     * The member email.
     * @type {string}
     * @memberof MemberAuthEmailVerifyInput
     */
    'email': string;
    /**
     * Return to url parameter.
     * @type {string}
     * @memberof MemberAuthEmailVerifyInput
     */
    'return_to'?: string;
    /**
     * Is new user flag.
     * @type {boolean}
     * @memberof MemberAuthEmailVerifyInput
     */
    'is_new'?: boolean;
}
/**
 * 
 * @export
 * @interface MemberAuthNewsletterSignupInput
 */
export interface MemberAuthNewsletterSignupInput {
    /**
     * The member email.
     * @type {string}
     * @memberof MemberAuthNewsletterSignupInput
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface MemberAuthPasswordUpdateInput
 */
export interface MemberAuthPasswordUpdateInput {
    /**
     * Member current password.
     * @type {string}
     * @memberof MemberAuthPasswordUpdateInput
     */
    'current': string;
    /**
     * Member new password.
     * @type {string}
     * @memberof MemberAuthPasswordUpdateInput
     */
    'renewed': string;
}
/**
 * 
 * @export
 * @interface MemberAuthPhoneSendCode200Response
 */
export interface MemberAuthPhoneSendCode200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberAuthPhoneSendCode200Response
     */
    'status': MemberAuthPhoneSendCode200ResponseStatusEnum;
    /**
     * 
     * @type {MemberAuthPhoneSendCode200ResponseAllOfData}
     * @memberof MemberAuthPhoneSendCode200Response
     */
    'data': MemberAuthPhoneSendCode200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberAuthPhoneSendCode200Response
     */
    'warnings'?: Array<string>;
}

export const MemberAuthPhoneSendCode200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberAuthPhoneSendCode200ResponseStatusEnum = typeof MemberAuthPhoneSendCode200ResponseStatusEnum[keyof typeof MemberAuthPhoneSendCode200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberAuthPhoneSendCode200ResponseAllOf
 */
export interface MemberAuthPhoneSendCode200ResponseAllOf {
    /**
     * 
     * @type {MemberAuthPhoneSendCode200ResponseAllOfData}
     * @memberof MemberAuthPhoneSendCode200ResponseAllOf
     */
    'data'?: MemberAuthPhoneSendCode200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MemberAuthPhoneSendCode200ResponseAllOfData
 */
export interface MemberAuthPhoneSendCode200ResponseAllOfData {
    /**
     * The recaptcha token returned from /member/auth-settings/phone/send-code
     * @type {string}
     * @memberof MemberAuthPhoneSendCode200ResponseAllOfData
     */
    'session_info': string | null;
}
/**
 * 
 * @export
 * @interface MemberAuthPhoneSendCodeInput
 */
export interface MemberAuthPhoneSendCodeInput {
    /**
     * The users phone number in E164 format.
     * @type {string}
     * @memberof MemberAuthPhoneSendCodeInput
     */
    'phone': string;
    /**
     * A token generated in the brower after the user successfully completes recaptcha.
     * @type {string}
     * @memberof MemberAuthPhoneSendCodeInput
     */
    'recaptcha_token': string;
}
/**
 * 
 * @export
 * @interface MemberAuthPhoneSendCodeOutput
 */
export interface MemberAuthPhoneSendCodeOutput {
    /**
     * The recaptcha token returned from /member/auth-settings/phone/send-code
     * @type {string}
     * @memberof MemberAuthPhoneSendCodeOutput
     */
    'session_info': string | null;
}
/**
 * 
 * @export
 * @interface MemberAuthPhoneValidateCode200Response
 */
export interface MemberAuthPhoneValidateCode200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberAuthPhoneValidateCode200Response
     */
    'status': MemberAuthPhoneValidateCode200ResponseStatusEnum;
    /**
     * 
     * @type {MemberAuthPhoneValidateCode200ResponseAllOfData}
     * @memberof MemberAuthPhoneValidateCode200Response
     */
    'data': MemberAuthPhoneValidateCode200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberAuthPhoneValidateCode200Response
     */
    'warnings'?: Array<string>;
}

export const MemberAuthPhoneValidateCode200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberAuthPhoneValidateCode200ResponseStatusEnum = typeof MemberAuthPhoneValidateCode200ResponseStatusEnum[keyof typeof MemberAuthPhoneValidateCode200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberAuthPhoneValidateCode200ResponseAllOf
 */
export interface MemberAuthPhoneValidateCode200ResponseAllOf {
    /**
     * 
     * @type {MemberAuthPhoneValidateCode200ResponseAllOfData}
     * @memberof MemberAuthPhoneValidateCode200ResponseAllOf
     */
    'data'?: MemberAuthPhoneValidateCode200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MemberAuthPhoneValidateCode200ResponseAllOfData
 */
export interface MemberAuthPhoneValidateCode200ResponseAllOfData {
    /**
     * Indicates whether SMS code has been verified.
     * @type {boolean}
     * @memberof MemberAuthPhoneValidateCode200ResponseAllOfData
     */
    'validated': boolean;
}
/**
 * 
 * @export
 * @interface MemberAuthPhoneValidateCodeInput
 */
export interface MemberAuthPhoneValidateCodeInput {
    /**
     * A six-digit code sent to the user\'s phone number.
     * @type {string}
     * @memberof MemberAuthPhoneValidateCodeInput
     */
    'code': string;
    /**
     * The recaptcha token returned from /member/auth-settings/phone/send-code
     * @type {string}
     * @memberof MemberAuthPhoneValidateCodeInput
     */
    'session_info': string;
}
/**
 * 
 * @export
 * @interface MemberAuthPhoneValidateCodeOutput
 */
export interface MemberAuthPhoneValidateCodeOutput {
    /**
     * Indicates whether SMS code has been verified.
     * @type {boolean}
     * @memberof MemberAuthPhoneValidateCodeOutput
     */
    'validated': boolean;
}
/**
 * 
 * @export
 * @interface MemberAuthPreregisterInput
 */
export interface MemberAuthPreregisterInput {
    /**
     * The member email.
     * @type {string}
     * @memberof MemberAuthPreregisterInput
     */
    'email': string;
    /**
     * The member firstname. Example John.
     * @type {string}
     * @memberof MemberAuthPreregisterInput
     */
    'firstname': string;
    /**
     * The member lastname. Example Doe.
     * @type {string}
     * @memberof MemberAuthPreregisterInput
     */
    'lastname': string;
    /**
     * Recaptcha token.
     * @type {string}
     * @memberof MemberAuthPreregisterInput
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface MemberAuthProviderUnlinkInput
 */
export interface MemberAuthProviderUnlinkInput {
    /**
     * The provider id.
     * @type {string}
     * @memberof MemberAuthProviderUnlinkInput
     */
    'provider_id': string;
}
/**
 * 
 * @export
 * @interface MemberAuthResendPasswordResetInput
 */
export interface MemberAuthResendPasswordResetInput {
    /**
     * The JWT token.
     * @type {string}
     * @memberof MemberAuthResendPasswordResetInput
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface MemberAuthSessionSetInput
 */
export interface MemberAuthSessionSetInput {
    /**
     * Firebase Auth refresh_token.
     * @type {string}
     * @memberof MemberAuthSessionSetInput
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface MemberChargeRefundInput
 */
export interface MemberChargeRefundInput {
    /**
     * An amount of charge refund.
     * @type {number}
     * @memberof MemberChargeRefundInput
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface MemberConsentSessionOutput
 */
export interface MemberConsentSessionOutput {
    /**
     * Member audience.
     * @type {string}
     * @memberof MemberConsentSessionOutput
     */
    'audience': string;
    /**
     * Member email address.
     * @type {string}
     * @memberof MemberConsentSessionOutput
     */
    'email': string;
    /**
     * Member family name.
     * @type {string}
     * @memberof MemberConsentSessionOutput
     */
    'family_name': string;
    /**
     * Member given name.
     * @type {string}
     * @memberof MemberConsentSessionOutput
     */
    'given_name': string;
    /**
     * Member ID.
     * @type {string}
     * @memberof MemberConsentSessionOutput
     */
    'member_id': string;
    /**
     * Member full name.
     * @type {string}
     * @memberof MemberConsentSessionOutput
     */
    'name': string;
    /**
     * Member tier.
     * @type {string}
     * @memberof MemberConsentSessionOutput
     */
    'tier': string;
    /**
     * Consent client ID.
     * @type {string}
     * @memberof MemberConsentSessionOutput
     */
    'client_id': string;
    /**
     * Consent client name.
     * @type {string}
     * @memberof MemberConsentSessionOutput
     */
    'client_name': string;
    /**
     * Date when consent was first granted in ISO 8601 format.
     * @type {string}
     * @memberof MemberConsentSessionOutput
     */
    'handled_at': string;
}
/**
 * 
 * @export
 * @interface MemberConsentSessions200Response
 */
export interface MemberConsentSessions200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberConsentSessions200Response
     */
    'status': MemberConsentSessions200ResponseStatusEnum;
    /**
     * 
     * @type {Array<MemberConsentSessionOutput>}
     * @memberof MemberConsentSessions200Response
     */
    'data': Array<MemberConsentSessionOutput>;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberConsentSessions200Response
     */
    'warnings'?: Array<string>;
}

export const MemberConsentSessions200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberConsentSessions200ResponseStatusEnum = typeof MemberConsentSessions200ResponseStatusEnum[keyof typeof MemberConsentSessions200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberConsentSessions200ResponseAllOf
 */
export interface MemberConsentSessions200ResponseAllOf {
    /**
     * 
     * @type {Array<MemberConsentSessionOutput>}
     * @memberof MemberConsentSessions200ResponseAllOf
     */
    'data'?: Array<MemberConsentSessionOutput>;
}
/**
 * 
 * @export
 * @interface MemberContactPreferences200Response
 */
export interface MemberContactPreferences200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberContactPreferences200Response
     */
    'status': MemberContactPreferences200ResponseStatusEnum;
    /**
     * 
     * @type {MemberContactPreferences200ResponseAllOfData}
     * @memberof MemberContactPreferences200Response
     */
    'data': MemberContactPreferences200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberContactPreferences200Response
     */
    'warnings'?: Array<string>;
}

export const MemberContactPreferences200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberContactPreferences200ResponseStatusEnum = typeof MemberContactPreferences200ResponseStatusEnum[keyof typeof MemberContactPreferences200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberContactPreferences200ResponseAllOf
 */
export interface MemberContactPreferences200ResponseAllOf {
    /**
     * 
     * @type {MemberContactPreferences200ResponseAllOfData}
     * @memberof MemberContactPreferences200ResponseAllOf
     */
    'data'?: MemberContactPreferences200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MemberContactPreferences200ResponseAllOfData
 */
export interface MemberContactPreferences200ResponseAllOfData {
    /**
     * News mailing list subscription flag.
     * @type {boolean}
     * @memberof MemberContactPreferences200ResponseAllOfData
     */
    'news': boolean;
}
/**
 * 
 * @export
 * @interface MemberEmailsInfoOutput
 */
export interface MemberEmailsInfoOutput {
    /**
     * Status code.
     * @type {number}
     * @memberof MemberEmailsInfoOutput
     */
    'statusCode': number;
}
/**
 * 
 * @export
 * @interface MemberInfo200Response
 */
export interface MemberInfo200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberInfo200Response
     */
    'status': MemberInfo200ResponseStatusEnum;
    /**
     * 
     * @type {MemberInfo200ResponseAllOfData}
     * @memberof MemberInfo200Response
     */
    'data': MemberInfo200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberInfo200Response
     */
    'warnings'?: Array<string>;
}

export const MemberInfo200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberInfo200ResponseStatusEnum = typeof MemberInfo200ResponseStatusEnum[keyof typeof MemberInfo200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberInfo200Response1
 */
export interface MemberInfo200Response1 {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberInfo200Response1
     */
    'status': MemberInfo200Response1StatusEnum;
    /**
     * 
     * @type {MemberInfo200Response1AllOfData}
     * @memberof MemberInfo200Response1
     */
    'data': MemberInfo200Response1AllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberInfo200Response1
     */
    'warnings'?: Array<string>;
}

export const MemberInfo200Response1StatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberInfo200Response1StatusEnum = typeof MemberInfo200Response1StatusEnum[keyof typeof MemberInfo200Response1StatusEnum];

/**
 * 
 * @export
 * @interface MemberInfo200Response1AllOf
 */
export interface MemberInfo200Response1AllOf {
    /**
     * 
     * @type {MemberInfo200Response1AllOfData}
     * @memberof MemberInfo200Response1AllOf
     */
    'data'?: MemberInfo200Response1AllOfData;
}
/**
 * 
 * @export
 * @interface MemberInfo200Response1AllOfData
 */
export interface MemberInfo200Response1AllOfData {
    /**
     * User tier.
     * @type {string}
     * @memberof MemberInfo200Response1AllOfData
     */
    'tier'?: MemberInfo200Response1AllOfDataTierEnum;
    /**
     * User first name.
     * @type {string}
     * @memberof MemberInfo200Response1AllOfData
     */
    'given_name'?: string;
    /**
     * User last name.
     * @type {string}
     * @memberof MemberInfo200Response1AllOfData
     */
    'family_name'?: string;
    /**
     * User member ID.
     * @type {string}
     * @memberof MemberInfo200Response1AllOfData
     */
    'sub'?: string;
    /**
     * Flag whether email is verified.
     * @type {boolean}
     * @memberof MemberInfo200Response1AllOfData
     */
    'email_verified'?: boolean;
    /**
     * User audience.
     * @type {Array<string>}
     * @memberof MemberInfo200Response1AllOfData
     */
    'audience'?: Array<string>;
    /**
     * User household members info.
     * @type {Array<PartnerMemberInfoOutputHouseholdMemberInfo>}
     * @memberof MemberInfo200Response1AllOfData
     */
    'household'?: Array<PartnerMemberInfoOutputHouseholdMemberInfo>;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof MemberInfo200Response1AllOfData
     */
    'email'?: string | null;
    /**
     * User member ID.
     * @type {string}
     * @memberof MemberInfo200Response1AllOfData
     */
    'member_id'?: string;
}

export const MemberInfo200Response1AllOfDataTierEnum = {
    Basic: 'basic',
    Premium: 'premium'
} as const;

export type MemberInfo200Response1AllOfDataTierEnum = typeof MemberInfo200Response1AllOfDataTierEnum[keyof typeof MemberInfo200Response1AllOfDataTierEnum];

/**
 * 
 * @export
 * @interface MemberInfo200ResponseAllOf
 */
export interface MemberInfo200ResponseAllOf {
    /**
     * 
     * @type {MemberInfo200ResponseAllOfData}
     * @memberof MemberInfo200ResponseAllOf
     */
    'data'?: MemberInfo200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MemberInfo200ResponseAllOfData
 */
export interface MemberInfo200ResponseAllOfData {
    /**
     * User affinity.
     * @type {string}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'affinity': MemberInfo200ResponseAllOfDataAffinityEnum;
    /**
     * Plan type.
     * @type {string}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'plan_type': MemberInfo200ResponseAllOfDataPlanTypeEnum;
    /**
     * Travel protection ID.
     * @type {string}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'travel_protection': string | null;
    /**
     * 
     * @type {MemberInfoOutputMilitary}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'military': MemberInfoOutputMilitary;
    /**
     * Plan expiration time.
     * @type {number}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'plan_expires': number | null;
    /**
     * Auth providers.
     * @type {Array<string>}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'providers': Array<string>;
    /**
     * 
     * @type {MemberInfoOutputPrimaryMember}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'primary_member': MemberInfoOutputPrimaryMember | null;
    /**
     * Flag indicating whether the member is a household member.
     * @type {boolean}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'household_member': boolean;
    /**
     * Flag indicating whether the admin member is masqueraded.
     * @type {boolean}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'masqueraded'?: boolean;
    /**
     * Profile photo API path.
     * @type {string}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'profile_photo': string | null;
    /**
     * Flag indicating whether the member is military verified.
     * @type {boolean}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'verified': boolean;
    /**
     * The date the user was created.
     * @type {number}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'created': number;
    /**
     * User tier.
     * @type {string}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'tier': MemberInfo200ResponseAllOfDataTierEnum;
    /**
     * User first name.
     * @type {string}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'last_name': string;
    /**
     * User member ID.
     * @type {string}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'member_id': string;
    /**
     * An array of extra roles of the user.
     * @type {Array<string>}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'roles': Array<string>;
    /**
     * User ID.
     * @type {string}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'id': string;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'email': string | null;
    /**
     * User phone.
     * @type {string}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'phone': string | null;
    /**
     * 
     * @type {UserOutputVerification}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'verification': UserOutputVerification;
    /**
     * User travel code.
     * @type {string}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'travel_code': string | null;
    /**
     * The flag indicating whether the last user payment attempt failed.
     * @type {boolean}
     * @memberof MemberInfo200ResponseAllOfData
     */
    'payment_failed': boolean;
}

export const MemberInfo200ResponseAllOfDataAffinityEnum = {
    Veteran: 'veteran',
    Relative: 'relative',
    Not: 'not',
    Commissioned: 'commissioned',
    ActiveDuty: 'active_duty',
    Academy: 'academy'
} as const;

export type MemberInfo200ResponseAllOfDataAffinityEnum = typeof MemberInfo200ResponseAllOfDataAffinityEnum[keyof typeof MemberInfo200ResponseAllOfDataAffinityEnum];
export const MemberInfo200ResponseAllOfDataPlanTypeEnum = {
    Trial: 'trial',
    Year: 'year',
    Month: 'month'
} as const;

export type MemberInfo200ResponseAllOfDataPlanTypeEnum = typeof MemberInfo200ResponseAllOfDataPlanTypeEnum[keyof typeof MemberInfo200ResponseAllOfDataPlanTypeEnum];
export const MemberInfo200ResponseAllOfDataTierEnum = {
    VaUnconfirmed: 'va_unconfirmed',
    VaSupporter: 'va_supporter',
    VaMember: 'va_member',
    VaFamily: 'va_family',
    VaActiveDuty: 'va_active_duty',
    VrMember: 'vr_member',
    VrMemberD: 'vr_member_d',
    VrMemberDw: 'vr_member_dw',
    VrFamily: 'vr_family',
    VrFamilyD: 'vr_family_d',
    VrFamilyDw: 'vr_family_dw',
    VrActiveDuty: 'vr_active_duty',
    VrHousehold: 'vr_household'
} as const;

export type MemberInfo200ResponseAllOfDataTierEnum = typeof MemberInfo200ResponseAllOfDataTierEnum[keyof typeof MemberInfo200ResponseAllOfDataTierEnum];

/**
 * 
 * @export
 * @interface MemberInfoOutput
 */
export interface MemberInfoOutput {
    /**
     * User affinity.
     * @type {string}
     * @memberof MemberInfoOutput
     */
    'affinity': MemberInfoOutputAffinityEnum;
    /**
     * Plan type.
     * @type {string}
     * @memberof MemberInfoOutput
     */
    'plan_type': MemberInfoOutputPlanTypeEnum;
    /**
     * Travel protection ID.
     * @type {string}
     * @memberof MemberInfoOutput
     */
    'travel_protection': string | null;
    /**
     * 
     * @type {MemberInfoOutputMilitary}
     * @memberof MemberInfoOutput
     */
    'military': MemberInfoOutputMilitary;
    /**
     * Plan expiration time.
     * @type {number}
     * @memberof MemberInfoOutput
     */
    'plan_expires': number | null;
    /**
     * Auth providers.
     * @type {Array<string>}
     * @memberof MemberInfoOutput
     */
    'providers': Array<string>;
    /**
     * 
     * @type {MemberInfoOutputPrimaryMember}
     * @memberof MemberInfoOutput
     */
    'primary_member': MemberInfoOutputPrimaryMember | null;
    /**
     * Flag indicating whether the member is a household member.
     * @type {boolean}
     * @memberof MemberInfoOutput
     */
    'household_member': boolean;
    /**
     * Flag indicating whether the admin member is masqueraded.
     * @type {boolean}
     * @memberof MemberInfoOutput
     */
    'masqueraded'?: boolean;
    /**
     * Profile photo API path.
     * @type {string}
     * @memberof MemberInfoOutput
     */
    'profile_photo': string | null;
    /**
     * Flag indicating whether the member is military verified.
     * @type {boolean}
     * @memberof MemberInfoOutput
     */
    'verified': boolean;
    /**
     * The date the user was created.
     * @type {number}
     * @memberof MemberInfoOutput
     */
    'created': number;
    /**
     * User tier.
     * @type {string}
     * @memberof MemberInfoOutput
     */
    'tier': MemberInfoOutputTierEnum;
    /**
     * User first name.
     * @type {string}
     * @memberof MemberInfoOutput
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof MemberInfoOutput
     */
    'last_name': string;
    /**
     * User member ID.
     * @type {string}
     * @memberof MemberInfoOutput
     */
    'member_id': string;
    /**
     * An array of extra roles of the user.
     * @type {Array<string>}
     * @memberof MemberInfoOutput
     */
    'roles': Array<string>;
    /**
     * User ID.
     * @type {string}
     * @memberof MemberInfoOutput
     */
    'id': string;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof MemberInfoOutput
     */
    'email': string | null;
    /**
     * User phone.
     * @type {string}
     * @memberof MemberInfoOutput
     */
    'phone': string | null;
    /**
     * 
     * @type {UserOutputVerification}
     * @memberof MemberInfoOutput
     */
    'verification': UserOutputVerification;
    /**
     * User travel code.
     * @type {string}
     * @memberof MemberInfoOutput
     */
    'travel_code': string | null;
    /**
     * The flag indicating whether the last user payment attempt failed.
     * @type {boolean}
     * @memberof MemberInfoOutput
     */
    'payment_failed': boolean;
}

export const MemberInfoOutputAffinityEnum = {
    Veteran: 'veteran',
    Relative: 'relative',
    Not: 'not',
    Commissioned: 'commissioned',
    ActiveDuty: 'active_duty',
    Academy: 'academy'
} as const;

export type MemberInfoOutputAffinityEnum = typeof MemberInfoOutputAffinityEnum[keyof typeof MemberInfoOutputAffinityEnum];
export const MemberInfoOutputPlanTypeEnum = {
    Trial: 'trial',
    Year: 'year',
    Month: 'month'
} as const;

export type MemberInfoOutputPlanTypeEnum = typeof MemberInfoOutputPlanTypeEnum[keyof typeof MemberInfoOutputPlanTypeEnum];
export const MemberInfoOutputTierEnum = {
    VaUnconfirmed: 'va_unconfirmed',
    VaSupporter: 'va_supporter',
    VaMember: 'va_member',
    VaFamily: 'va_family',
    VaActiveDuty: 'va_active_duty',
    VrMember: 'vr_member',
    VrMemberD: 'vr_member_d',
    VrMemberDw: 'vr_member_dw',
    VrFamily: 'vr_family',
    VrFamilyD: 'vr_family_d',
    VrFamilyDw: 'vr_family_dw',
    VrActiveDuty: 'vr_active_duty',
    VrHousehold: 'vr_household'
} as const;

export type MemberInfoOutputTierEnum = typeof MemberInfoOutputTierEnum[keyof typeof MemberInfoOutputTierEnum];

/**
 * Military info.
 * @export
 * @interface MemberInfoOutputMilitary
 */
export interface MemberInfoOutputMilitary {
    /**
     * 
     * @type {MemberMilitaryCredentialsOutputMilitaryInfoBranch}
     * @memberof MemberInfoOutputMilitary
     */
    'branch': MemberMilitaryCredentialsOutputMilitaryInfoBranch | null;
    /**
     * Military era info.
     * @type {string}
     * @memberof MemberInfoOutputMilitary
     */
    'era': string | null;
}
/**
 * Primary member data if it\'s household member.
 * @export
 * @interface MemberInfoOutputPrimaryMember
 */
export interface MemberInfoOutputPrimaryMember {
    /**
     * User affinity.
     * @type {string}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'affinity': MemberInfoOutputPrimaryMemberAffinityEnum;
    /**
     * Plan type.
     * @type {string}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'plan_type': MemberInfoOutputPrimaryMemberPlanTypeEnum;
    /**
     * Travel protection ID.
     * @type {string}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'travel_protection': string | null;
    /**
     * 
     * @type {MemberInfoOutputMilitary}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'military': MemberInfoOutputMilitary;
    /**
     * Plan expiration time.
     * @type {number}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'plan_expires': number | null;
    /**
     * Auth providers.
     * @type {Array<string>}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'providers': Array<string>;
    /**
     * 
     * @type {MemberInfoOutputPrimaryMember}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'primary_member': MemberInfoOutputPrimaryMember | null;
    /**
     * Flag indicating whether the member is a household member.
     * @type {boolean}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'household_member': boolean;
    /**
     * Flag indicating whether the admin member is masqueraded.
     * @type {boolean}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'masqueraded'?: boolean;
    /**
     * Profile photo API path.
     * @type {string}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'profile_photo': string | null;
    /**
     * Flag indicating whether the member is military verified.
     * @type {boolean}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'verified': boolean;
    /**
     * The date the user was created.
     * @type {number}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'created': number;
    /**
     * User tier.
     * @type {string}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'tier': MemberInfoOutputPrimaryMemberTierEnum;
    /**
     * User first name.
     * @type {string}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'last_name': string;
    /**
     * User member ID.
     * @type {string}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'member_id': string;
    /**
     * An array of extra roles of the user.
     * @type {Array<string>}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'roles': Array<string>;
    /**
     * User ID.
     * @type {string}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'id': string;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'email': string | null;
    /**
     * User phone.
     * @type {string}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'phone': string | null;
    /**
     * 
     * @type {UserOutputVerification}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'verification': UserOutputVerification;
    /**
     * User travel code.
     * @type {string}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'travel_code': string | null;
    /**
     * The flag indicating whether the last user payment attempt failed.
     * @type {boolean}
     * @memberof MemberInfoOutputPrimaryMember
     */
    'payment_failed': boolean;
}

export const MemberInfoOutputPrimaryMemberAffinityEnum = {
    Veteran: 'veteran',
    Relative: 'relative',
    Not: 'not',
    Commissioned: 'commissioned',
    ActiveDuty: 'active_duty',
    Academy: 'academy'
} as const;

export type MemberInfoOutputPrimaryMemberAffinityEnum = typeof MemberInfoOutputPrimaryMemberAffinityEnum[keyof typeof MemberInfoOutputPrimaryMemberAffinityEnum];
export const MemberInfoOutputPrimaryMemberPlanTypeEnum = {
    Trial: 'trial',
    Year: 'year',
    Month: 'month'
} as const;

export type MemberInfoOutputPrimaryMemberPlanTypeEnum = typeof MemberInfoOutputPrimaryMemberPlanTypeEnum[keyof typeof MemberInfoOutputPrimaryMemberPlanTypeEnum];
export const MemberInfoOutputPrimaryMemberTierEnum = {
    VaUnconfirmed: 'va_unconfirmed',
    VaSupporter: 'va_supporter',
    VaMember: 'va_member',
    VaFamily: 'va_family',
    VaActiveDuty: 'va_active_duty',
    VrMember: 'vr_member',
    VrMemberD: 'vr_member_d',
    VrMemberDw: 'vr_member_dw',
    VrFamily: 'vr_family',
    VrFamilyD: 'vr_family_d',
    VrFamilyDw: 'vr_family_dw',
    VrActiveDuty: 'vr_active_duty',
    VrHousehold: 'vr_household'
} as const;

export type MemberInfoOutputPrimaryMemberTierEnum = typeof MemberInfoOutputPrimaryMemberTierEnum[keyof typeof MemberInfoOutputPrimaryMemberTierEnum];

/**
 * 
 * @export
 * @interface MemberInvoiceFullOutput
 */
export interface MemberInvoiceFullOutput {
    /**
     * Invoice status.
     * @type {string}
     * @memberof MemberInvoiceFullOutput
     */
    'status': MemberInvoiceFullOutputStatusEnum;
    /**
     * Invoice ID.
     * @type {string}
     * @memberof MemberInvoiceFullOutput
     */
    'id': string;
    /**
     * Charge ID.
     * @type {string}
     * @memberof MemberInvoiceFullOutput
     */
    'charge': string | null;
    /**
     * Total amount.
     * @type {number}
     * @memberof MemberInvoiceFullOutput
     */
    'total': number;
    /**
     * Invoice currency.
     * @type {string}
     * @memberof MemberInvoiceFullOutput
     */
    'currency': MemberInvoiceFullOutputCurrencyEnum;
    /**
     * Timestamp in milliseconds at which the invoice was created.
     * @type {number}
     * @memberof MemberInvoiceFullOutput
     */
    'created': number;
    /**
     * Timestamp in milliseconds at which the invoice was created.
     * @type {number}
     * @memberof MemberInvoiceFullOutput
     */
    'period_start': number;
    /**
     * Timestamp in milliseconds at which the invoice was created.
     * @type {number}
     * @memberof MemberInvoiceFullOutput
     */
    'period_end': number;
    /**
     * Invoice number.
     * @type {string}
     * @memberof MemberInvoiceFullOutput
     */
    'number': string | null;
    /**
     * Customer email.
     * @type {string}
     * @memberof MemberInvoiceFullOutput
     */
    'customer_email': string | null;
    /**
     * Customer name.
     * @type {string}
     * @memberof MemberInvoiceFullOutput
     */
    'customer_name': string | null;
    /**
     * 
     * @type {MemberInvoiceFullOutputCustomerAddress}
     * @memberof MemberInvoiceFullOutput
     */
    'customer_address': MemberInvoiceFullOutputCustomerAddress | null;
    /**
     * Customer phone.
     * @type {string}
     * @memberof MemberInvoiceFullOutput
     */
    'customer_phone': string | null;
    /**
     * Default payment method.
     * @type {string}
     * @memberof MemberInvoiceFullOutput
     */
    'default_payment_method': string | null;
    /**
     * Auto advance setting.
     * @type {boolean}
     * @memberof MemberInvoiceFullOutput
     */
    'auto_advance': boolean;
    /**
     * Invoice PDF URL.
     * @type {string}
     * @memberof MemberInvoiceFullOutput
     */
    'invoice_pdf': string | null;
    /**
     * Array of charges.
     * @type {Array<MemberInvoiceFullOutputChargeOutput>}
     * @memberof MemberInvoiceFullOutput
     */
    'charges': Array<MemberInvoiceFullOutputChargeOutput>;
    /**
     * Array of invoice line items.
     * @type {Array<MemberInvoiceFullOutputLineItemOutput>}
     * @memberof MemberInvoiceFullOutput
     */
    'lines': Array<MemberInvoiceFullOutputLineItemOutput>;
}

export const MemberInvoiceFullOutputStatusEnum = {
    Deleted: 'deleted',
    Draft: 'draft',
    Open: 'open',
    Paid: 'paid',
    Uncollectible: 'uncollectible',
    Void: 'void'
} as const;

export type MemberInvoiceFullOutputStatusEnum = typeof MemberInvoiceFullOutputStatusEnum[keyof typeof MemberInvoiceFullOutputStatusEnum];
export const MemberInvoiceFullOutputCurrencyEnum = {
    Usd: 'usd'
} as const;

export type MemberInvoiceFullOutputCurrencyEnum = typeof MemberInvoiceFullOutputCurrencyEnum[keyof typeof MemberInvoiceFullOutputCurrencyEnum];

/**
 * 
 * @export
 * @interface MemberInvoiceFullOutputAddress
 */
export interface MemberInvoiceFullOutputAddress {
    /**
     * City/District/Suburb/Town/Village.
     * @type {string}
     * @memberof MemberInvoiceFullOutputAddress
     */
    'city': string | null;
    /**
     * 2-letter country code.
     * @type {string}
     * @memberof MemberInvoiceFullOutputAddress
     */
    'country': string | null;
    /**
     * Address line 1 (Street address/PO Box/Company name).
     * @type {string}
     * @memberof MemberInvoiceFullOutputAddress
     */
    'line1': string | null;
    /**
     * Address line 2 (Apartment/Suite/Unit/Building).
     * @type {string}
     * @memberof MemberInvoiceFullOutputAddress
     */
    'line2': string | null;
    /**
     * ZIP or postal code.
     * @type {string}
     * @memberof MemberInvoiceFullOutputAddress
     */
    'postal_code': string | null;
    /**
     * State/County/Province/Region.
     * @type {string}
     * @memberof MemberInvoiceFullOutputAddress
     */
    'state': string | null;
}
/**
 * 
 * @export
 * @interface MemberInvoiceFullOutputChargeOutput
 */
export interface MemberInvoiceFullOutputChargeOutput {
    /**
     * Charge ID.
     * @type {string}
     * @memberof MemberInvoiceFullOutputChargeOutput
     */
    'id': string;
    /**
     * Charge amount.
     * @type {number}
     * @memberof MemberInvoiceFullOutputChargeOutput
     */
    'amount': number;
    /**
     * Amount refunded from charge.
     * @type {number}
     * @memberof MemberInvoiceFullOutputChargeOutput
     */
    'amount_refunded': number;
    /**
     * Currency of the charge.
     * @type {string}
     * @memberof MemberInvoiceFullOutputChargeOutput
     */
    'currency': string;
}
/**
 * Customer address.
 * @export
 * @interface MemberInvoiceFullOutputCustomerAddress
 */
export interface MemberInvoiceFullOutputCustomerAddress {
    /**
     * City/District/Suburb/Town/Village.
     * @type {string}
     * @memberof MemberInvoiceFullOutputCustomerAddress
     */
    'city': string | null;
    /**
     * 2-letter country code.
     * @type {string}
     * @memberof MemberInvoiceFullOutputCustomerAddress
     */
    'country': string | null;
    /**
     * Address line 1 (Street address/PO Box/Company name).
     * @type {string}
     * @memberof MemberInvoiceFullOutputCustomerAddress
     */
    'line1': string | null;
    /**
     * Address line 2 (Apartment/Suite/Unit/Building).
     * @type {string}
     * @memberof MemberInvoiceFullOutputCustomerAddress
     */
    'line2': string | null;
    /**
     * ZIP or postal code.
     * @type {string}
     * @memberof MemberInvoiceFullOutputCustomerAddress
     */
    'postal_code': string | null;
    /**
     * State/County/Province/Region.
     * @type {string}
     * @memberof MemberInvoiceFullOutputCustomerAddress
     */
    'state': string | null;
}
/**
 * 
 * @export
 * @interface MemberInvoiceFullOutputLineItemOutput
 */
export interface MemberInvoiceFullOutputLineItemOutput {
    /**
     * Line item ID.
     * @type {string}
     * @memberof MemberInvoiceFullOutputLineItemOutput
     */
    'id': string;
    /**
     * Line item description.
     * @type {string}
     * @memberof MemberInvoiceFullOutputLineItemOutput
     */
    'description': string;
    /**
     * Quantity of line item.
     * @type {number}
     * @memberof MemberInvoiceFullOutputLineItemOutput
     */
    'quantity': number;
    /**
     * Amount for the line item.
     * @type {number}
     * @memberof MemberInvoiceFullOutputLineItemOutput
     */
    'amount': number;
    /**
     * Price of the line item.
     * @type {number}
     * @memberof MemberInvoiceFullOutputLineItemOutput
     */
    'price': number | null;
}
/**
 * 
 * @export
 * @interface MemberInvoiceOutput
 */
export interface MemberInvoiceOutput {
    /**
     * Invoice status.
     * @type {string}
     * @memberof MemberInvoiceOutput
     */
    'status': MemberInvoiceOutputStatusEnum;
    /**
     * Invoice ID.
     * @type {string}
     * @memberof MemberInvoiceOutput
     */
    'id': string;
    /**
     * Invoice number.
     * @type {string}
     * @memberof MemberInvoiceOutput
     */
    'number': string | null;
    /**
     * Invoice amount in cents.
     * @type {number}
     * @memberof MemberInvoiceOutput
     */
    'amount': number;
    /**
     * Invoice currency.
     * @type {string}
     * @memberof MemberInvoiceOutput
     */
    'currency': MemberInvoiceOutputCurrencyEnum;
    /**
     * Invoice billing reason.
     * @type {string}
     * @memberof MemberInvoiceOutput
     */
    'billing_reason': string | null;
    /**
     * Timestamp in milliseconds at which the invoice was charged.
     * @type {number}
     * @memberof MemberInvoiceOutput
     */
    'charged': number | null;
    /**
     * Timestamp in milliseconds at which the invoice was created.
     * @type {number}
     * @memberof MemberInvoiceOutput
     */
    'created': number;
    /**
     * The link to download the PDF for the invoice.
     * @type {string}
     * @memberof MemberInvoiceOutput
     */
    'pdf': string | null;
}

export const MemberInvoiceOutputStatusEnum = {
    Deleted: 'deleted',
    Draft: 'draft',
    Open: 'open',
    Paid: 'paid',
    Uncollectible: 'uncollectible',
    Void: 'void'
} as const;

export type MemberInvoiceOutputStatusEnum = typeof MemberInvoiceOutputStatusEnum[keyof typeof MemberInvoiceOutputStatusEnum];
export const MemberInvoiceOutputCurrencyEnum = {
    Usd: 'usd'
} as const;

export type MemberInvoiceOutputCurrencyEnum = typeof MemberInvoiceOutputCurrencyEnum[keyof typeof MemberInvoiceOutputCurrencyEnum];

/**
 * 
 * @export
 * @interface MemberInvoices200Response
 */
export interface MemberInvoices200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberInvoices200Response
     */
    'status': MemberInvoices200ResponseStatusEnum;
    /**
     * 
     * @type {MemberInvoices200ResponseAllOfData}
     * @memberof MemberInvoices200Response
     */
    'data': MemberInvoices200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberInvoices200Response
     */
    'warnings'?: Array<string>;
}

export const MemberInvoices200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberInvoices200ResponseStatusEnum = typeof MemberInvoices200ResponseStatusEnum[keyof typeof MemberInvoices200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberInvoices200ResponseAllOf
 */
export interface MemberInvoices200ResponseAllOf {
    /**
     * 
     * @type {MemberInvoices200ResponseAllOfData}
     * @memberof MemberInvoices200ResponseAllOf
     */
    'data'?: MemberInvoices200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MemberInvoices200ResponseAllOfData
 */
export interface MemberInvoices200ResponseAllOfData {
    /**
     * 
     * @type {Array<MemberInvoiceOutput>}
     * @memberof MemberInvoices200ResponseAllOfData
     */
    'items': Array<MemberInvoiceOutput>;
    /**
     * Indicates whether there are additional items beyond the end of this list.
     * @type {boolean}
     * @memberof MemberInvoices200ResponseAllOfData
     */
    'has_more': boolean;
    /**
     * The total number of items in the list (optional).
     * @type {number}
     * @memberof MemberInvoices200ResponseAllOfData
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface MemberInvoices200ResponseAllOfDataAllOf
 */
export interface MemberInvoices200ResponseAllOfDataAllOf {
    /**
     * 
     * @type {Array<MemberInvoiceOutput>}
     * @memberof MemberInvoices200ResponseAllOfDataAllOf
     */
    'items'?: Array<MemberInvoiceOutput>;
}
/**
 * 
 * @export
 * @interface MemberMilitaryCredentials200Response
 */
export interface MemberMilitaryCredentials200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberMilitaryCredentials200Response
     */
    'status': MemberMilitaryCredentials200ResponseStatusEnum;
    /**
     * 
     * @type {MemberMilitaryCredentials200ResponseAllOfData}
     * @memberof MemberMilitaryCredentials200Response
     */
    'data': MemberMilitaryCredentials200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberMilitaryCredentials200Response
     */
    'warnings'?: Array<string>;
}

export const MemberMilitaryCredentials200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberMilitaryCredentials200ResponseStatusEnum = typeof MemberMilitaryCredentials200ResponseStatusEnum[keyof typeof MemberMilitaryCredentials200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberMilitaryCredentials200ResponseAllOf
 */
export interface MemberMilitaryCredentials200ResponseAllOf {
    /**
     * 
     * @type {MemberMilitaryCredentials200ResponseAllOfData}
     * @memberof MemberMilitaryCredentials200ResponseAllOf
     */
    'data'?: MemberMilitaryCredentials200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MemberMilitaryCredentials200ResponseAllOfData
 */
export interface MemberMilitaryCredentials200ResponseAllOfData {
    /**
     * 
     * @type {MemberInfoOutputMilitary}
     * @memberof MemberMilitaryCredentials200ResponseAllOfData
     */
    'military': MemberInfoOutputMilitary;
    /**
     * User first name.
     * @type {string}
     * @memberof MemberMilitaryCredentials200ResponseAllOfData
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof MemberMilitaryCredentials200ResponseAllOfData
     */
    'last_name': string;
}
/**
 * 
 * @export
 * @interface MemberMilitaryCredentialsOutput
 */
export interface MemberMilitaryCredentialsOutput {
    /**
     * 
     * @type {MemberInfoOutputMilitary}
     * @memberof MemberMilitaryCredentialsOutput
     */
    'military': MemberInfoOutputMilitary;
    /**
     * User first name.
     * @type {string}
     * @memberof MemberMilitaryCredentialsOutput
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof MemberMilitaryCredentialsOutput
     */
    'last_name': string;
}
/**
 * 
 * @export
 * @interface MemberMilitaryCredentialsOutputMilitaryInfo
 */
export interface MemberMilitaryCredentialsOutputMilitaryInfo {
    /**
     * 
     * @type {MemberMilitaryCredentialsOutputMilitaryInfoBranch}
     * @memberof MemberMilitaryCredentialsOutputMilitaryInfo
     */
    'branch': MemberMilitaryCredentialsOutputMilitaryInfoBranch | null;
    /**
     * Military era info.
     * @type {string}
     * @memberof MemberMilitaryCredentialsOutputMilitaryInfo
     */
    'era': string | null;
}
/**
 * Military branch info.
 * @export
 * @interface MemberMilitaryCredentialsOutputMilitaryInfoBranch
 */
export interface MemberMilitaryCredentialsOutputMilitaryInfoBranch {
    /**
     * Military branch name.
     * @type {string}
     * @memberof MemberMilitaryCredentialsOutputMilitaryInfoBranch
     */
    'name': string;
    /**
     * Military branch logo URL.
     * @type {string}
     * @memberof MemberMilitaryCredentialsOutputMilitaryInfoBranch
     */
    'logo': string;
}
/**
 * 
 * @export
 * @interface MemberPaymentMethodCreate200Response
 */
export interface MemberPaymentMethodCreate200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberPaymentMethodCreate200Response
     */
    'status': MemberPaymentMethodCreate200ResponseStatusEnum;
    /**
     * 
     * @type {MemberPaymentMethodCreate200ResponseAllOfData}
     * @memberof MemberPaymentMethodCreate200Response
     */
    'data': MemberPaymentMethodCreate200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberPaymentMethodCreate200Response
     */
    'warnings'?: Array<string>;
}

export const MemberPaymentMethodCreate200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberPaymentMethodCreate200ResponseStatusEnum = typeof MemberPaymentMethodCreate200ResponseStatusEnum[keyof typeof MemberPaymentMethodCreate200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberPaymentMethodCreate200ResponseAllOf
 */
export interface MemberPaymentMethodCreate200ResponseAllOf {
    /**
     * 
     * @type {MemberPaymentMethodCreate200ResponseAllOfData}
     * @memberof MemberPaymentMethodCreate200ResponseAllOf
     */
    'data'?: MemberPaymentMethodCreate200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MemberPaymentMethodCreate200ResponseAllOfData
 */
export interface MemberPaymentMethodCreate200ResponseAllOfData {
    /**
     * Payment method ID.
     * @type {string}
     * @memberof MemberPaymentMethodCreate200ResponseAllOfData
     */
    'id': string;
    /**
     * Card brand.
     * @type {string}
     * @memberof MemberPaymentMethodCreate200ResponseAllOfData
     */
    'brand': string;
    /**
     * The last four digits of the card.
     * @type {string}
     * @memberof MemberPaymentMethodCreate200ResponseAllOfData
     */
    'last4': string;
    /**
     * Two-digit number representing the card\'s expiration month.
     * @type {number}
     * @memberof MemberPaymentMethodCreate200ResponseAllOfData
     */
    'exp_month': number;
    /**
     * Four-digit number representing the card\'s expiration year.
     * @type {number}
     * @memberof MemberPaymentMethodCreate200ResponseAllOfData
     */
    'exp_year': number;
    /**
     * Cardholder name.
     * @type {string}
     * @memberof MemberPaymentMethodCreate200ResponseAllOfData
     */
    'name': string | null;
    /**
     * 
     * @type {MemberPaymentMethodOutputAddress}
     * @memberof MemberPaymentMethodCreate200ResponseAllOfData
     */
    'address': MemberPaymentMethodOutputAddress;
    /**
     * Flag indicating whether this payment method is default.
     * @type {boolean}
     * @memberof MemberPaymentMethodCreate200ResponseAllOfData
     */
    'default': boolean;
    /**
     * Flag indicating whether the card is expired.
     * @type {boolean}
     * @memberof MemberPaymentMethodCreate200ResponseAllOfData
     */
    'expired': boolean;
}
/**
 * 
 * @export
 * @interface MemberPaymentMethodCreateInput
 */
export interface MemberPaymentMethodCreateInput {
    /**
     * Card token.
     * @type {string}
     * @memberof MemberPaymentMethodCreateInput
     */
    'card_token': string;
    /**
     * Flag indicating whether to make this payment method default.
     * @type {boolean}
     * @memberof MemberPaymentMethodCreateInput
     */
    'default': boolean;
}
/**
 * 
 * @export
 * @interface MemberPaymentMethodOutput
 */
export interface MemberPaymentMethodOutput {
    /**
     * Payment method ID.
     * @type {string}
     * @memberof MemberPaymentMethodOutput
     */
    'id': string;
    /**
     * Card brand.
     * @type {string}
     * @memberof MemberPaymentMethodOutput
     */
    'brand': string;
    /**
     * The last four digits of the card.
     * @type {string}
     * @memberof MemberPaymentMethodOutput
     */
    'last4': string;
    /**
     * Two-digit number representing the card\'s expiration month.
     * @type {number}
     * @memberof MemberPaymentMethodOutput
     */
    'exp_month': number;
    /**
     * Four-digit number representing the card\'s expiration year.
     * @type {number}
     * @memberof MemberPaymentMethodOutput
     */
    'exp_year': number;
    /**
     * Cardholder name.
     * @type {string}
     * @memberof MemberPaymentMethodOutput
     */
    'name': string | null;
    /**
     * 
     * @type {MemberPaymentMethodOutputAddress}
     * @memberof MemberPaymentMethodOutput
     */
    'address': MemberPaymentMethodOutputAddress;
    /**
     * Flag indicating whether this payment method is default.
     * @type {boolean}
     * @memberof MemberPaymentMethodOutput
     */
    'default': boolean;
    /**
     * Flag indicating whether the card is expired.
     * @type {boolean}
     * @memberof MemberPaymentMethodOutput
     */
    'expired': boolean;
}
/**
 * Billing address.
 * @export
 * @interface MemberPaymentMethodOutputAddress
 */
export interface MemberPaymentMethodOutputAddress {
    /**
     * Address type.
     * @type {string}
     * @memberof MemberPaymentMethodOutputAddress
     */
    'type': MemberPaymentMethodOutputAddressTypeEnum;
    /**
     * Street address.
     * @type {string}
     * @memberof MemberPaymentMethodOutputAddress
     */
    'line1': string | null;
    /**
     * Apartment, suite, box number, etc.
     * @type {string}
     * @memberof MemberPaymentMethodOutputAddress
     */
    'line2': string | null;
    /**
     * Postal code, ZIP (or ZIP+4) code.
     * @type {string}
     * @memberof MemberPaymentMethodOutputAddress
     */
    'postal_code': string | null;
    /**
     * Depending on address type: - home: city or town; - post: \"APO\", \"FPO\", or \"DPO\".
     * @type {string}
     * @memberof MemberPaymentMethodOutputAddress
     */
    'city': string | null;
    /**
     * State, province, region (ISO code when available).
     * @type {string}
     * @memberof MemberPaymentMethodOutputAddress
     */
    'state': string | null;
    /**
     * Country (2 character ISO code).
     * @type {string}
     * @memberof MemberPaymentMethodOutputAddress
     */
    'country': string | null;
}

export const MemberPaymentMethodOutputAddressTypeEnum = {
    Home: 'home',
    Post: 'post'
} as const;

export type MemberPaymentMethodOutputAddressTypeEnum = typeof MemberPaymentMethodOutputAddressTypeEnum[keyof typeof MemberPaymentMethodOutputAddressTypeEnum];

/**
 * 
 * @export
 * @interface MemberPaymentMethodUpdateInput
 */
export interface MemberPaymentMethodUpdateInput {
    /**
     * Four-digit number representing the card\'s expiration year.
     * @type {number}
     * @memberof MemberPaymentMethodUpdateInput
     */
    'exp_year': number;
    /**
     * Two-digit number representing the card\'s expiration month.
     * @type {number}
     * @memberof MemberPaymentMethodUpdateInput
     */
    'exp_month': number;
    /**
     * Cardholder name.
     * @type {string}
     * @memberof MemberPaymentMethodUpdateInput
     */
    'name': string;
    /**
     * 
     * @type {MemberPaymentMethodUpdateInputAddress}
     * @memberof MemberPaymentMethodUpdateInput
     */
    'address': MemberPaymentMethodUpdateInputAddress;
    /**
     * Flag indicating whether to make this payment method default. Should not be set to False, if this payment method is default.
     * @type {boolean}
     * @memberof MemberPaymentMethodUpdateInput
     */
    'default': boolean;
}
/**
 * Billing address.
 * @export
 * @interface MemberPaymentMethodUpdateInputAddress
 */
export interface MemberPaymentMethodUpdateInputAddress {
    /**
     * Address type.
     * @type {string}
     * @memberof MemberPaymentMethodUpdateInputAddress
     */
    'type': MemberPaymentMethodUpdateInputAddressTypeEnum;
    /**
     * Street address.
     * @type {string}
     * @memberof MemberPaymentMethodUpdateInputAddress
     */
    'line1': string;
    /**
     * Apartment, suite, box number, etc.
     * @type {string}
     * @memberof MemberPaymentMethodUpdateInputAddress
     */
    'line2'?: string | null;
    /**
     * Postal code, ZIP (or ZIP+4) code.
     * @type {string}
     * @memberof MemberPaymentMethodUpdateInputAddress
     */
    'postal_code': string;
    /**
     * Depending on address type: - home: city or town; - post: \"APO\", \"FPO\", or \"DPO\".
     * @type {string}
     * @memberof MemberPaymentMethodUpdateInputAddress
     */
    'city': string;
    /**
     * State, province, region (ISO code when available).
     * @type {string}
     * @memberof MemberPaymentMethodUpdateInputAddress
     */
    'state': string;
    /**
     * Country (2 character ISO code).
     * @type {string}
     * @memberof MemberPaymentMethodUpdateInputAddress
     */
    'country'?: string | null;
}

export const MemberPaymentMethodUpdateInputAddressTypeEnum = {
    Home: 'home',
    Post: 'post'
} as const;

export type MemberPaymentMethodUpdateInputAddressTypeEnum = typeof MemberPaymentMethodUpdateInputAddressTypeEnum[keyof typeof MemberPaymentMethodUpdateInputAddressTypeEnum];

/**
 * 
 * @export
 * @interface MemberPaymentMethods200Response
 */
export interface MemberPaymentMethods200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberPaymentMethods200Response
     */
    'status': MemberPaymentMethods200ResponseStatusEnum;
    /**
     * 
     * @type {MemberPaymentMethods200ResponseAllOfData}
     * @memberof MemberPaymentMethods200Response
     */
    'data': MemberPaymentMethods200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberPaymentMethods200Response
     */
    'warnings'?: Array<string>;
}

export const MemberPaymentMethods200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberPaymentMethods200ResponseStatusEnum = typeof MemberPaymentMethods200ResponseStatusEnum[keyof typeof MemberPaymentMethods200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberPaymentMethods200ResponseAllOf
 */
export interface MemberPaymentMethods200ResponseAllOf {
    /**
     * 
     * @type {MemberPaymentMethods200ResponseAllOfData}
     * @memberof MemberPaymentMethods200ResponseAllOf
     */
    'data'?: MemberPaymentMethods200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MemberPaymentMethods200ResponseAllOfData
 */
export interface MemberPaymentMethods200ResponseAllOfData {
    /**
     * 
     * @type {Array<MemberPaymentMethodOutput>}
     * @memberof MemberPaymentMethods200ResponseAllOfData
     */
    'items': Array<MemberPaymentMethodOutput>;
    /**
     * Indicates whether there are additional items beyond the end of this list.
     * @type {boolean}
     * @memberof MemberPaymentMethods200ResponseAllOfData
     */
    'has_more': boolean;
    /**
     * The total number of items in the list (optional).
     * @type {number}
     * @memberof MemberPaymentMethods200ResponseAllOfData
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface MemberPaymentMethods200ResponseAllOfDataAllOf
 */
export interface MemberPaymentMethods200ResponseAllOfDataAllOf {
    /**
     * 
     * @type {Array<MemberPaymentMethodOutput>}
     * @memberof MemberPaymentMethods200ResponseAllOfDataAllOf
     */
    'items'?: Array<MemberPaymentMethodOutput>;
}
/**
 * 
 * @export
 * @interface MemberProfileInput
 */
export interface MemberProfileInput {
    /**
     * User gender.
     * @type {string}
     * @memberof MemberProfileInput
     */
    'gender'?: MemberProfileInputGenderEnum;
    /**
     * User gender (other). Required if gender is \"other\".
     * @type {string}
     * @memberof MemberProfileInput
     */
    'gender_other'?: string | null;
    /**
     * User honorific.
     * @type {string}
     * @memberof MemberProfileInput
     */
    'honorific'?: MemberProfileInputHonorificEnum;
    /**
     * 
     * @type {MemberProfileInputHouseholdMember}
     * @memberof MemberProfileInput
     */
    'household_member'?: MemberProfileInputHouseholdMember;
    /**
     * 
     * @type {MemberProfileInputMilitary}
     * @memberof MemberProfileInput
     */
    'military'?: MemberProfileInputMilitary;
    /**
     * User date of birth.
     * @type {string}
     * @memberof MemberProfileInput
     */
    'dob'?: string | null;
    /**
     * User first name.
     * @type {string}
     * @memberof MemberProfileInput
     */
    'first_name'?: string;
    /**
     * User last name.
     * @type {string}
     * @memberof MemberProfileInput
     */
    'last_name'?: string;
    /**
     * User middle name.
     * @type {string}
     * @memberof MemberProfileInput
     */
    'middle_name'?: string | null;
    /**
     * User timezone.
     * @type {string}
     * @memberof MemberProfileInput
     */
    'timezone'?: string | null;
    /**
     * 
     * @type {FulfillmentRecordOutputAddress}
     * @memberof MemberProfileInput
     */
    'address'?: FulfillmentRecordOutputAddress | null;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof MemberProfileInput
     */
    'email'?: string | null;
}

export const MemberProfileInputGenderEnum = {
    Male: 'male',
    Female: 'female',
    Other: 'other'
} as const;

export type MemberProfileInputGenderEnum = typeof MemberProfileInputGenderEnum[keyof typeof MemberProfileInputGenderEnum];
export const MemberProfileInputHonorificEnum = {
    Dr: 'dr',
    Miss: 'miss',
    Mr: 'mr',
    Mrs: 'mrs',
    Ms: 'ms',
    Mstr: 'mstr',
    Prof: 'prof',
    Rev: 'rev',
    Sir: 'sir',
    Sr: 'sr'
} as const;

export type MemberProfileInputHonorificEnum = typeof MemberProfileInputHonorificEnum[keyof typeof MemberProfileInputHonorificEnum];

/**
 * Household member info.
 * @export
 * @interface MemberProfileInputHouseholdMember
 */
export interface MemberProfileInputHouseholdMember {
    /**
     * Whether the household member is above 13 y.o.
     * @type {boolean}
     * @memberof MemberProfileInputHouseholdMember
     */
    'above_13'?: boolean;
    /**
     * Household member relationship to a primary user.
     * @type {string}
     * @memberof MemberProfileInputHouseholdMember
     */
    'relationship'?: MemberProfileInputHouseholdMemberRelationshipEnum;
}

export const MemberProfileInputHouseholdMemberRelationshipEnum = {
    Spouse: 'spouse',
    Father: 'father',
    Mother: 'mother',
    Child: 'child',
    Brother: 'brother',
    Sister: 'sister'
} as const;

export type MemberProfileInputHouseholdMemberRelationshipEnum = typeof MemberProfileInputHouseholdMemberRelationshipEnum[keyof typeof MemberProfileInputHouseholdMemberRelationshipEnum];

/**
 * Military info.
 * @export
 * @interface MemberProfileInputMilitary
 */
export interface MemberProfileInputMilitary {
    /**
     * User military branch ID.
     * @type {string}
     * @memberof MemberProfileInputMilitary
     */
    'branch'?: string | null;
    /**
     * User military rank ID.
     * @type {string}
     * @memberof MemberProfileInputMilitary
     */
    'rank'?: string | null;
}
/**
 * 
 * @export
 * @interface MemberProfileInputMilitaryInfo
 */
export interface MemberProfileInputMilitaryInfo {
    /**
     * User military branch ID.
     * @type {string}
     * @memberof MemberProfileInputMilitaryInfo
     */
    'branch'?: string | null;
    /**
     * User military rank ID.
     * @type {string}
     * @memberof MemberProfileInputMilitaryInfo
     */
    'rank'?: string | null;
}
/**
 * 
 * @export
 * @interface MemberProfileOutput
 */
export interface MemberProfileOutput {
    /**
     * User gender.
     * @type {string}
     * @memberof MemberProfileOutput
     */
    'gender': MemberProfileOutputGenderEnum;
    /**
     * User gender (other). Required if gender is \"other\".
     * @type {string}
     * @memberof MemberProfileOutput
     */
    'gender_other': string | null;
    /**
     * User honorific.
     * @type {string}
     * @memberof MemberProfileOutput
     */
    'honorific': MemberProfileOutputHonorificEnum;
    /**
     * Profile photo API path.
     * @type {string}
     * @memberof MemberProfileOutput
     */
    'profile_photo': string | null;
    /**
     * User military verification status
     * @type {boolean}
     * @memberof MemberProfileOutput
     */
    'verified': boolean;
    /**
     * User first name.
     * @type {string}
     * @memberof MemberProfileOutput
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof MemberProfileOutput
     */
    'last_name': string;
    /**
     * User middle name.
     * @type {string}
     * @memberof MemberProfileOutput
     */
    'middle_name': string | null;
    /**
     * 
     * @type {UserOutputVerification}
     * @memberof MemberProfileOutput
     */
    'verification': UserOutputVerification;
    /**
     * User phone.
     * @type {string}
     * @memberof MemberProfileOutput
     */
    'phone': string | null;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof MemberProfileOutput
     */
    'email': string | null;
    /**
     * User date of birth.
     * @type {string}
     * @memberof MemberProfileOutput
     */
    'dob': string | null;
    /**
     * 
     * @type {UserOutputHouseholdMember}
     * @memberof MemberProfileOutput
     */
    'household_member': UserOutputHouseholdMember | null;
    /**
     * User timezone.
     * @type {string}
     * @memberof MemberProfileOutput
     */
    'timezone': string | null;
    /**
     * 
     * @type {FulfillmentRecordOutputAddress}
     * @memberof MemberProfileOutput
     */
    'address': FulfillmentRecordOutputAddress | null;
    /**
     * 
     * @type {UserOutputMilitary}
     * @memberof MemberProfileOutput
     */
    'military': UserOutputMilitary;
    /**
     * User MRP program.
     * @type {string}
     * @memberof MemberProfileOutput
     */
    'mrp': string | null;
}

export const MemberProfileOutputGenderEnum = {
    Male: 'male',
    Female: 'female',
    Other: 'other'
} as const;

export type MemberProfileOutputGenderEnum = typeof MemberProfileOutputGenderEnum[keyof typeof MemberProfileOutputGenderEnum];
export const MemberProfileOutputHonorificEnum = {
    Dr: 'dr',
    Miss: 'miss',
    Mr: 'mr',
    Mrs: 'mrs',
    Ms: 'ms',
    Mstr: 'mstr',
    Prof: 'prof',
    Rev: 'rev',
    Sir: 'sir',
    Sr: 'sr'
} as const;

export type MemberProfileOutputHonorificEnum = typeof MemberProfileOutputHonorificEnum[keyof typeof MemberProfileOutputHonorificEnum];

/**
 * 
 * @export
 * @interface MemberProfilePhotoInfo200Response
 */
export interface MemberProfilePhotoInfo200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberProfilePhotoInfo200Response
     */
    'status': MemberProfilePhotoInfo200ResponseStatusEnum;
    /**
     * 
     * @type {MemberProfilePhotoOutput}
     * @memberof MemberProfilePhotoInfo200Response
     */
    'data': MemberProfilePhotoOutput | null;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberProfilePhotoInfo200Response
     */
    'warnings'?: Array<string>;
}

export const MemberProfilePhotoInfo200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberProfilePhotoInfo200ResponseStatusEnum = typeof MemberProfilePhotoInfo200ResponseStatusEnum[keyof typeof MemberProfilePhotoInfo200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberProfilePhotoInfo200ResponseAllOf
 */
export interface MemberProfilePhotoInfo200ResponseAllOf {
    /**
     * 
     * @type {MemberProfilePhotoOutput}
     * @memberof MemberProfilePhotoInfo200ResponseAllOf
     */
    'data'?: MemberProfilePhotoOutput | null;
}
/**
 * 
 * @export
 * @interface MemberProfilePhotoInput
 */
export interface MemberProfilePhotoInput {
    /**
     * Profile photo file base64 contents.
     * @type {string}
     * @memberof MemberProfilePhotoInput
     */
    'file': string;
    /**
     * 
     * @type {UserProfilePhotoOutputOptions}
     * @memberof MemberProfilePhotoInput
     */
    'options': UserProfilePhotoOutputOptions;
}
/**
 * 
 * @export
 * @interface MemberProfilePhotoOutput
 */
export interface MemberProfilePhotoOutput {
    /**
     * Profile photo file base64 contents.
     * @type {string}
     * @memberof MemberProfilePhotoOutput
     */
    'file': string;
    /**
     * 
     * @type {UserProfilePhotoOutputOptions}
     * @memberof MemberProfilePhotoOutput
     */
    'options': UserProfilePhotoOutputOptions;
}
/**
 * 
 * @export
 * @interface MemberProfilePhotoUpload200Response
 */
export interface MemberProfilePhotoUpload200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberProfilePhotoUpload200Response
     */
    'status': MemberProfilePhotoUpload200ResponseStatusEnum;
    /**
     * 
     * @type {MemberProfilePhotoUpload200ResponseAllOfData}
     * @memberof MemberProfilePhotoUpload200Response
     */
    'data': MemberProfilePhotoUpload200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberProfilePhotoUpload200Response
     */
    'warnings'?: Array<string>;
}

export const MemberProfilePhotoUpload200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberProfilePhotoUpload200ResponseStatusEnum = typeof MemberProfilePhotoUpload200ResponseStatusEnum[keyof typeof MemberProfilePhotoUpload200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberProfilePhotoUpload200ResponseAllOf
 */
export interface MemberProfilePhotoUpload200ResponseAllOf {
    /**
     * 
     * @type {MemberProfilePhotoUpload200ResponseAllOfData}
     * @memberof MemberProfilePhotoUpload200ResponseAllOf
     */
    'data'?: MemberProfilePhotoUpload200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MemberProfilePhotoUpload200ResponseAllOfData
 */
export interface MemberProfilePhotoUpload200ResponseAllOfData {
    /**
     * Access token.
     * @type {string}
     * @memberof MemberProfilePhotoUpload200ResponseAllOfData
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface MemberProfilePhotoUploadOutput
 */
export interface MemberProfilePhotoUploadOutput {
    /**
     * Access token.
     * @type {string}
     * @memberof MemberProfilePhotoUploadOutput
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface MemberProfileUpdate200Response
 */
export interface MemberProfileUpdate200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberProfileUpdate200Response
     */
    'status': MemberProfileUpdate200ResponseStatusEnum;
    /**
     * 
     * @type {MemberProfileUpdate200ResponseAllOfData}
     * @memberof MemberProfileUpdate200Response
     */
    'data': MemberProfileUpdate200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberProfileUpdate200Response
     */
    'warnings'?: Array<string>;
}

export const MemberProfileUpdate200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberProfileUpdate200ResponseStatusEnum = typeof MemberProfileUpdate200ResponseStatusEnum[keyof typeof MemberProfileUpdate200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberProfileUpdate200ResponseAllOf
 */
export interface MemberProfileUpdate200ResponseAllOf {
    /**
     * 
     * @type {MemberProfileUpdate200ResponseAllOfData}
     * @memberof MemberProfileUpdate200ResponseAllOf
     */
    'data'?: MemberProfileUpdate200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MemberProfileUpdate200ResponseAllOfData
 */
export interface MemberProfileUpdate200ResponseAllOfData {
    /**
     * User gender.
     * @type {string}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'gender': MemberProfileUpdate200ResponseAllOfDataGenderEnum;
    /**
     * User gender (other). Required if gender is \"other\".
     * @type {string}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'gender_other': string | null;
    /**
     * User honorific.
     * @type {string}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'honorific': MemberProfileUpdate200ResponseAllOfDataHonorificEnum;
    /**
     * Profile photo API path.
     * @type {string}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'profile_photo': string | null;
    /**
     * User military verification status
     * @type {boolean}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'verified': boolean;
    /**
     * User first name.
     * @type {string}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'last_name': string;
    /**
     * User middle name.
     * @type {string}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'middle_name': string | null;
    /**
     * 
     * @type {UserOutputVerification}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'verification': UserOutputVerification;
    /**
     * User phone.
     * @type {string}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'phone': string | null;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'email': string | null;
    /**
     * User date of birth.
     * @type {string}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'dob': string | null;
    /**
     * 
     * @type {UserOutputHouseholdMember}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'household_member': UserOutputHouseholdMember | null;
    /**
     * User timezone.
     * @type {string}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'timezone': string | null;
    /**
     * 
     * @type {FulfillmentRecordOutputAddress}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'address': FulfillmentRecordOutputAddress | null;
    /**
     * 
     * @type {UserOutputMilitary}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'military': UserOutputMilitary;
    /**
     * User MRP program.
     * @type {string}
     * @memberof MemberProfileUpdate200ResponseAllOfData
     */
    'mrp': string | null;
}

export const MemberProfileUpdate200ResponseAllOfDataGenderEnum = {
    Male: 'male',
    Female: 'female',
    Other: 'other'
} as const;

export type MemberProfileUpdate200ResponseAllOfDataGenderEnum = typeof MemberProfileUpdate200ResponseAllOfDataGenderEnum[keyof typeof MemberProfileUpdate200ResponseAllOfDataGenderEnum];
export const MemberProfileUpdate200ResponseAllOfDataHonorificEnum = {
    Dr: 'dr',
    Miss: 'miss',
    Mr: 'mr',
    Mrs: 'mrs',
    Ms: 'ms',
    Mstr: 'mstr',
    Prof: 'prof',
    Rev: 'rev',
    Sir: 'sir',
    Sr: 'sr'
} as const;

export type MemberProfileUpdate200ResponseAllOfDataHonorificEnum = typeof MemberProfileUpdate200ResponseAllOfDataHonorificEnum[keyof typeof MemberProfileUpdate200ResponseAllOfDataHonorificEnum];

/**
 * 
 * @export
 * @interface MemberPromoteInput
 */
export interface MemberPromoteInput {
    /**
     * User ID. It\'s HHM ID in case of action == \"swap\"|\"promote\" and Primary ID otherwise.
     * @type {string}
     * @memberof MemberPromoteInput
     */
    'id': string;
    /**
     * Action name (optional and is \"swap\" by default).
     * @type {string}
     * @memberof MemberPromoteInput
     */
    'action'?: string;
    /**
     * Household ID list which would be connected to new Primary (optional). It will be ignored if action == \"swap\".
     * @type {Array<string>}
     * @memberof MemberPromoteInput
     */
    'household_members'?: Array<string>;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionAddonInput
 */
export interface MemberSubscriptionAddonInput {
    /**
     * Flag indicating whether to cancel auto-renew of subscription/TP.
     * @type {string}
     * @memberof MemberSubscriptionAddonInput
     */
    'cancel'?: MemberSubscriptionAddonInputCancelEnum;
    /**
     * Household members info.
     * @type {Array<EnrollInputHouseholdMembersInner>}
     * @memberof MemberSubscriptionAddonInput
     */
    'household_members'?: Array<EnrollInputHouseholdMembersInner>;
    /**
     * Payment method ID.
     * @type {string}
     * @memberof MemberSubscriptionAddonInput
     */
    'payment_method'?: string;
    /**
     * Subscription extension product ID.
     * @type {string}
     * @memberof MemberSubscriptionAddonInput
     */
    'subscription_extension'?: string;
    /**
     * Travel protection product ID.
     * @type {string}
     * @memberof MemberSubscriptionAddonInput
     */
    'travel_protection'?: string | null;
    /**
     * Contribution amount in cents.
     * @type {number}
     * @memberof MemberSubscriptionAddonInput
     */
    'contribution'?: number;
    /**
     * 
     * @type {EnrollInputAdmin}
     * @memberof MemberSubscriptionAddonInput
     */
    'admin'?: EnrollInputAdmin;
}

export const MemberSubscriptionAddonInputCancelEnum = {
    Plan: 'plan',
    TravelProtection: 'travel_protection'
} as const;

export type MemberSubscriptionAddonInputCancelEnum = typeof MemberSubscriptionAddonInputCancelEnum[keyof typeof MemberSubscriptionAddonInputCancelEnum];

/**
 * 
 * @export
 * @interface MemberSubscriptionAddonPreview200Response
 */
export interface MemberSubscriptionAddonPreview200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberSubscriptionAddonPreview200Response
     */
    'status': MemberSubscriptionAddonPreview200ResponseStatusEnum;
    /**
     * 
     * @type {MemberSubscriptionAddonPreview200ResponseAllOfData}
     * @memberof MemberSubscriptionAddonPreview200Response
     */
    'data': MemberSubscriptionAddonPreview200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberSubscriptionAddonPreview200Response
     */
    'warnings'?: Array<string>;
}

export const MemberSubscriptionAddonPreview200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberSubscriptionAddonPreview200ResponseStatusEnum = typeof MemberSubscriptionAddonPreview200ResponseStatusEnum[keyof typeof MemberSubscriptionAddonPreview200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberSubscriptionAddonPreview200ResponseAllOf
 */
export interface MemberSubscriptionAddonPreview200ResponseAllOf {
    /**
     * 
     * @type {MemberSubscriptionAddonPreview200ResponseAllOfData}
     * @memberof MemberSubscriptionAddonPreview200ResponseAllOf
     */
    'data'?: MemberSubscriptionAddonPreview200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionAddonPreview200ResponseAllOfData
 */
export interface MemberSubscriptionAddonPreview200ResponseAllOfData {
    /**
     * 
     * @type {EnrollPreviewOutputLines}
     * @memberof MemberSubscriptionAddonPreview200ResponseAllOfData
     */
    'lines': EnrollPreviewOutputLines;
    /**
     * 
     * @type {MemberSubscriptionAddonPreviewOutputSubscription}
     * @memberof MemberSubscriptionAddonPreview200ResponseAllOfData
     */
    'subscription': MemberSubscriptionAddonPreviewOutputSubscription;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionAddonPreviewInput
 */
export interface MemberSubscriptionAddonPreviewInput {
    /**
     * Flag indicating whether to cancel auto-renew of subscription/TP.
     * @type {string}
     * @memberof MemberSubscriptionAddonPreviewInput
     */
    'cancel'?: MemberSubscriptionAddonPreviewInputCancelEnum;
    /**
     * Household members info.
     * @type {Array<EnrollInputHouseholdMembersInner>}
     * @memberof MemberSubscriptionAddonPreviewInput
     */
    'household_members'?: Array<EnrollInputHouseholdMembersInner>;
    /**
     * Subscription extension product ID.
     * @type {string}
     * @memberof MemberSubscriptionAddonPreviewInput
     */
    'subscription_extension'?: string;
    /**
     * Travel protection product ID.
     * @type {string}
     * @memberof MemberSubscriptionAddonPreviewInput
     */
    'travel_protection'?: string | null;
    /**
     * Contribution amount in cents.
     * @type {number}
     * @memberof MemberSubscriptionAddonPreviewInput
     */
    'contribution'?: number;
    /**
     * 
     * @type {EnrollInputAdmin}
     * @memberof MemberSubscriptionAddonPreviewInput
     */
    'admin'?: EnrollInputAdmin;
}

export const MemberSubscriptionAddonPreviewInputCancelEnum = {
    Plan: 'plan',
    TravelProtection: 'travel_protection'
} as const;

export type MemberSubscriptionAddonPreviewInputCancelEnum = typeof MemberSubscriptionAddonPreviewInputCancelEnum[keyof typeof MemberSubscriptionAddonPreviewInputCancelEnum];

/**
 * 
 * @export
 * @interface MemberSubscriptionAddonPreviewOutput
 */
export interface MemberSubscriptionAddonPreviewOutput {
    /**
     * 
     * @type {EnrollPreviewOutputLines}
     * @memberof MemberSubscriptionAddonPreviewOutput
     */
    'lines': EnrollPreviewOutputLines;
    /**
     * 
     * @type {MemberSubscriptionAddonPreviewOutputSubscription}
     * @memberof MemberSubscriptionAddonPreviewOutput
     */
    'subscription': MemberSubscriptionAddonPreviewOutputSubscription;
}
/**
 * Subscription info.
 * @export
 * @interface MemberSubscriptionAddonPreviewOutputSubscription
 */
export interface MemberSubscriptionAddonPreviewOutputSubscription {
    /**
     * Subscription ID. Null for household members.
     * @type {string}
     * @memberof MemberSubscriptionAddonPreviewOutputSubscription
     */
    'id': string | null;
    /**
     * The date of subscription expiration in milliseconds.
     * @type {number}
     * @memberof MemberSubscriptionAddonPreviewOutputSubscription
     */
    'expires': number;
    /**
     * The flag indicating whether the subscription autorenew is enabled.
     * @type {boolean}
     * @memberof MemberSubscriptionAddonPreviewOutputSubscription
     */
    'autorenew': boolean;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionAddonPreviewOutputSubscriptionInfo
 */
export interface MemberSubscriptionAddonPreviewOutputSubscriptionInfo {
    /**
     * Subscription ID. Null for household members.
     * @type {string}
     * @memberof MemberSubscriptionAddonPreviewOutputSubscriptionInfo
     */
    'id': string | null;
    /**
     * The date of subscription expiration in milliseconds.
     * @type {number}
     * @memberof MemberSubscriptionAddonPreviewOutputSubscriptionInfo
     */
    'expires': number;
    /**
     * The flag indicating whether the subscription autorenew is enabled.
     * @type {boolean}
     * @memberof MemberSubscriptionAddonPreviewOutputSubscriptionInfo
     */
    'autorenew': boolean;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionDataOutput
 */
export interface MemberSubscriptionDataOutput {
    /**
     * Household member subscriptions.
     * @type {{ [key: string]: UserSubscriptionOutput; }}
     * @memberof MemberSubscriptionDataOutput
     */
    'household_members'?: { [key: string]: UserSubscriptionOutput; };
    /**
     * 
     * @type {MemberSubscriptionDataOutputExtra}
     * @memberof MemberSubscriptionDataOutput
     */
    'extra'?: MemberSubscriptionDataOutputExtra;
    /**
     * Subscription ID. Null for household members.
     * @type {string}
     * @memberof MemberSubscriptionDataOutput
     */
    'id': string | null;
    /**
     * The flag indicating whether the subscription autorenew is enabled.
     * @type {boolean}
     * @memberof MemberSubscriptionDataOutput
     */
    'autorenew': boolean;
    /**
     * The date subscription autorenew was disabled on in milliseconds.
     * @type {number}
     * @memberof MemberSubscriptionDataOutput
     */
    'autorenew_disabled_on': number | null;
    /**
     * The subscription plan ID.
     * @type {string}
     * @memberof MemberSubscriptionDataOutput
     */
    'plan': string;
    /**
     * The promotion ID.
     * @type {string}
     * @memberof MemberSubscriptionDataOutput
     */
    'promotion': string | null;
    /**
     * The subscription extension product IDs.
     * @type {Array<string>}
     * @memberof MemberSubscriptionDataOutput
     */
    'extensions': Array<string>;
    /**
     * 
     * @type {UserSubscriptionOutputTravelProtection}
     * @memberof MemberSubscriptionDataOutput
     */
    'travel_protection': UserSubscriptionOutputTravelProtection | null;
    /**
     * The date of subscription expiration in milliseconds.
     * @type {number}
     * @memberof MemberSubscriptionDataOutput
     */
    'expires': number;
    /**
     * The date subscription is paused until in milliseconds.
     * @type {number}
     * @memberof MemberSubscriptionDataOutput
     */
    'paused_until': number | null;
    /**
     * The subscription saves number.
     * @type {number}
     * @memberof MemberSubscriptionDataOutput
     */
    'sub_saves'?: number | null;
    /**
     * Whether the physical card will be provided to the user.
     * @type {boolean}
     * @memberof MemberSubscriptionDataOutput
     */
    'vetrewards_card_physical': boolean;
}
/**
 * Extra info.
 * @export
 * @interface MemberSubscriptionDataOutputExtra
 */
export interface MemberSubscriptionDataOutputExtra {
    /**
     * Invoice ID.
     * @type {string}
     * @memberof MemberSubscriptionDataOutputExtra
     */
    'invoice_id': string | null;
    /**
     * 
     * @type {EnrollOutputSubscriptionOrderCompletedMessage}
     * @memberof MemberSubscriptionDataOutputExtra
     */
    'order_completed_message'?: EnrollOutputSubscriptionOrderCompletedMessage;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionFree200Response
 */
export interface MemberSubscriptionFree200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberSubscriptionFree200Response
     */
    'status': MemberSubscriptionFree200ResponseStatusEnum;
    /**
     * 
     * @type {MemberSubscriptionFree200ResponseAllOfData}
     * @memberof MemberSubscriptionFree200Response
     */
    'data': MemberSubscriptionFree200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberSubscriptionFree200Response
     */
    'warnings'?: Array<string>;
}

export const MemberSubscriptionFree200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberSubscriptionFree200ResponseStatusEnum = typeof MemberSubscriptionFree200ResponseStatusEnum[keyof typeof MemberSubscriptionFree200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberSubscriptionFree200ResponseAllOf
 */
export interface MemberSubscriptionFree200ResponseAllOf {
    /**
     * 
     * @type {MemberSubscriptionFree200ResponseAllOfData}
     * @memberof MemberSubscriptionFree200ResponseAllOf
     */
    'data'?: MemberSubscriptionFree200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionFree200ResponseAllOfData
 */
export interface MemberSubscriptionFree200ResponseAllOfData {
    /**
     * Household member subscriptions.
     * @type {{ [key: string]: UserSubscriptionOutput; }}
     * @memberof MemberSubscriptionFree200ResponseAllOfData
     */
    'household_members'?: { [key: string]: UserSubscriptionOutput; };
    /**
     * 
     * @type {MemberSubscriptionDataOutputExtra}
     * @memberof MemberSubscriptionFree200ResponseAllOfData
     */
    'extra'?: MemberSubscriptionDataOutputExtra;
    /**
     * Subscription ID. Null for household members.
     * @type {string}
     * @memberof MemberSubscriptionFree200ResponseAllOfData
     */
    'id': string | null;
    /**
     * The flag indicating whether the subscription autorenew is enabled.
     * @type {boolean}
     * @memberof MemberSubscriptionFree200ResponseAllOfData
     */
    'autorenew': boolean;
    /**
     * The date subscription autorenew was disabled on in milliseconds.
     * @type {number}
     * @memberof MemberSubscriptionFree200ResponseAllOfData
     */
    'autorenew_disabled_on': number | null;
    /**
     * The subscription plan ID.
     * @type {string}
     * @memberof MemberSubscriptionFree200ResponseAllOfData
     */
    'plan': string;
    /**
     * The promotion ID.
     * @type {string}
     * @memberof MemberSubscriptionFree200ResponseAllOfData
     */
    'promotion': string | null;
    /**
     * The subscription extension product IDs.
     * @type {Array<string>}
     * @memberof MemberSubscriptionFree200ResponseAllOfData
     */
    'extensions': Array<string>;
    /**
     * 
     * @type {UserSubscriptionOutputTravelProtection}
     * @memberof MemberSubscriptionFree200ResponseAllOfData
     */
    'travel_protection': UserSubscriptionOutputTravelProtection | null;
    /**
     * The date of subscription expiration in milliseconds.
     * @type {number}
     * @memberof MemberSubscriptionFree200ResponseAllOfData
     */
    'expires': number;
    /**
     * The date subscription is paused until in milliseconds.
     * @type {number}
     * @memberof MemberSubscriptionFree200ResponseAllOfData
     */
    'paused_until': number | null;
    /**
     * The subscription saves number.
     * @type {number}
     * @memberof MemberSubscriptionFree200ResponseAllOfData
     */
    'sub_saves'?: number | null;
    /**
     * Whether the physical card will be provided to the user.
     * @type {boolean}
     * @memberof MemberSubscriptionFree200ResponseAllOfData
     */
    'vetrewards_card_physical': boolean;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionFreeInput
 */
export interface MemberSubscriptionFreeInput {
    /**
     * Free months number.
     * @type {number}
     * @memberof MemberSubscriptionFreeInput
     */
    'months': number;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionHouseholdMemberExistingInput
 */
export interface MemberSubscriptionHouseholdMemberExistingInput {
    /**
     * Flag indicating whether to cancel auto-renew of subscription/TP.
     * @type {string}
     * @memberof MemberSubscriptionHouseholdMemberExistingInput
     */
    'cancel'?: MemberSubscriptionHouseholdMemberExistingInputCancelEnum;
    /**
     * User ID.
     * @type {string}
     * @memberof MemberSubscriptionHouseholdMemberExistingInput
     */
    'id': string;
    /**
     * Flag indicating whether to purchase a travel protection product.
     * @type {boolean}
     * @memberof MemberSubscriptionHouseholdMemberExistingInput
     */
    'travel_protection'?: boolean;
}

export const MemberSubscriptionHouseholdMemberExistingInputCancelEnum = {
    Plan: 'plan',
    TravelProtection: 'travel_protection'
} as const;

export type MemberSubscriptionHouseholdMemberExistingInputCancelEnum = typeof MemberSubscriptionHouseholdMemberExistingInputCancelEnum[keyof typeof MemberSubscriptionHouseholdMemberExistingInputCancelEnum];

/**
 * 
 * @export
 * @interface MemberSubscriptionInfo200Response
 */
export interface MemberSubscriptionInfo200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberSubscriptionInfo200Response
     */
    'status': MemberSubscriptionInfo200ResponseStatusEnum;
    /**
     * 
     * @type {MemberSubscriptionInfo200ResponseAllOfData}
     * @memberof MemberSubscriptionInfo200Response
     */
    'data': MemberSubscriptionInfo200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberSubscriptionInfo200Response
     */
    'warnings'?: Array<string>;
}

export const MemberSubscriptionInfo200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberSubscriptionInfo200ResponseStatusEnum = typeof MemberSubscriptionInfo200ResponseStatusEnum[keyof typeof MemberSubscriptionInfo200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberSubscriptionInfo200ResponseAllOf
 */
export interface MemberSubscriptionInfo200ResponseAllOf {
    /**
     * 
     * @type {MemberSubscriptionInfo200ResponseAllOfData}
     * @memberof MemberSubscriptionInfo200ResponseAllOf
     */
    'data'?: MemberSubscriptionInfo200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionInfo200ResponseAllOfData
 */
export interface MemberSubscriptionInfo200ResponseAllOfData {
    /**
     * 
     * @type {MemberSubscriptionInfoOutputPlan}
     * @memberof MemberSubscriptionInfo200ResponseAllOfData
     */
    'plan': MemberSubscriptionInfoOutputPlan;
    /**
     * 
     * @type {MemberSubscriptionInfoOutputPromotion}
     * @memberof MemberSubscriptionInfo200ResponseAllOfData
     */
    'promotion': MemberSubscriptionInfoOutputPromotion;
    /**
     * The OTP info.
     * @type {boolean}
     * @memberof MemberSubscriptionInfo200ResponseAllOfData
     */
    'otp': boolean;
    /**
     * 
     * @type {MemberSubscriptionInfoOutputHouseholdMembers}
     * @memberof MemberSubscriptionInfo200ResponseAllOfData
     */
    'household_members': MemberSubscriptionInfoOutputHouseholdMembers;
    /**
     * 
     * @type {EnrollPreviewOutputLines}
     * @memberof MemberSubscriptionInfo200ResponseAllOfData
     */
    'lines': EnrollPreviewOutputLines;
    /**
     * Indicates whether Travel Protection was purchased.
     * @type {boolean}
     * @memberof MemberSubscriptionInfo200ResponseAllOfData
     */
    'travel_protection_purchased': boolean;
    /**
     * 
     * @type {MemberSubscriptionInfoOutputRenewalOffer}
     * @memberof MemberSubscriptionInfo200ResponseAllOfData
     */
    'renewal_offer': MemberSubscriptionInfoOutputRenewalOffer;
    /**
     * Subscription ID. Null for household members.
     * @type {string}
     * @memberof MemberSubscriptionInfo200ResponseAllOfData
     */
    'id': string | null;
    /**
     * The flag indicating whether the subscription autorenew is enabled.
     * @type {boolean}
     * @memberof MemberSubscriptionInfo200ResponseAllOfData
     */
    'autorenew': boolean;
    /**
     * The date subscription autorenew was disabled on in milliseconds.
     * @type {number}
     * @memberof MemberSubscriptionInfo200ResponseAllOfData
     */
    'autorenew_disabled_on': number | null;
    /**
     * The subscription extension product IDs.
     * @type {Array<string>}
     * @memberof MemberSubscriptionInfo200ResponseAllOfData
     */
    'extensions': Array<string>;
    /**
     * 
     * @type {UserSubscriptionOutputTravelProtection}
     * @memberof MemberSubscriptionInfo200ResponseAllOfData
     */
    'travel_protection': UserSubscriptionOutputTravelProtection | null;
    /**
     * The date of subscription expiration in milliseconds.
     * @type {number}
     * @memberof MemberSubscriptionInfo200ResponseAllOfData
     */
    'expires': number;
    /**
     * The date subscription is paused until in milliseconds.
     * @type {number}
     * @memberof MemberSubscriptionInfo200ResponseAllOfData
     */
    'paused_until': number | null;
    /**
     * The subscription saves number.
     * @type {number}
     * @memberof MemberSubscriptionInfo200ResponseAllOfData
     */
    'sub_saves'?: number | null;
    /**
     * Whether the physical card will be provided to the user.
     * @type {boolean}
     * @memberof MemberSubscriptionInfo200ResponseAllOfData
     */
    'vetrewards_card_physical': boolean;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionInfoOutput
 */
export interface MemberSubscriptionInfoOutput {
    /**
     * 
     * @type {MemberSubscriptionInfoOutputPlan}
     * @memberof MemberSubscriptionInfoOutput
     */
    'plan': MemberSubscriptionInfoOutputPlan;
    /**
     * 
     * @type {MemberSubscriptionInfoOutputPromotion}
     * @memberof MemberSubscriptionInfoOutput
     */
    'promotion': MemberSubscriptionInfoOutputPromotion;
    /**
     * The OTP info.
     * @type {boolean}
     * @memberof MemberSubscriptionInfoOutput
     */
    'otp': boolean;
    /**
     * 
     * @type {MemberSubscriptionInfoOutputHouseholdMembers}
     * @memberof MemberSubscriptionInfoOutput
     */
    'household_members': MemberSubscriptionInfoOutputHouseholdMembers;
    /**
     * 
     * @type {EnrollPreviewOutputLines}
     * @memberof MemberSubscriptionInfoOutput
     */
    'lines': EnrollPreviewOutputLines;
    /**
     * Indicates whether Travel Protection was purchased.
     * @type {boolean}
     * @memberof MemberSubscriptionInfoOutput
     */
    'travel_protection_purchased': boolean;
    /**
     * 
     * @type {MemberSubscriptionInfoOutputRenewalOffer}
     * @memberof MemberSubscriptionInfoOutput
     */
    'renewal_offer': MemberSubscriptionInfoOutputRenewalOffer;
    /**
     * Subscription ID. Null for household members.
     * @type {string}
     * @memberof MemberSubscriptionInfoOutput
     */
    'id': string | null;
    /**
     * The flag indicating whether the subscription autorenew is enabled.
     * @type {boolean}
     * @memberof MemberSubscriptionInfoOutput
     */
    'autorenew': boolean;
    /**
     * The date subscription autorenew was disabled on in milliseconds.
     * @type {number}
     * @memberof MemberSubscriptionInfoOutput
     */
    'autorenew_disabled_on': number | null;
    /**
     * The subscription extension product IDs.
     * @type {Array<string>}
     * @memberof MemberSubscriptionInfoOutput
     */
    'extensions': Array<string>;
    /**
     * 
     * @type {UserSubscriptionOutputTravelProtection}
     * @memberof MemberSubscriptionInfoOutput
     */
    'travel_protection': UserSubscriptionOutputTravelProtection | null;
    /**
     * The date of subscription expiration in milliseconds.
     * @type {number}
     * @memberof MemberSubscriptionInfoOutput
     */
    'expires': number;
    /**
     * The date subscription is paused until in milliseconds.
     * @type {number}
     * @memberof MemberSubscriptionInfoOutput
     */
    'paused_until': number | null;
    /**
     * The subscription saves number.
     * @type {number}
     * @memberof MemberSubscriptionInfoOutput
     */
    'sub_saves'?: number | null;
    /**
     * Whether the physical card will be provided to the user.
     * @type {boolean}
     * @memberof MemberSubscriptionInfoOutput
     */
    'vetrewards_card_physical': boolean;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionInfoOutputHouseholdMember
 */
export interface MemberSubscriptionInfoOutputHouseholdMember {
    /**
     * Household member relationship to a primary user.
     * @type {string}
     * @memberof MemberSubscriptionInfoOutputHouseholdMember
     */
    'relationship': MemberSubscriptionInfoOutputHouseholdMemberRelationshipEnum;
    /**
     * Whether the household member is above 13 y.o.
     * @type {boolean}
     * @memberof MemberSubscriptionInfoOutputHouseholdMember
     */
    'above_13': boolean;
    /**
     * Whether email is verified.
     * @type {boolean}
     * @memberof MemberSubscriptionInfoOutputHouseholdMember
     */
    'email_verified': boolean;
    /**
     * The date invitation email sent.
     * @type {number}
     * @memberof MemberSubscriptionInfoOutputHouseholdMember
     */
    'invite_sent': number;
    /**
     * Profile photo API path.
     * @type {string}
     * @memberof MemberSubscriptionInfoOutputHouseholdMember
     */
    'profile_photo': string | null;
    /**
     * 
     * @type {MemberSubscriptionInfoOutputHouseholdMemberSubscription}
     * @memberof MemberSubscriptionInfoOutputHouseholdMember
     */
    'subscription': MemberSubscriptionInfoOutputHouseholdMemberSubscription | null;
    /**
     * User ID.
     * @type {string}
     * @memberof MemberSubscriptionInfoOutputHouseholdMember
     */
    'id': string;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof MemberSubscriptionInfoOutputHouseholdMember
     */
    'email': string | null;
    /**
     * User first name.
     * @type {string}
     * @memberof MemberSubscriptionInfoOutputHouseholdMember
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof MemberSubscriptionInfoOutputHouseholdMember
     */
    'last_name': string;
    /**
     * User member ID.
     * @type {string}
     * @memberof MemberSubscriptionInfoOutputHouseholdMember
     */
    'member_id': string;
}

export const MemberSubscriptionInfoOutputHouseholdMemberRelationshipEnum = {
    Spouse: 'spouse',
    Father: 'father',
    Mother: 'mother',
    Child: 'child',
    Brother: 'brother',
    Sister: 'sister'
} as const;

export type MemberSubscriptionInfoOutputHouseholdMemberRelationshipEnum = typeof MemberSubscriptionInfoOutputHouseholdMemberRelationshipEnum[keyof typeof MemberSubscriptionInfoOutputHouseholdMemberRelationshipEnum];

/**
 * Household member subscription data.
 * @export
 * @interface MemberSubscriptionInfoOutputHouseholdMemberSubscription
 */
export interface MemberSubscriptionInfoOutputHouseholdMemberSubscription {
    /**
     * The flag indicating whether the subscription autorenew is enabled.
     * @type {boolean}
     * @memberof MemberSubscriptionInfoOutputHouseholdMemberSubscription
     */
    'autorenew': boolean;
    /**
     * The household member expiration date.
     * @type {number}
     * @memberof MemberSubscriptionInfoOutputHouseholdMemberSubscription
     */
    'expires': number;
    /**
     * 
     * @type {MemberSubscriptionInfoOutputHouseholdMemberSubscriptionTravelProtection}
     * @memberof MemberSubscriptionInfoOutputHouseholdMemberSubscription
     */
    'travel_protection': MemberSubscriptionInfoOutputHouseholdMemberSubscriptionTravelProtection | null;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionInfoOutputHouseholdMemberSubscriptionTp
 */
export interface MemberSubscriptionInfoOutputHouseholdMemberSubscriptionTp {
    /**
     * Travel protection autorenew.
     * @type {boolean}
     * @memberof MemberSubscriptionInfoOutputHouseholdMemberSubscriptionTp
     */
    'autorenew': boolean;
    /**
     * The household member travel protection expiration date.
     * @type {number}
     * @memberof MemberSubscriptionInfoOutputHouseholdMemberSubscriptionTp
     */
    'expires': number | null;
    /**
     * Travel protection travel_code.
     * @type {string}
     * @memberof MemberSubscriptionInfoOutputHouseholdMemberSubscriptionTp
     */
    'travel_code': string | null;
}
/**
 * The subscription travel protection product info.
 * @export
 * @interface MemberSubscriptionInfoOutputHouseholdMemberSubscriptionTravelProtection
 */
export interface MemberSubscriptionInfoOutputHouseholdMemberSubscriptionTravelProtection {
    /**
     * Travel protection autorenew.
     * @type {boolean}
     * @memberof MemberSubscriptionInfoOutputHouseholdMemberSubscriptionTravelProtection
     */
    'autorenew': boolean;
    /**
     * The household member travel protection expiration date.
     * @type {number}
     * @memberof MemberSubscriptionInfoOutputHouseholdMemberSubscriptionTravelProtection
     */
    'expires': number | null;
    /**
     * Travel protection travel_code.
     * @type {string}
     * @memberof MemberSubscriptionInfoOutputHouseholdMemberSubscriptionTravelProtection
     */
    'travel_code': string | null;
}
/**
 * Household members subscription info.
 * @export
 * @interface MemberSubscriptionInfoOutputHouseholdMembers
 */
export interface MemberSubscriptionInfoOutputHouseholdMembers {
    /**
     * Active household members subscription info.
     * @type {Array<MemberSubscriptionInfoOutputHouseholdMember>}
     * @memberof MemberSubscriptionInfoOutputHouseholdMembers
     */
    'active': Array<MemberSubscriptionInfoOutputHouseholdMember>;
    /**
     * Inactive household members subscription info.
     * @type {Array<MemberSubscriptionInfoOutputHouseholdMember>}
     * @memberof MemberSubscriptionInfoOutputHouseholdMembers
     */
    'inactive': Array<MemberSubscriptionInfoOutputHouseholdMember>;
}
/**
 * The plan info.
 * @export
 * @interface MemberSubscriptionInfoOutputPlan
 */
export interface MemberSubscriptionInfoOutputPlan {
    /**
     * Plan ID.
     * @type {string}
     * @memberof MemberSubscriptionInfoOutputPlan
     */
    'id': string;
    /**
     * Plan title.
     * @type {string}
     * @memberof MemberSubscriptionInfoOutputPlan
     */
    'title': string;
    /**
     * 
     * @type {PurchaseBriefPlanOutputPrice}
     * @memberof MemberSubscriptionInfoOutputPlan
     */
    'price': PurchaseBriefPlanOutputPrice;
    /**
     * Plan note.
     * @type {string}
     * @memberof MemberSubscriptionInfoOutputPlan
     */
    'note': string;
    /**
     * 
     * @type {PurchaseBriefPlanOutputProcessingFee}
     * @memberof MemberSubscriptionInfoOutputPlan
     */
    'processing_fee': PurchaseBriefPlanOutputProcessingFee | null;
    /**
     * 
     * @type {PurchaseBriefPlanOutputPeriod}
     * @memberof MemberSubscriptionInfoOutputPlan
     */
    'period': PurchaseBriefPlanOutputPeriod;
    /**
     * Available subscription extension products of the plan.
     * @type {Array<PurchaseBriefProductOutput>}
     * @memberof MemberSubscriptionInfoOutputPlan
     */
    'subscription_extension': Array<PurchaseBriefProductOutput>;
    /**
     * Available travel protection products of the plan.
     * @type {Array<PurchaseBriefProductOutput>}
     * @memberof MemberSubscriptionInfoOutputPlan
     */
    'travel_protection': Array<PurchaseBriefProductOutput>;
    /**
     * Whether the plan is a trial plan.
     * @type {boolean}
     * @memberof MemberSubscriptionInfoOutputPlan
     */
    'trial': boolean;
    /**
     * Plan type.
     * @type {string}
     * @memberof MemberSubscriptionInfoOutputPlan
     */
    'type': MemberSubscriptionInfoOutputPlanTypeEnum;
    /**
     * Whether the plan is inactive. Set to True if this plan has a \"trial\" flag set and current user has a \"trial_used\" flag set.
     * @type {boolean}
     * @memberof MemberSubscriptionInfoOutputPlan
     */
    'inactive': boolean;
}

export const MemberSubscriptionInfoOutputPlanTypeEnum = {
    Trial: 'trial',
    Year: 'year',
    Month: 'month'
} as const;

export type MemberSubscriptionInfoOutputPlanTypeEnum = typeof MemberSubscriptionInfoOutputPlanTypeEnum[keyof typeof MemberSubscriptionInfoOutputPlanTypeEnum];

/**
 * 
 * @export
 * @interface MemberSubscriptionInfoOutputPromotion
 */
export interface MemberSubscriptionInfoOutputPromotion {
    /**
     * 
     * @type {string}
     * @memberof MemberSubscriptionInfoOutputPromotion
     */
    'set'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberSubscriptionInfoOutputPromotion
     */
    'enroll'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberSubscriptionInfoOutputPromotion
     */
    'renew'?: string | null;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionInfoOutputRenewalOffer
 */
export interface MemberSubscriptionInfoOutputRenewalOffer {
    /**
     * 
     * @type {PurchaseBriefLineItemsInfoOutput}
     * @memberof MemberSubscriptionInfoOutputRenewalOffer
     */
    'lines'?: PurchaseBriefLineItemsInfoOutput;
    /**
     * 
     * @type {PurchaseBriefPromotionOutput}
     * @memberof MemberSubscriptionInfoOutputRenewalOffer
     */
    'promotion'?: PurchaseBriefPromotionOutput;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionPromotionApplyInput
 */
export interface MemberSubscriptionPromotionApplyInput {
    /**
     * Promotion ID.
     * @type {string}
     * @memberof MemberSubscriptionPromotionApplyInput
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionPromotionResetInput
 */
export interface MemberSubscriptionPromotionResetInput {
    /**
     * Promotion type.
     * @type {string}
     * @memberof MemberSubscriptionPromotionResetInput
     */
    'type': MemberSubscriptionPromotionResetInputTypeEnum;
}

export const MemberSubscriptionPromotionResetInputTypeEnum = {
    Enroll: 'enroll',
    Renew: 'renew'
} as const;

export type MemberSubscriptionPromotionResetInputTypeEnum = typeof MemberSubscriptionPromotionResetInputTypeEnum[keyof typeof MemberSubscriptionPromotionResetInputTypeEnum];

/**
 * 
 * @export
 * @interface MemberSubscriptionSaveInput
 */
export interface MemberSubscriptionSaveInput {
    /**
     * Action name.
     * @type {string}
     * @memberof MemberSubscriptionSaveInput
     */
    'action': string;
    /**
     * User ID.
     * @type {string}
     * @memberof MemberSubscriptionSaveInput
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionSetExpiresInput
 */
export interface MemberSubscriptionSetExpiresInput {
    /**
     * Expiration date.
     * @type {number}
     * @memberof MemberSubscriptionSetExpiresInput
     */
    'expires': number;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionUpdate200Response
 */
export interface MemberSubscriptionUpdate200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberSubscriptionUpdate200Response
     */
    'status': MemberSubscriptionUpdate200ResponseStatusEnum;
    /**
     * 
     * @type {MemberSubscriptionDataOutput}
     * @memberof MemberSubscriptionUpdate200Response
     */
    'data': MemberSubscriptionDataOutput | null;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberSubscriptionUpdate200Response
     */
    'warnings'?: Array<string>;
}

export const MemberSubscriptionUpdate200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberSubscriptionUpdate200ResponseStatusEnum = typeof MemberSubscriptionUpdate200ResponseStatusEnum[keyof typeof MemberSubscriptionUpdate200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberSubscriptionUpdate200ResponseAllOf
 */
export interface MemberSubscriptionUpdate200ResponseAllOf {
    /**
     * 
     * @type {MemberSubscriptionDataOutput}
     * @memberof MemberSubscriptionUpdate200ResponseAllOf
     */
    'data'?: MemberSubscriptionDataOutput | null;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionUpdateInput
 */
export interface MemberSubscriptionUpdateInput {
    /**
     * Household member subscriptions.
     * @type {{ [key: string]: MemberSubscriptionUpdateItemInput; }}
     * @memberof MemberSubscriptionUpdateInput
     */
    'household_members'?: { [key: string]: MemberSubscriptionUpdateItemInput; };
    /**
     * Subscription autorenew state.
     * @type {boolean}
     * @memberof MemberSubscriptionUpdateInput
     */
    'autorenew'?: boolean;
    /**
     * 
     * @type {MemberSubscriptionUpdateItemInputTravelProtection}
     * @memberof MemberSubscriptionUpdateInput
     */
    'travel_protection'?: MemberSubscriptionUpdateItemInputTravelProtection;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionUpdateItemInput
 */
export interface MemberSubscriptionUpdateItemInput {
    /**
     * Subscription autorenew state.
     * @type {boolean}
     * @memberof MemberSubscriptionUpdateItemInput
     */
    'autorenew'?: boolean;
    /**
     * 
     * @type {MemberSubscriptionUpdateItemInputTravelProtection}
     * @memberof MemberSubscriptionUpdateItemInput
     */
    'travel_protection'?: MemberSubscriptionUpdateItemInputTravelProtection;
}
/**
 * The subscription travel protection info.
 * @export
 * @interface MemberSubscriptionUpdateItemInputTravelProtection
 */
export interface MemberSubscriptionUpdateItemInputTravelProtection {
    /**
     * Travel protection autorenew state.
     * @type {boolean}
     * @memberof MemberSubscriptionUpdateItemInputTravelProtection
     */
    'autorenew': boolean;
}
/**
 * 
 * @export
 * @interface MemberSubscriptionUpdateItemInputTravelProtectionInfo
 */
export interface MemberSubscriptionUpdateItemInputTravelProtectionInfo {
    /**
     * Travel protection autorenew state.
     * @type {boolean}
     * @memberof MemberSubscriptionUpdateItemInputTravelProtectionInfo
     */
    'autorenew': boolean;
}
/**
 * 
 * @export
 * @interface MemberTokenDto
 */
export interface MemberTokenDto {
    /**
     * Secret base32 code.
     * @type {string}
     * @memberof MemberTokenDto
     */
    'secret': string;
    /**
     * Token generated for secret.
     * @type {string}
     * @memberof MemberTokenDto
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface MemberVerificationStatus200Response
 */
export interface MemberVerificationStatus200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MemberVerificationStatus200Response
     */
    'status': MemberVerificationStatus200ResponseStatusEnum;
    /**
     * 
     * @type {MemberVerificationStatus200ResponseAllOfData}
     * @memberof MemberVerificationStatus200Response
     */
    'data': MemberVerificationStatus200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MemberVerificationStatus200Response
     */
    'warnings'?: Array<string>;
}

export const MemberVerificationStatus200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MemberVerificationStatus200ResponseStatusEnum = typeof MemberVerificationStatus200ResponseStatusEnum[keyof typeof MemberVerificationStatus200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MemberVerificationStatus200ResponseAllOf
 */
export interface MemberVerificationStatus200ResponseAllOf {
    /**
     * 
     * @type {MemberVerificationStatus200ResponseAllOfData}
     * @memberof MemberVerificationStatus200ResponseAllOf
     */
    'data'?: MemberVerificationStatus200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MemberVerificationStatus200ResponseAllOfData
 */
export interface MemberVerificationStatus200ResponseAllOfData {
    /**
     * User affinity.
     * @type {string}
     * @memberof MemberVerificationStatus200ResponseAllOfData
     */
    'affinity': MemberVerificationStatus200ResponseAllOfDataAffinityEnum;
    /**
     * 
     * @type {MemberMilitaryCredentialsOutputMilitaryInfoBranch}
     * @memberof MemberVerificationStatus200ResponseAllOfData
     */
    'branch': MemberMilitaryCredentialsOutputMilitaryInfoBranch | null;
    /**
     * Indicates whether user is verified or not.
     * @type {boolean}
     * @memberof MemberVerificationStatus200ResponseAllOfData
     */
    'verified': boolean;
    /**
     * 
     * @type {MemberVerificationStatusOutputIdentification}
     * @memberof MemberVerificationStatus200ResponseAllOfData
     */
    'identification': MemberVerificationStatusOutputIdentification | null;
    /**
     * 
     * @type {MemberVerificationStatusOutputActiveDuty}
     * @memberof MemberVerificationStatus200ResponseAllOfData
     */
    'active_duty': MemberVerificationStatusOutputActiveDuty | null;
    /**
     * User first name.
     * @type {string}
     * @memberof MemberVerificationStatus200ResponseAllOfData
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof MemberVerificationStatus200ResponseAllOfData
     */
    'last_name': string;
}

export const MemberVerificationStatus200ResponseAllOfDataAffinityEnum = {
    Veteran: 'veteran',
    Relative: 'relative',
    Not: 'not',
    Commissioned: 'commissioned',
    ActiveDuty: 'active_duty',
    Academy: 'academy'
} as const;

export type MemberVerificationStatus200ResponseAllOfDataAffinityEnum = typeof MemberVerificationStatus200ResponseAllOfDataAffinityEnum[keyof typeof MemberVerificationStatus200ResponseAllOfDataAffinityEnum];

/**
 * 
 * @export
 * @interface MemberVerificationStatusOutput
 */
export interface MemberVerificationStatusOutput {
    /**
     * User affinity.
     * @type {string}
     * @memberof MemberVerificationStatusOutput
     */
    'affinity': MemberVerificationStatusOutputAffinityEnum;
    /**
     * 
     * @type {MemberMilitaryCredentialsOutputMilitaryInfoBranch}
     * @memberof MemberVerificationStatusOutput
     */
    'branch': MemberMilitaryCredentialsOutputMilitaryInfoBranch | null;
    /**
     * Indicates whether user is verified or not.
     * @type {boolean}
     * @memberof MemberVerificationStatusOutput
     */
    'verified': boolean;
    /**
     * 
     * @type {MemberVerificationStatusOutputIdentification}
     * @memberof MemberVerificationStatusOutput
     */
    'identification': MemberVerificationStatusOutputIdentification | null;
    /**
     * 
     * @type {MemberVerificationStatusOutputActiveDuty}
     * @memberof MemberVerificationStatusOutput
     */
    'active_duty': MemberVerificationStatusOutputActiveDuty | null;
    /**
     * User first name.
     * @type {string}
     * @memberof MemberVerificationStatusOutput
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof MemberVerificationStatusOutput
     */
    'last_name': string;
}

export const MemberVerificationStatusOutputAffinityEnum = {
    Veteran: 'veteran',
    Relative: 'relative',
    Not: 'not',
    Commissioned: 'commissioned',
    ActiveDuty: 'active_duty',
    Academy: 'academy'
} as const;

export type MemberVerificationStatusOutputAffinityEnum = typeof MemberVerificationStatusOutputAffinityEnum[keyof typeof MemberVerificationStatusOutputAffinityEnum];

/**
 * Active duty info or null.
 * @export
 * @interface MemberVerificationStatusOutputActiveDuty
 */
export interface MemberVerificationStatusOutputActiveDuty {
    /**
     * Verification timestamp.
     * @type {number}
     * @memberof MemberVerificationStatusOutputActiveDuty
     */
    'verified_on': number;
    /**
     * Military email address.
     * @type {string}
     * @memberof MemberVerificationStatusOutputActiveDuty
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface MemberVerificationStatusOutputActiveDutyInfo
 */
export interface MemberVerificationStatusOutputActiveDutyInfo {
    /**
     * Verification timestamp.
     * @type {number}
     * @memberof MemberVerificationStatusOutputActiveDutyInfo
     */
    'verified_on': number;
    /**
     * Military email address.
     * @type {string}
     * @memberof MemberVerificationStatusOutputActiveDutyInfo
     */
    'email': string;
}
/**
 * Identification info.
 * @export
 * @interface MemberVerificationStatusOutputIdentification
 */
export interface MemberVerificationStatusOutputIdentification {
    /**
     * 
     * @type {IdentificationOutputIdentity}
     * @memberof MemberVerificationStatusOutputIdentification
     */
    'identity': IdentificationOutputIdentity;
    /**
     * 
     * @type {IdentificationOutputMilitary}
     * @memberof MemberVerificationStatusOutputIdentification
     */
    'military': IdentificationOutputMilitary;
}
/**
 * 
 * @export
 * @interface MembersList200Response
 */
export interface MembersList200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MembersList200Response
     */
    'status': MembersList200ResponseStatusEnum;
    /**
     * 
     * @type {MembersList200ResponseAllOfData}
     * @memberof MembersList200Response
     */
    'data': MembersList200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MembersList200Response
     */
    'warnings'?: Array<string>;
}

export const MembersList200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MembersList200ResponseStatusEnum = typeof MembersList200ResponseStatusEnum[keyof typeof MembersList200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MembersList200ResponseAllOf
 */
export interface MembersList200ResponseAllOf {
    /**
     * 
     * @type {MembersList200ResponseAllOfData}
     * @memberof MembersList200ResponseAllOf
     */
    'data'?: MembersList200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MembersList200ResponseAllOfData
 */
export interface MembersList200ResponseAllOfData {
    /**
     * 
     * @type {Array<MemberInfoOutput>}
     * @memberof MembersList200ResponseAllOfData
     */
    'items': Array<MemberInfoOutput>;
    /**
     * Indicates whether there are additional items beyond the end of this list.
     * @type {boolean}
     * @memberof MembersList200ResponseAllOfData
     */
    'has_more': boolean;
    /**
     * The total number of items in the list (optional).
     * @type {number}
     * @memberof MembersList200ResponseAllOfData
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface MembersList200ResponseAllOfDataAllOf
 */
export interface MembersList200ResponseAllOfDataAllOf {
    /**
     * 
     * @type {Array<MemberInfoOutput>}
     * @memberof MembersList200ResponseAllOfDataAllOf
     */
    'items'?: Array<MemberInfoOutput>;
}
/**
 * 
 * @export
 * @interface MilitaryBranchOutput
 */
export interface MilitaryBranchOutput {
    /**
     * Military branch name.
     * @type {string}
     * @memberof MilitaryBranchOutput
     */
    'name': string;
    /**
     * Military branch logo URL.
     * @type {string}
     * @memberof MilitaryBranchOutput
     */
    'logo': string;
}
/**
 * 
 * @export
 * @interface MilitaryBranches200Response
 */
export interface MilitaryBranches200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MilitaryBranches200Response
     */
    'status': MilitaryBranches200ResponseStatusEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MilitaryBranches200Response
     */
    'data': { [key: string]: string; };
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MilitaryBranches200Response
     */
    'warnings'?: Array<string>;
}

export const MilitaryBranches200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MilitaryBranches200ResponseStatusEnum = typeof MilitaryBranches200ResponseStatusEnum[keyof typeof MilitaryBranches200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MilitaryBranches200ResponseAllOf
 */
export interface MilitaryBranches200ResponseAllOf {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MilitaryBranches200ResponseAllOf
     */
    'data'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface MilitaryRanks200Response
 */
export interface MilitaryRanks200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MilitaryRanks200Response
     */
    'status': MilitaryRanks200ResponseStatusEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MilitaryRanks200Response
     */
    'data': { [key: string]: string; };
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MilitaryRanks200Response
     */
    'warnings'?: Array<string>;
}

export const MilitaryRanks200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MilitaryRanks200ResponseStatusEnum = typeof MilitaryRanks200ResponseStatusEnum[keyof typeof MilitaryRanks200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MilitaryRanks200ResponseAllOf
 */
export interface MilitaryRanks200ResponseAllOf {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MilitaryRanks200ResponseAllOf
     */
    'data'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * Error type.
     * @type {string}
     * @memberof ModelError
     */
    'type': string;
    /**
     * Error message.
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * Error details.
     * @type {object}
     * @memberof ModelError
     */
    'details'?: object;
}
/**
 * 
 * @export
 * @interface MrpInput
 */
export interface MrpInput {
    /**
     * MRP data encrypted string.
     * @type {string}
     * @memberof MrpInput
     */
    'data': string;
}
/**
 * 
 * @export
 * @interface MrpPromotionApply200Response
 */
export interface MrpPromotionApply200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof MrpPromotionApply200Response
     */
    'status': MrpPromotionApply200ResponseStatusEnum;
    /**
     * 
     * @type {MrpPromotionApply200ResponseAllOfData}
     * @memberof MrpPromotionApply200Response
     */
    'data': MrpPromotionApply200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof MrpPromotionApply200Response
     */
    'warnings'?: Array<string>;
}

export const MrpPromotionApply200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type MrpPromotionApply200ResponseStatusEnum = typeof MrpPromotionApply200ResponseStatusEnum[keyof typeof MrpPromotionApply200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface MrpPromotionApply200ResponseAllOf
 */
export interface MrpPromotionApply200ResponseAllOf {
    /**
     * 
     * @type {MrpPromotionApply200ResponseAllOfData}
     * @memberof MrpPromotionApply200ResponseAllOf
     */
    'data'?: MrpPromotionApply200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface MrpPromotionApply200ResponseAllOfData
 */
export interface MrpPromotionApply200ResponseAllOfData {
    /**
     * The MRP program UUID.
     * @type {string}
     * @memberof MrpPromotionApply200ResponseAllOfData
     */
    'mrp'?: string;
    /**
     * Redirect URL.
     * @type {string}
     * @memberof MrpPromotionApply200ResponseAllOfData
     */
    'redirect_url'?: string;
}
/**
 * 
 * @export
 * @interface OrderCompletedSegmentMessageOutput
 */
export interface OrderCompletedSegmentMessageOutput {
    /**
     * 
     * @type {OrderCompletedSegmentMessageOutputProperties}
     * @memberof OrderCompletedSegmentMessageOutput
     */
    'properties': OrderCompletedSegmentMessageOutputProperties;
    /**
     * Event name.
     * @type {string}
     * @memberof OrderCompletedSegmentMessageOutput
     */
    'event': string;
    /**
     * The member ID of the user.
     * @type {string}
     * @memberof OrderCompletedSegmentMessageOutput
     */
    'userId': string;
}
/**
 * Event properties.
 * @export
 * @interface OrderCompletedSegmentMessageOutputProperties
 */
export interface OrderCompletedSegmentMessageOutputProperties {
    /**
     * Order affiliation.
     * @type {string}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'affiliation': string;
    /**
     * Order currency.
     * @type {string}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'currency': string;
    /**
     * Order ID.
     * @type {string}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'orderId': string;
    /**
     * Email.
     * @type {string}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'email': string;
    /**
     * Order products.
     * @type {Array<CheckoutSegmentProductOutput>}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'products': Array<CheckoutSegmentProductOutput>;
    /**
     * Order revenue.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'revenue': number;
    /**
     * Order shipping cost.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'shipping': number;
    /**
     * Order subLifecycle.
     * @type {string}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'subLifecycle': string;
    /**
     * Order subtotal.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'subtotal': number;
    /**
     * Order tax.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'tax': number;
    /**
     * Order total.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'total': number;
    /**
     * Promotion code.
     * @type {string}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'code'?: string;
    /**
     * Order discount.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'discount'?: number;
    /**
     * Number of household members in order.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'householdAddonQuantity'?: number;
    /**
     * Order revenue for household members.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'householdAddonRevenue'?: number;
    /**
     * Order payment method.
     * @type {string}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'paymentMethod'?: string;
    /**
     * Order plan revenue.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'planRevenue'?: number;
    /**
     * Order processing fee quantity.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'processingFeeQuantity'?: number;
    /**
     * Order processing fee revenue.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'processingFeeRevenue'?: number;
    /**
     * Order promotion ID.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'promotionId'?: number;
    /**
     * Number of subscription extension products in order.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'subExtensionAddonQuantity'?: number;
    /**
     * Order revenue for subscription extension products.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'subExtensionAddonRevenue'?: number;
    /**
     * Order subscription plan.
     * @type {string}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'subscriptionPlan'?: string;
    /**
     * Order subscription sub-type.
     * @type {string}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'subscriptionSubType'?: string;
    /**
     * Promotion title.
     * @type {string}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'title'?: string;
    /**
     * Number of travel protection products in order.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'travelProtectionAddonQuantity'?: number;
    /**
     * Order revenue for travel protection products.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'travelProtectionAddonRevenue'?: number;
    /**
     * Order wounded VetGift revenue.
     * @type {number}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'woundedVetGiftRevenue'?: number;
    /**
     * Event source.
     * @type {string}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'source': string;
    /**
     * 
     * @type {OrderCompletedSegmentMessageOutputPropertiesContext}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'context': OrderCompletedSegmentMessageOutputPropertiesContext;
    /**
     * Masquerading user ID.
     * @type {string}
     * @memberof OrderCompletedSegmentMessageOutputProperties
     */
    'masquerading'?: string;
}
/**
 * Event context.
 * @export
 * @interface OrderCompletedSegmentMessageOutputPropertiesContext
 */
export interface OrderCompletedSegmentMessageOutputPropertiesContext {
    /**
     * 
     * @type {SegmentMessageOutputPropertiesContextApp}
     * @memberof OrderCompletedSegmentMessageOutputPropertiesContext
     */
    'app': SegmentMessageOutputPropertiesContextApp;
}
/**
 * 
 * @export
 * @interface PagerListOutput
 */
export interface PagerListOutput {
    /**
     * An array of list items.
     * @type {Array<object>}
     * @memberof PagerListOutput
     */
    'items': Array<object>;
    /**
     * Indicates whether there are additional items beyond the end of this list.
     * @type {boolean}
     * @memberof PagerListOutput
     */
    'has_more': boolean;
    /**
     * The total number of items in the list (optional).
     * @type {number}
     * @memberof PagerListOutput
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface PartnerMemberInfoOutput
 */
export interface PartnerMemberInfoOutput {
    /**
     * User tier.
     * @type {string}
     * @memberof PartnerMemberInfoOutput
     */
    'tier'?: PartnerMemberInfoOutputTierEnum;
    /**
     * User first name.
     * @type {string}
     * @memberof PartnerMemberInfoOutput
     */
    'given_name'?: string;
    /**
     * User last name.
     * @type {string}
     * @memberof PartnerMemberInfoOutput
     */
    'family_name'?: string;
    /**
     * User member ID.
     * @type {string}
     * @memberof PartnerMemberInfoOutput
     */
    'sub'?: string;
    /**
     * Flag whether email is verified.
     * @type {boolean}
     * @memberof PartnerMemberInfoOutput
     */
    'email_verified'?: boolean;
    /**
     * User audience.
     * @type {Array<string>}
     * @memberof PartnerMemberInfoOutput
     */
    'audience'?: Array<string>;
    /**
     * User household members info.
     * @type {Array<PartnerMemberInfoOutputHouseholdMemberInfo>}
     * @memberof PartnerMemberInfoOutput
     */
    'household'?: Array<PartnerMemberInfoOutputHouseholdMemberInfo>;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof PartnerMemberInfoOutput
     */
    'email'?: string | null;
    /**
     * User member ID.
     * @type {string}
     * @memberof PartnerMemberInfoOutput
     */
    'member_id'?: string;
}

export const PartnerMemberInfoOutputTierEnum = {
    Basic: 'basic',
    Premium: 'premium'
} as const;

export type PartnerMemberInfoOutputTierEnum = typeof PartnerMemberInfoOutputTierEnum[keyof typeof PartnerMemberInfoOutputTierEnum];

/**
 * 
 * @export
 * @interface PartnerMemberInfoOutputHouseholdMemberInfo
 */
export interface PartnerMemberInfoOutputHouseholdMemberInfo {
    /**
     * User first name.
     * @type {string}
     * @memberof PartnerMemberInfoOutputHouseholdMemberInfo
     */
    'given_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof PartnerMemberInfoOutputHouseholdMemberInfo
     */
    'family_name': string;
    /**
     * User full name.
     * @type {string}
     * @memberof PartnerMemberInfoOutputHouseholdMemberInfo
     */
    'name': string;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof PartnerMemberInfoOutputHouseholdMemberInfo
     */
    'email': string | null;
}
/**
 * 
 * @export
 * @interface PriceOutput
 */
export interface PriceOutput {
    /**
     * Price currency.
     * @type {string}
     * @memberof PriceOutput
     */
    'currency': PriceOutputCurrencyEnum;
    /**
     * Price cost.
     * @type {number}
     * @memberof PriceOutput
     */
    'cost': number;
    /**
     * Price discount.
     * @type {number}
     * @memberof PriceOutput
     */
    'discount': number;
    /**
     * Price amount.
     * @type {number}
     * @memberof PriceOutput
     */
    'amount': number;
}

export const PriceOutputCurrencyEnum = {
    Usd: 'usd'
} as const;

export type PriceOutputCurrencyEnum = typeof PriceOutputCurrencyEnum[keyof typeof PriceOutputCurrencyEnum];

/**
 * 
 * @export
 * @interface PromoInput
 */
export interface PromoInput {
    /**
     * Promo data encrypted string.
     * @type {string}
     * @memberof PromoInput
     */
    'data': string;
}
/**
 * 
 * @export
 * @interface PromotionApply200Response
 */
export interface PromotionApply200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof PromotionApply200Response
     */
    'status': PromotionApply200ResponseStatusEnum;
    /**
     * 
     * @type {PromotionApply200ResponseAllOfData}
     * @memberof PromotionApply200Response
     */
    'data': PromotionApply200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof PromotionApply200Response
     */
    'warnings'?: Array<string>;
}

export const PromotionApply200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type PromotionApply200ResponseStatusEnum = typeof PromotionApply200ResponseStatusEnum[keyof typeof PromotionApply200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface PromotionApply200ResponseAllOf
 */
export interface PromotionApply200ResponseAllOf {
    /**
     * 
     * @type {PromotionApply200ResponseAllOfData}
     * @memberof PromotionApply200ResponseAllOf
     */
    'data'?: PromotionApply200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface PromotionApply200ResponseAllOfData
 */
export interface PromotionApply200ResponseAllOfData {
    /**
     * The Promotion entity UUID.
     * @type {string}
     * @memberof PromotionApply200ResponseAllOfData
     */
    'promo'?: string;
    /**
     * Redirect URL.
     * @type {string}
     * @memberof PromotionApply200ResponseAllOfData
     */
    'redirect_url'?: string;
}
/**
 * 
 * @export
 * @interface PromotionsList200Response
 */
export interface PromotionsList200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof PromotionsList200Response
     */
    'status': PromotionsList200ResponseStatusEnum;
    /**
     * 
     * @type {PromotionsList200ResponseAllOfData}
     * @memberof PromotionsList200Response
     */
    'data': PromotionsList200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof PromotionsList200Response
     */
    'warnings'?: Array<string>;
}

export const PromotionsList200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type PromotionsList200ResponseStatusEnum = typeof PromotionsList200ResponseStatusEnum[keyof typeof PromotionsList200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface PromotionsList200ResponseAllOf
 */
export interface PromotionsList200ResponseAllOf {
    /**
     * 
     * @type {PromotionsList200ResponseAllOfData}
     * @memberof PromotionsList200ResponseAllOf
     */
    'data'?: PromotionsList200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface PromotionsList200ResponseAllOfData
 */
export interface PromotionsList200ResponseAllOfData {
    /**
     * 
     * @type {Array<PurchaseBriefPromotionOutput>}
     * @memberof PromotionsList200ResponseAllOfData
     */
    'items': Array<PurchaseBriefPromotionOutput>;
    /**
     * Indicates whether there are additional items beyond the end of this list.
     * @type {boolean}
     * @memberof PromotionsList200ResponseAllOfData
     */
    'has_more': boolean;
    /**
     * The total number of items in the list (optional).
     * @type {number}
     * @memberof PromotionsList200ResponseAllOfData
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface PromotionsList200ResponseAllOfDataAllOf
 */
export interface PromotionsList200ResponseAllOfDataAllOf {
    /**
     * 
     * @type {Array<PurchaseBriefPromotionOutput>}
     * @memberof PromotionsList200ResponseAllOfDataAllOf
     */
    'items'?: Array<PurchaseBriefPromotionOutput>;
}
/**
 * 
 * @export
 * @interface PurchaseBriefHouseholdMemberOutput
 */
export interface PurchaseBriefHouseholdMemberOutput {
    /**
     * Whether travel_protection is purchased for this member.
     * @type {boolean}
     * @memberof PurchaseBriefHouseholdMemberOutput
     */
    'travel_protection': boolean;
    /**
     * 
     * @type {PurchaseBriefHouseholdMemberOutputExpires}
     * @memberof PurchaseBriefHouseholdMemberOutput
     */
    'expires': PurchaseBriefHouseholdMemberOutputExpires | null;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof PurchaseBriefHouseholdMemberOutput
     */
    'email': string | null;
    /**
     * User first name.
     * @type {string}
     * @memberof PurchaseBriefHouseholdMemberOutput
     */
    'first_name': string;
    /**
     * User ID.
     * @type {string}
     * @memberof PurchaseBriefHouseholdMemberOutput
     */
    'id': string;
    /**
     * User last name.
     * @type {string}
     * @memberof PurchaseBriefHouseholdMemberOutput
     */
    'last_name': string;
    /**
     * Whether the household member is above 13 y.o.
     * @type {boolean}
     * @memberof PurchaseBriefHouseholdMemberOutput
     */
    'above_13': boolean;
    /**
     * Household member relationship to a primary user.
     * @type {string}
     * @memberof PurchaseBriefHouseholdMemberOutput
     */
    'relationship': PurchaseBriefHouseholdMemberOutputRelationshipEnum;
}

export const PurchaseBriefHouseholdMemberOutputRelationshipEnum = {
    Spouse: 'spouse',
    Father: 'father',
    Mother: 'mother',
    Child: 'child',
    Brother: 'brother',
    Sister: 'sister'
} as const;

export type PurchaseBriefHouseholdMemberOutputRelationshipEnum = typeof PurchaseBriefHouseholdMemberOutputRelationshipEnum[keyof typeof PurchaseBriefHouseholdMemberOutputRelationshipEnum];

/**
 * Expire values for subscription and travel_protection.
 * @export
 * @interface PurchaseBriefHouseholdMemberOutputExpires
 */
export interface PurchaseBriefHouseholdMemberOutputExpires {
    /**
     * Subscription expiry date.
     * @type {number}
     * @memberof PurchaseBriefHouseholdMemberOutputExpires
     */
    'subscription': number;
    /**
     * Travel protection expiry date.
     * @type {number}
     * @memberof PurchaseBriefHouseholdMemberOutputExpires
     */
    'travel_protection': number | null;
}
/**
 * 
 * @export
 * @interface PurchaseBriefLineItemOutput
 */
export interface PurchaseBriefLineItemOutput {
    /**
     * Line item type.
     * @type {string}
     * @memberof PurchaseBriefLineItemOutput
     */
    'type': PurchaseBriefLineItemOutputTypeEnum;
    /**
     * Line item title.
     * @type {string}
     * @memberof PurchaseBriefLineItemOutput
     */
    'title': string;
    /**
     * Line item description.
     * @type {string}
     * @memberof PurchaseBriefLineItemOutput
     */
    'description': string;
    /**
     * 
     * @type {PurchaseBriefLineItemOutputDiscountReason}
     * @memberof PurchaseBriefLineItemOutput
     */
    'discount_reason': PurchaseBriefLineItemOutputDiscountReason;
    /**
     * 
     * @type {PurchaseBriefLineItemOutputPrice}
     * @memberof PurchaseBriefLineItemOutput
     */
    'price': PurchaseBriefLineItemOutputPrice;
}

export const PurchaseBriefLineItemOutputTypeEnum = {
    SubscriptionExtension: 'subscription_extension',
    TravelProtection: 'travel_protection',
    Plan: 'plan',
    ProcessingFee: 'processing_fee',
    Contribution: 'contribution'
} as const;

export type PurchaseBriefLineItemOutputTypeEnum = typeof PurchaseBriefLineItemOutputTypeEnum[keyof typeof PurchaseBriefLineItemOutputTypeEnum];

/**
 * Line item discount reason.
 * @export
 * @interface PurchaseBriefLineItemOutputDiscountReason
 */
export interface PurchaseBriefLineItemOutputDiscountReason {
    /**
     * Discount reason description.
     * @type {string}
     * @memberof PurchaseBriefLineItemOutputDiscountReason
     */
    'description': string;
    /**
     * Discount reason type.
     * @type {string}
     * @memberof PurchaseBriefLineItemOutputDiscountReason
     */
    'type': string;
}
/**
 * Line item price.
 * @export
 * @interface PurchaseBriefLineItemOutputPrice
 */
export interface PurchaseBriefLineItemOutputPrice {
    /**
     * Price currency.
     * @type {string}
     * @memberof PurchaseBriefLineItemOutputPrice
     */
    'currency': PurchaseBriefLineItemOutputPriceCurrencyEnum;
    /**
     * Price cost.
     * @type {number}
     * @memberof PurchaseBriefLineItemOutputPrice
     */
    'cost': number;
    /**
     * Price discount.
     * @type {number}
     * @memberof PurchaseBriefLineItemOutputPrice
     */
    'discount': number;
    /**
     * Price amount.
     * @type {number}
     * @memberof PurchaseBriefLineItemOutputPrice
     */
    'amount': number;
}

export const PurchaseBriefLineItemOutputPriceCurrencyEnum = {
    Usd: 'usd'
} as const;

export type PurchaseBriefLineItemOutputPriceCurrencyEnum = typeof PurchaseBriefLineItemOutputPriceCurrencyEnum[keyof typeof PurchaseBriefLineItemOutputPriceCurrencyEnum];

/**
 * 
 * @export
 * @interface PurchaseBriefLineItemsGroupOutput
 */
export interface PurchaseBriefLineItemsGroupOutput {
    /**
     * 
     * @type {PurchaseBriefLineItemsGroupOutputUser}
     * @memberof PurchaseBriefLineItemsGroupOutput
     */
    'user': PurchaseBriefLineItemsGroupOutputUser;
    /**
     * Line items.
     * @type {Array<PurchaseBriefLineItemOutput>}
     * @memberof PurchaseBriefLineItemsGroupOutput
     */
    'items': Array<PurchaseBriefLineItemOutput>;
    /**
     * 
     * @type {PurchaseBriefLineItemsGroupOutputTotal}
     * @memberof PurchaseBriefLineItemsGroupOutput
     */
    'total': PurchaseBriefLineItemsGroupOutputTotal;
}
/**
 * Total price.
 * @export
 * @interface PurchaseBriefLineItemsGroupOutputTotal
 */
export interface PurchaseBriefLineItemsGroupOutputTotal {
    /**
     * Price currency.
     * @type {string}
     * @memberof PurchaseBriefLineItemsGroupOutputTotal
     */
    'currency': PurchaseBriefLineItemsGroupOutputTotalCurrencyEnum;
    /**
     * Price cost.
     * @type {number}
     * @memberof PurchaseBriefLineItemsGroupOutputTotal
     */
    'cost': number;
    /**
     * Price discount.
     * @type {number}
     * @memberof PurchaseBriefLineItemsGroupOutputTotal
     */
    'discount': number;
    /**
     * Price amount.
     * @type {number}
     * @memberof PurchaseBriefLineItemsGroupOutputTotal
     */
    'amount': number;
}

export const PurchaseBriefLineItemsGroupOutputTotalCurrencyEnum = {
    Usd: 'usd'
} as const;

export type PurchaseBriefLineItemsGroupOutputTotalCurrencyEnum = typeof PurchaseBriefLineItemsGroupOutputTotalCurrencyEnum[keyof typeof PurchaseBriefLineItemsGroupOutputTotalCurrencyEnum];

/**
 * User info.
 * @export
 * @interface PurchaseBriefLineItemsGroupOutputUser
 */
export interface PurchaseBriefLineItemsGroupOutputUser {
    /**
     * 
     * @type {string}
     * @memberof PurchaseBriefLineItemsGroupOutputUser
     */
    'id': string | null;
    /**
     * User first name.
     * @type {string}
     * @memberof PurchaseBriefLineItemsGroupOutputUser
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof PurchaseBriefLineItemsGroupOutputUser
     */
    'last_name': string;
    /**
     * User member ID.
     * @type {string}
     * @memberof PurchaseBriefLineItemsGroupOutputUser
     */
    'member_id': string;
}
/**
 * 
 * @export
 * @interface PurchaseBriefLineItemsInfoOutput
 */
export interface PurchaseBriefLineItemsInfoOutput {
    /**
     * 
     * @type {PurchaseBriefLineItemsInfoOutputPrimary}
     * @memberof PurchaseBriefLineItemsInfoOutput
     */
    'primary': PurchaseBriefLineItemsInfoOutputPrimary;
    /**
     * Household members line items groups.
     * @type {Array<PurchaseBriefLineItemsGroupOutput>}
     * @memberof PurchaseBriefLineItemsInfoOutput
     */
    'household_members': Array<PurchaseBriefLineItemsGroupOutput>;
    /**
     * Contrubution line item.
     * @type {Array<PurchaseBriefLineItemOutput>}
     * @memberof PurchaseBriefLineItemsInfoOutput
     */
    'contribution': Array<PurchaseBriefLineItemOutput>;
    /**
     * 
     * @type {PurchaseBriefLineItemsGroupOutputTotal}
     * @memberof PurchaseBriefLineItemsInfoOutput
     */
    'total': PurchaseBriefLineItemsGroupOutputTotal;
}
/**
 * Primary user line items group.
 * @export
 * @interface PurchaseBriefLineItemsInfoOutputPrimary
 */
export interface PurchaseBriefLineItemsInfoOutputPrimary {
    /**
     * 
     * @type {PurchaseBriefLineItemsGroupOutputUser}
     * @memberof PurchaseBriefLineItemsInfoOutputPrimary
     */
    'user': PurchaseBriefLineItemsGroupOutputUser;
    /**
     * Line items.
     * @type {Array<PurchaseBriefLineItemOutput>}
     * @memberof PurchaseBriefLineItemsInfoOutputPrimary
     */
    'items': Array<PurchaseBriefLineItemOutput>;
    /**
     * 
     * @type {PurchaseBriefLineItemsGroupOutputTotal}
     * @memberof PurchaseBriefLineItemsInfoOutputPrimary
     */
    'total': PurchaseBriefLineItemsGroupOutputTotal;
}
/**
 * 
 * @export
 * @interface PurchaseBriefOverviewOutput
 */
export interface PurchaseBriefOverviewOutput {
    /**
     * Military ranks info.
     * @type {{ [key: string]: string; }}
     * @memberof PurchaseBriefOverviewOutput
     */
    'ranks': { [key: string]: string; };
    /**
     * Military branches info.
     * @type {{ [key: string]: string; }}
     * @memberof PurchaseBriefOverviewOutput
     */
    'branches': { [key: string]: string; };
    /**
     * 
     * @type {PurchaseBriefOverviewOutputUser}
     * @memberof PurchaseBriefOverviewOutput
     */
    'user': PurchaseBriefOverviewOutputUser;
    /**
     * 
     * @type {PurchaseBriefOverviewOutputHouseholdMembers}
     * @memberof PurchaseBriefOverviewOutput
     */
    'household_members': PurchaseBriefOverviewOutputHouseholdMembers;
    /**
     * 
     * @type {PurchaseBriefOverviewOutputCard}
     * @memberof PurchaseBriefOverviewOutput
     */
    'card': PurchaseBriefOverviewOutputCard | null;
    /**
     * Available plans.
     * @type {Array<PurchaseBriefPlanOutput>}
     * @memberof PurchaseBriefOverviewOutput
     */
    'plans': Array<PurchaseBriefPlanOutput>;
    /**
     * 
     * @type {PurchaseBriefOverviewOutputBrandQueue}
     * @memberof PurchaseBriefOverviewOutput
     */
    'brand_queue': PurchaseBriefOverviewOutputBrandQueue | null;
    /**
     * 
     * @type {PurchaseBriefOverviewOutputPromotion}
     * @memberof PurchaseBriefOverviewOutput
     */
    'promotion': PurchaseBriefOverviewOutputPromotion | null;
    /**
     * 
     * @type {PurchaseBriefOverviewOutputConfig}
     * @memberof PurchaseBriefOverviewOutput
     */
    'config': PurchaseBriefOverviewOutputConfig;
}
/**
 * Premium brands queue.
 * @export
 * @interface PurchaseBriefOverviewOutputBrandQueue
 */
export interface PurchaseBriefOverviewOutputBrandQueue {
    /**
     * Brand queue id.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputBrandQueue
     */
    'id': string;
    /**
     * Brand queue title.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputBrandQueue
     */
    'title': string;
    /**
     * Brand queue machine name.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputBrandQueue
     */
    'machine_name': string;
    /**
     * Brand queue items.
     * @type {Array<BrandQueueOutputBrand>}
     * @memberof PurchaseBriefOverviewOutputBrandQueue
     */
    'brands': Array<BrandQueueOutputBrand>;
}
/**
 * Card info.
 * @export
 * @interface PurchaseBriefOverviewOutputCard
 */
export interface PurchaseBriefOverviewOutputCard {
    /**
     * Card id.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputCard
     */
    'id': string;
    /**
     * Card brand.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputCard
     */
    'brand': string;
    /**
     * The last four digits of the card.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputCard
     */
    'last4': string;
}
/**
 * Purchase configuration.
 * @export
 * @interface PurchaseBriefOverviewOutputConfig
 */
export interface PurchaseBriefOverviewOutputConfig {
    /**
     * Household members block title override.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputConfig
     */
    'hhm_block_title'?: string;
    /**
     * Household members block body override.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputConfig
     */
    'hhm_block_body'?: string;
    /**
     * Whether the \"X household member(s) added\" info should be hidden.
     * @type {boolean}
     * @memberof PurchaseBriefOverviewOutputConfig
     */
    'hhm_block_hide_added_hhm_info'?: boolean;
    /**
     * \"Add household members\" CTA name override.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputConfig
     */
    'hhm_block_add_hhm_cta_name'?: string;
    /**
     * Whether the household members UI should be excluded.
     * @type {boolean}
     * @memberof PurchaseBriefOverviewOutputConfig
     */
    'household_members_exclude'?: boolean;
    /**
     * Whether the payment info UI should be excluded at no charge.
     * @type {boolean}
     * @memberof PurchaseBriefOverviewOutputConfig
     */
    'payment_info_exclude_at_no_charge'?: boolean;
    /**
     * Whether any subscription extension should be preselected.
     * @type {boolean}
     * @memberof PurchaseBriefOverviewOutputConfig
     */
    'plan_extension_preselect'?: boolean;
    /**
     * Which exactly subscription extension product should be preselected.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputConfig
     */
    'plan_extension_preselect_product'?: string;
    /**
     * Whether the subscription extension UI should be disabled.
     * @type {boolean}
     * @memberof PurchaseBriefOverviewOutputConfig
     */
    'plan_extension_disable'?: boolean;
    /**
     * Whether the promotion info UI should be excluded.
     * @type {boolean}
     * @memberof PurchaseBriefOverviewOutputConfig
     */
    'promotion_info_exclude'?: boolean;
    /**
     * Whether the \"Promotion Applied\" CTA should be hidden.
     * @type {boolean}
     * @memberof PurchaseBriefOverviewOutputConfig
     */
    'promotion_info_hide_promotion_applied_cta'?: boolean;
    /**
     * Whether the first travel protection product should be preselected.
     * @type {boolean}
     * @memberof PurchaseBriefOverviewOutputConfig
     */
    'travel_protection_preselect'?: boolean;
    /**
     * Whether the travel protection UI should be disabled.
     * @type {boolean}
     * @memberof PurchaseBriefOverviewOutputConfig
     */
    'travel_protection_disable'?: boolean;
    /**
     * Travel protection price line text override.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputConfig
     */
    'travel_protection_price_line_text'?: string;
    /**
     * Whether the Vetreward card UI should be hidden.
     * @type {boolean}
     * @memberof PurchaseBriefOverviewOutputConfig
     */
    'vetrewards_card_hide'?: boolean;
}
/**
 * Household members info.
 * @export
 * @interface PurchaseBriefOverviewOutputHouseholdMembers
 */
export interface PurchaseBriefOverviewOutputHouseholdMembers {
    /**
     * Active household members.
     * @type {Array<PurchaseBriefHouseholdMemberOutput>}
     * @memberof PurchaseBriefOverviewOutputHouseholdMembers
     */
    'active': Array<PurchaseBriefHouseholdMemberOutput>;
    /**
     * Inactive household members.
     * @type {Array<PurchaseBriefHouseholdMemberOutput>}
     * @memberof PurchaseBriefOverviewOutputHouseholdMembers
     */
    'inactive': Array<PurchaseBriefHouseholdMemberOutput>;
}
/**
 * Promotion info.
 * @export
 * @interface PurchaseBriefOverviewOutputPromotion
 */
export interface PurchaseBriefOverviewOutputPromotion {
    /**
     * Promotion ID.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputPromotion
     */
    'id': string;
    /**
     * Promotion code.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputPromotion
     */
    'code': string;
    /**
     * Promotion badge.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputPromotion
     */
    'badge': string;
    /**
     * Promotion node ID.
     * @type {number}
     * @memberof PurchaseBriefOverviewOutputPromotion
     */
    'nid': number;
    /**
     * Promotion title.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputPromotion
     */
    'title': string;
    /**
     * 
     * @type {PurchaseBriefPromotionOutputBanner}
     * @memberof PurchaseBriefOverviewOutputPromotion
     */
    'banner': PurchaseBriefPromotionOutputBanner | null;
    /**
     * Date of promotion expiration for current user in milliseconds.
     * @type {number}
     * @memberof PurchaseBriefOverviewOutputPromotion
     */
    'expires_on': number | null;
}
/**
 * User info.
 * @export
 * @interface PurchaseBriefOverviewOutputUser
 */
export interface PurchaseBriefOverviewOutputUser {
    /**
     * 
     * @type {FulfillmentRecordOutputAddress}
     * @memberof PurchaseBriefOverviewOutputUser
     */
    'address': FulfillmentRecordOutputAddress | null;
    /**
     * 
     * @type {UserOutputBilling}
     * @memberof PurchaseBriefOverviewOutputUser
     */
    'billing': UserOutputBilling | null;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputUser
     */
    'email': string | null;
    /**
     * User first name.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputUser
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof PurchaseBriefOverviewOutputUser
     */
    'last_name': string;
    /**
     * 
     * @type {UserOutputMilitary}
     * @memberof PurchaseBriefOverviewOutputUser
     */
    'military': UserOutputMilitary;
    /**
     * The flag indicating whether the user has purchased a travel protection product.
     * @type {boolean}
     * @memberof PurchaseBriefOverviewOutputUser
     */
    'tp_purchased': boolean;
}
/**
 * 
 * @export
 * @interface PurchaseBriefPlanOutput
 */
export interface PurchaseBriefPlanOutput {
    /**
     * Plan ID.
     * @type {string}
     * @memberof PurchaseBriefPlanOutput
     */
    'id': string;
    /**
     * Plan title.
     * @type {string}
     * @memberof PurchaseBriefPlanOutput
     */
    'title': string;
    /**
     * 
     * @type {PurchaseBriefPlanOutputPrice}
     * @memberof PurchaseBriefPlanOutput
     */
    'price': PurchaseBriefPlanOutputPrice;
    /**
     * Plan note.
     * @type {string}
     * @memberof PurchaseBriefPlanOutput
     */
    'note': string;
    /**
     * 
     * @type {PurchaseBriefPlanOutputProcessingFee}
     * @memberof PurchaseBriefPlanOutput
     */
    'processing_fee': PurchaseBriefPlanOutputProcessingFee | null;
    /**
     * 
     * @type {PurchaseBriefPlanOutputPeriod}
     * @memberof PurchaseBriefPlanOutput
     */
    'period': PurchaseBriefPlanOutputPeriod;
    /**
     * Available subscription extension products of the plan.
     * @type {Array<PurchaseBriefProductOutput>}
     * @memberof PurchaseBriefPlanOutput
     */
    'subscription_extension': Array<PurchaseBriefProductOutput>;
    /**
     * Available travel protection products of the plan.
     * @type {Array<PurchaseBriefProductOutput>}
     * @memberof PurchaseBriefPlanOutput
     */
    'travel_protection': Array<PurchaseBriefProductOutput>;
    /**
     * Whether the plan is a trial plan.
     * @type {boolean}
     * @memberof PurchaseBriefPlanOutput
     */
    'trial': boolean;
    /**
     * Plan type.
     * @type {string}
     * @memberof PurchaseBriefPlanOutput
     */
    'type': PurchaseBriefPlanOutputTypeEnum;
    /**
     * Whether the plan is inactive. Set to True if this plan has a \"trial\" flag set and current user has a \"trial_used\" flag set.
     * @type {boolean}
     * @memberof PurchaseBriefPlanOutput
     */
    'inactive': boolean;
}

export const PurchaseBriefPlanOutputTypeEnum = {
    Trial: 'trial',
    Year: 'year',
    Month: 'month'
} as const;

export type PurchaseBriefPlanOutputTypeEnum = typeof PurchaseBriefPlanOutputTypeEnum[keyof typeof PurchaseBriefPlanOutputTypeEnum];

/**
 * Plan period.
 * @export
 * @interface PurchaseBriefPlanOutputPeriod
 */
export interface PurchaseBriefPlanOutputPeriod {
    /**
     * Time period unit.
     * @type {string}
     * @memberof PurchaseBriefPlanOutputPeriod
     */
    'unit': PurchaseBriefPlanOutputPeriodUnitEnum;
    /**
     * Number of time units in a period.
     * @type {number}
     * @memberof PurchaseBriefPlanOutputPeriod
     */
    'number': number;
}

export const PurchaseBriefPlanOutputPeriodUnitEnum = {
    Ms: 'ms',
    Second: 'second',
    Minute: 'minute',
    Hour: 'hour',
    Day: 'day',
    Week: 'week',
    Month: 'month',
    Year: 'year'
} as const;

export type PurchaseBriefPlanOutputPeriodUnitEnum = typeof PurchaseBriefPlanOutputPeriodUnitEnum[keyof typeof PurchaseBriefPlanOutputPeriodUnitEnum];

/**
 * Plan price.
 * @export
 * @interface PurchaseBriefPlanOutputPrice
 */
export interface PurchaseBriefPlanOutputPrice {
    /**
     * Price currency.
     * @type {string}
     * @memberof PurchaseBriefPlanOutputPrice
     */
    'currency': PurchaseBriefPlanOutputPriceCurrencyEnum;
    /**
     * Price cost.
     * @type {number}
     * @memberof PurchaseBriefPlanOutputPrice
     */
    'cost': number;
    /**
     * Price discount.
     * @type {number}
     * @memberof PurchaseBriefPlanOutputPrice
     */
    'discount': number;
    /**
     * Price amount.
     * @type {number}
     * @memberof PurchaseBriefPlanOutputPrice
     */
    'amount': number;
}

export const PurchaseBriefPlanOutputPriceCurrencyEnum = {
    Usd: 'usd'
} as const;

export type PurchaseBriefPlanOutputPriceCurrencyEnum = typeof PurchaseBriefPlanOutputPriceCurrencyEnum[keyof typeof PurchaseBriefPlanOutputPriceCurrencyEnum];

/**
 * Plan processing fee.
 * @export
 * @interface PurchaseBriefPlanOutputProcessingFee
 */
export interface PurchaseBriefPlanOutputProcessingFee {
    /**
     * Price currency.
     * @type {string}
     * @memberof PurchaseBriefPlanOutputProcessingFee
     */
    'currency': PurchaseBriefPlanOutputProcessingFeeCurrencyEnum;
    /**
     * Price cost.
     * @type {number}
     * @memberof PurchaseBriefPlanOutputProcessingFee
     */
    'cost': number;
    /**
     * Price discount.
     * @type {number}
     * @memberof PurchaseBriefPlanOutputProcessingFee
     */
    'discount': number;
    /**
     * Price amount.
     * @type {number}
     * @memberof PurchaseBriefPlanOutputProcessingFee
     */
    'amount': number;
}

export const PurchaseBriefPlanOutputProcessingFeeCurrencyEnum = {
    Usd: 'usd'
} as const;

export type PurchaseBriefPlanOutputProcessingFeeCurrencyEnum = typeof PurchaseBriefPlanOutputProcessingFeeCurrencyEnum[keyof typeof PurchaseBriefPlanOutputProcessingFeeCurrencyEnum];

/**
 * 
 * @export
 * @interface PurchaseBriefProductOutput
 */
export interface PurchaseBriefProductOutput {
    /**
     * Product ID.
     * @type {string}
     * @memberof PurchaseBriefProductOutput
     */
    'id': string;
    /**
     * Product title.
     * @type {string}
     * @memberof PurchaseBriefProductOutput
     */
    'title': string;
    /**
     * Product note.
     * @type {string}
     * @memberof PurchaseBriefProductOutput
     */
    'note': string;
    /**
     * 
     * @type {PurchaseBriefProductOutputPrice}
     * @memberof PurchaseBriefProductOutput
     */
    'price': PurchaseBriefProductOutputPrice;
    /**
     * 
     * @type {PurchaseBriefProductOutputPeriod}
     * @memberof PurchaseBriefProductOutput
     */
    'period': PurchaseBriefProductOutputPeriod;
    /**
     * Is it an OTP product?
     * @type {boolean}
     * @memberof PurchaseBriefProductOutput
     */
    'otp': boolean;
    /**
     * Is it hidden OTP product on UI?
     * @type {boolean}
     * @memberof PurchaseBriefProductOutput
     */
    'hidden': boolean;
}
/**
 * Product period.
 * @export
 * @interface PurchaseBriefProductOutputPeriod
 */
export interface PurchaseBriefProductOutputPeriod {
    /**
     * Time period unit.
     * @type {string}
     * @memberof PurchaseBriefProductOutputPeriod
     */
    'unit': PurchaseBriefProductOutputPeriodUnitEnum;
    /**
     * Number of time units in a period.
     * @type {number}
     * @memberof PurchaseBriefProductOutputPeriod
     */
    'number': number;
}

export const PurchaseBriefProductOutputPeriodUnitEnum = {
    Ms: 'ms',
    Second: 'second',
    Minute: 'minute',
    Hour: 'hour',
    Day: 'day',
    Week: 'week',
    Month: 'month',
    Year: 'year'
} as const;

export type PurchaseBriefProductOutputPeriodUnitEnum = typeof PurchaseBriefProductOutputPeriodUnitEnum[keyof typeof PurchaseBriefProductOutputPeriodUnitEnum];

/**
 * Product price.
 * @export
 * @interface PurchaseBriefProductOutputPrice
 */
export interface PurchaseBriefProductOutputPrice {
    /**
     * Price currency.
     * @type {string}
     * @memberof PurchaseBriefProductOutputPrice
     */
    'currency': PurchaseBriefProductOutputPriceCurrencyEnum;
    /**
     * Price cost.
     * @type {number}
     * @memberof PurchaseBriefProductOutputPrice
     */
    'cost': number;
    /**
     * Price discount.
     * @type {number}
     * @memberof PurchaseBriefProductOutputPrice
     */
    'discount': number;
    /**
     * Price amount.
     * @type {number}
     * @memberof PurchaseBriefProductOutputPrice
     */
    'amount': number;
}

export const PurchaseBriefProductOutputPriceCurrencyEnum = {
    Usd: 'usd'
} as const;

export type PurchaseBriefProductOutputPriceCurrencyEnum = typeof PurchaseBriefProductOutputPriceCurrencyEnum[keyof typeof PurchaseBriefProductOutputPriceCurrencyEnum];

/**
 * 
 * @export
 * @interface PurchaseBriefPromotionOutput
 */
export interface PurchaseBriefPromotionOutput {
    /**
     * Promotion ID.
     * @type {string}
     * @memberof PurchaseBriefPromotionOutput
     */
    'id': string;
    /**
     * Promotion code.
     * @type {string}
     * @memberof PurchaseBriefPromotionOutput
     */
    'code': string;
    /**
     * Promotion badge.
     * @type {string}
     * @memberof PurchaseBriefPromotionOutput
     */
    'badge': string;
    /**
     * Promotion node ID.
     * @type {number}
     * @memberof PurchaseBriefPromotionOutput
     */
    'nid': number;
    /**
     * Promotion title.
     * @type {string}
     * @memberof PurchaseBriefPromotionOutput
     */
    'title': string;
    /**
     * 
     * @type {PurchaseBriefPromotionOutputBanner}
     * @memberof PurchaseBriefPromotionOutput
     */
    'banner': PurchaseBriefPromotionOutputBanner | null;
    /**
     * Date of promotion expiration for current user in milliseconds.
     * @type {number}
     * @memberof PurchaseBriefPromotionOutput
     */
    'expires_on': number | null;
}
/**
 * Promotion banner.
 * @export
 * @interface PurchaseBriefPromotionOutputBanner
 */
export interface PurchaseBriefPromotionOutputBanner {
    /**
     * Banner title.
     * @type {string}
     * @memberof PurchaseBriefPromotionOutputBanner
     */
    'title': string | null;
    /**
     * Banner body.
     * @type {string}
     * @memberof PurchaseBriefPromotionOutputBanner
     */
    'body': string | null;
    /**
     * Banner image.
     * @type {string}
     * @memberof PurchaseBriefPromotionOutputBanner
     */
    'image': string | null;
}
/**
 * 
 * @export
 * @interface PurchaseBriefUserOutput
 */
export interface PurchaseBriefUserOutput {
    /**
     * 
     * @type {FulfillmentRecordOutputAddress}
     * @memberof PurchaseBriefUserOutput
     */
    'address': FulfillmentRecordOutputAddress | null;
    /**
     * 
     * @type {UserOutputBilling}
     * @memberof PurchaseBriefUserOutput
     */
    'billing': UserOutputBilling | null;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof PurchaseBriefUserOutput
     */
    'email': string | null;
    /**
     * User first name.
     * @type {string}
     * @memberof PurchaseBriefUserOutput
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof PurchaseBriefUserOutput
     */
    'last_name': string;
    /**
     * 
     * @type {UserOutputMilitary}
     * @memberof PurchaseBriefUserOutput
     */
    'military': UserOutputMilitary;
    /**
     * The flag indicating whether the user has purchased a travel protection product.
     * @type {boolean}
     * @memberof PurchaseBriefUserOutput
     */
    'tp_purchased': boolean;
}
/**
 * 
 * @export
 * @interface PurchaseCardInfoOutput
 */
export interface PurchaseCardInfoOutput {
    /**
     * Card id.
     * @type {string}
     * @memberof PurchaseCardInfoOutput
     */
    'id': string;
    /**
     * Card brand.
     * @type {string}
     * @memberof PurchaseCardInfoOutput
     */
    'brand': string;
    /**
     * The last four digits of the card.
     * @type {string}
     * @memberof PurchaseCardInfoOutput
     */
    'last4': string;
}
/**
 * 
 * @export
 * @interface PurchaseHouseholdMemberExistingInput
 */
export interface PurchaseHouseholdMemberExistingInput {
    /**
     * User ID.
     * @type {string}
     * @memberof PurchaseHouseholdMemberExistingInput
     */
    'id': string;
    /**
     * Flag indicating whether to purchase a travel protection product.
     * @type {boolean}
     * @memberof PurchaseHouseholdMemberExistingInput
     */
    'travel_protection'?: boolean;
}
/**
 * 
 * @export
 * @interface PurchaseHouseholdMemberNewInput
 */
export interface PurchaseHouseholdMemberNewInput {
    /**
     * Flag indicating whether to purchase a travel protection product.
     * @type {boolean}
     * @memberof PurchaseHouseholdMemberNewInput
     */
    'travel_protection'?: boolean;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof PurchaseHouseholdMemberNewInput
     */
    'email': string | null;
    /**
     * User first name.
     * @type {string}
     * @memberof PurchaseHouseholdMemberNewInput
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof PurchaseHouseholdMemberNewInput
     */
    'last_name': string;
    /**
     * Household member relationship to a primary user.
     * @type {string}
     * @memberof PurchaseHouseholdMemberNewInput
     */
    'relationship': PurchaseHouseholdMemberNewInputRelationshipEnum;
    /**
     * Whether the household member is above 13 y.o.
     * @type {boolean}
     * @memberof PurchaseHouseholdMemberNewInput
     */
    'above_13': boolean;
}

export const PurchaseHouseholdMemberNewInputRelationshipEnum = {
    Spouse: 'spouse',
    Father: 'father',
    Mother: 'mother',
    Child: 'child',
    Brother: 'brother',
    Sister: 'sister'
} as const;

export type PurchaseHouseholdMemberNewInputRelationshipEnum = typeof PurchaseHouseholdMemberNewInputRelationshipEnum[keyof typeof PurchaseHouseholdMemberNewInputRelationshipEnum];

/**
 * 
 * @export
 * @interface PurchaseInput
 */
export interface PurchaseInput {
    /**
     * Household members info.
     * @type {Array<PurchaseInputHouseholdMembersInner>}
     * @memberof PurchaseInput
     */
    'household_members'?: Array<PurchaseInputHouseholdMembersInner>;
    /**
     * Flag indicating whether to cancel auto-renew of subscription/TP.
     * @type {string}
     * @memberof PurchaseInput
     */
    'cancel'?: PurchaseInputCancelEnum;
    /**
     * Payment method ID.
     * @type {string}
     * @memberof PurchaseInput
     */
    'payment_method': string;
    /**
     * Plan ID.
     * @type {string}
     * @memberof PurchaseInput
     */
    'plan': string;
    /**
     * Subscription extension product ID.
     * @type {string}
     * @memberof PurchaseInput
     */
    'subscription_extension'?: string;
    /**
     * Travel protection product ID.
     * @type {string}
     * @memberof PurchaseInput
     */
    'travel_protection'?: string | null;
    /**
     * Contribution amount in cents.
     * @type {number}
     * @memberof PurchaseInput
     */
    'contribution'?: number;
    /**
     * 
     * @type {EnrollInputAdmin}
     * @memberof PurchaseInput
     */
    'admin'?: EnrollInputAdmin;
}

export const PurchaseInputCancelEnum = {
    Plan: 'plan',
    TravelProtection: 'travel_protection'
} as const;

export type PurchaseInputCancelEnum = typeof PurchaseInputCancelEnum[keyof typeof PurchaseInputCancelEnum];

/**
 * 
 * @export
 * @interface PurchaseInputAdminConfig
 */
export interface PurchaseInputAdminConfig {
    /**
     * Indicator whether there should be no charge.
     * @type {boolean}
     * @memberof PurchaseInputAdminConfig
     */
    'no_charge': boolean;
}
/**
 * @type PurchaseInputHouseholdMembersInner
 * @export
 */
export type PurchaseInputHouseholdMembersInner = PurchaseHouseholdMemberExistingInput | PurchaseHouseholdMemberNewInput;

/**
 * 
 * @export
 * @interface PurchaseLineItemsGroupBriefOutputUserInfo
 */
export interface PurchaseLineItemsGroupBriefOutputUserInfo {
    /**
     * 
     * @type {string}
     * @memberof PurchaseLineItemsGroupBriefOutputUserInfo
     */
    'id': string | null;
    /**
     * User first name.
     * @type {string}
     * @memberof PurchaseLineItemsGroupBriefOutputUserInfo
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof PurchaseLineItemsGroupBriefOutputUserInfo
     */
    'last_name': string;
    /**
     * User member ID.
     * @type {string}
     * @memberof PurchaseLineItemsGroupBriefOutputUserInfo
     */
    'member_id': string;
}
/**
 * 
 * @export
 * @interface SegmentMessageOutputPropertiesContext
 */
export interface SegmentMessageOutputPropertiesContext {
    /**
     * 
     * @type {SegmentMessageOutputPropertiesContextApp}
     * @memberof SegmentMessageOutputPropertiesContext
     */
    'app': SegmentMessageOutputPropertiesContextApp;
}
/**
 * Event context app.
 * @export
 * @interface SegmentMessageOutputPropertiesContextApp
 */
export interface SegmentMessageOutputPropertiesContextApp {
    /**
     * The app build.
     * @type {string}
     * @memberof SegmentMessageOutputPropertiesContextApp
     */
    'build': string;
    /**
     * The app name.
     * @type {string}
     * @memberof SegmentMessageOutputPropertiesContextApp
     */
    'name': string;
    /**
     * The app namespace.
     * @type {string}
     * @memberof SegmentMessageOutputPropertiesContextApp
     */
    'namespace': string;
    /**
     * The app version.
     * @type {string}
     * @memberof SegmentMessageOutputPropertiesContextApp
     */
    'version': string;
}
/**
 * 
 * @export
 * @interface ShippingPaymentInfoUpdate200Response
 */
export interface ShippingPaymentInfoUpdate200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200Response
     */
    'status': ShippingPaymentInfoUpdate200ResponseStatusEnum;
    /**
     * 
     * @type {ShippingPaymentInfoUpdate200ResponseAllOfData}
     * @memberof ShippingPaymentInfoUpdate200Response
     */
    'data': ShippingPaymentInfoUpdate200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof ShippingPaymentInfoUpdate200Response
     */
    'warnings'?: Array<string>;
}

export const ShippingPaymentInfoUpdate200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type ShippingPaymentInfoUpdate200ResponseStatusEnum = typeof ShippingPaymentInfoUpdate200ResponseStatusEnum[keyof typeof ShippingPaymentInfoUpdate200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface ShippingPaymentInfoUpdate200ResponseAllOf
 */
export interface ShippingPaymentInfoUpdate200ResponseAllOf {
    /**
     * 
     * @type {ShippingPaymentInfoUpdate200ResponseAllOfData}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOf
     */
    'data'?: ShippingPaymentInfoUpdate200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface ShippingPaymentInfoUpdate200ResponseAllOfData
 */
export interface ShippingPaymentInfoUpdate200ResponseAllOfData {
    /**
     * User affinity.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'affinity': ShippingPaymentInfoUpdate200ResponseAllOfDataAffinityEnum;
    /**
     * User gender.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'gender': ShippingPaymentInfoUpdate200ResponseAllOfDataGenderEnum;
    /**
     * User gender (other). Required if gender is \"other\".
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'gender_other': string | null;
    /**
     * User honorific.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'honorific': ShippingPaymentInfoUpdate200ResponseAllOfDataHonorificEnum;
    /**
     * User ID.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'id': string;
    /**
     * 
     * @type {UserOutputActiveDuty}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'active_duty': UserOutputActiveDuty;
    /**
     * User tier.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'tier': ShippingPaymentInfoUpdate200ResponseAllOfDataTierEnum;
    /**
     * User first name.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'last_name': string;
    /**
     * User middle name.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'middle_name': string | null;
    /**
     * 
     * @type {FulfillmentRecordOutputAddress}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'address': FulfillmentRecordOutputAddress | null;
    /**
     * User phone.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'phone': string | null;
    /**
     * 
     * @type {UserOutputBilling}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'billing': UserOutputBilling | null;
    /**
     * User date of birth.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'dob': string | null;
    /**
     * 
     * @type {UserOutputProfilePhoto}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'profile_photo': UserOutputProfilePhoto;
    /**
     * 
     * @type {UserOutputReplacementCard}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'replacement_card': UserOutputReplacementCard;
    /**
     * User timezone.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'timezone': string | null;
    /**
     * 
     * @type {UserOutputMilitary}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'military': UserOutputMilitary;
    /**
     * 
     * @type {UserOutputMultiFactorAuth}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'multi_factor_auth': UserOutputMultiFactorAuth;
    /**
     * 
     * @type {UserOutputVerification}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'verification': UserOutputVerification;
    /**
     * 
     * @type {UserOutputIdentification}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'identification': UserOutputIdentification;
    /**
     * 
     * @type {UserOutputHouseholdMembers}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'household_members': UserOutputHouseholdMembers;
    /**
     * 
     * @type {UserOutputHouseholdMember}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'household_member': UserOutputHouseholdMember | null;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'email': string | null;
    /**
     * User MRP program.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'mrp': string | null;
    /**
     * User MRP brand.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'mrp_brand': string | null;
    /**
     * User MRP company.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'mrp_company': string | null;
    /**
     * User MRP name.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'mrp_name': string | null;
    /**
     * 
     * @type {UserOutputPromotion}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'promotion': UserOutputPromotion;
    /**
     * 
     * @type {UserOutputSubscription}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'subscription': UserOutputSubscription | null;
    /**
     * The flag indicating whether the last user payment attempt failed.
     * @type {boolean}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'payment_failed': boolean;
    /**
     * An array of extra roles of the user.
     * @type {Array<string>}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'roles': Array<string>;
    /**
     * User member ID.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'member_id': string;
    /**
     * User travel code.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'travel_code': string;
    /**
     * Username of the user. Email or auto generated string (first name + last name + random digits) for household member that are not above 13 y.o.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'username': string;
    /**
     * The flag indicating whether the user has purchased a subscription.
     * @type {boolean}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'subscription_purchased': boolean;
    /**
     * The time when the user has purchased a subscription.
     * @type {number}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'subscription_purchased_time': number;
    /**
     * The flag indicating whether the user has purchased a travel protection product.
     * @type {boolean}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'tp_purchased': boolean;
    /**
     * The time when the user has purchased a travel protection product.
     * @type {number}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'tp_purchased_time': number;
    /**
     * The time when the user has purchased a subscription extension product.
     * @type {number}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'pe_purchased_time': number;
    /**
     * The flag indicating whether the user has used a trial subscription.
     * @type {boolean}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'trial_used': boolean;
    /**
     * 
     * @type {UserOutputContactPreferences}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'contact_preferences': UserOutputContactPreferences;
    /**
     * The date the user was created.
     * @type {number}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'created': number;
    /**
     * The date the user was last updated.
     * @type {number}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'updated': number;
    /**
     * 
     * @type {UserOutputSegment}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'segment': UserOutputSegment;
    /**
     * 
     * @type {UserOutputMarketing}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'marketing': UserOutputMarketing;
    /**
     * 
     * @type {UserOutputVerified}
     * @memberof ShippingPaymentInfoUpdate200ResponseAllOfData
     */
    'verified': UserOutputVerified;
}

export const ShippingPaymentInfoUpdate200ResponseAllOfDataAffinityEnum = {
    Veteran: 'veteran',
    Relative: 'relative',
    Not: 'not',
    Commissioned: 'commissioned',
    ActiveDuty: 'active_duty',
    Academy: 'academy'
} as const;

export type ShippingPaymentInfoUpdate200ResponseAllOfDataAffinityEnum = typeof ShippingPaymentInfoUpdate200ResponseAllOfDataAffinityEnum[keyof typeof ShippingPaymentInfoUpdate200ResponseAllOfDataAffinityEnum];
export const ShippingPaymentInfoUpdate200ResponseAllOfDataGenderEnum = {
    Male: 'male',
    Female: 'female',
    Other: 'other'
} as const;

export type ShippingPaymentInfoUpdate200ResponseAllOfDataGenderEnum = typeof ShippingPaymentInfoUpdate200ResponseAllOfDataGenderEnum[keyof typeof ShippingPaymentInfoUpdate200ResponseAllOfDataGenderEnum];
export const ShippingPaymentInfoUpdate200ResponseAllOfDataHonorificEnum = {
    Dr: 'dr',
    Miss: 'miss',
    Mr: 'mr',
    Mrs: 'mrs',
    Ms: 'ms',
    Mstr: 'mstr',
    Prof: 'prof',
    Rev: 'rev',
    Sir: 'sir',
    Sr: 'sr'
} as const;

export type ShippingPaymentInfoUpdate200ResponseAllOfDataHonorificEnum = typeof ShippingPaymentInfoUpdate200ResponseAllOfDataHonorificEnum[keyof typeof ShippingPaymentInfoUpdate200ResponseAllOfDataHonorificEnum];
export const ShippingPaymentInfoUpdate200ResponseAllOfDataTierEnum = {
    VaUnconfirmed: 'va_unconfirmed',
    VaSupporter: 'va_supporter',
    VaMember: 'va_member',
    VaFamily: 'va_family',
    VaActiveDuty: 'va_active_duty',
    VrMember: 'vr_member',
    VrMemberD: 'vr_member_d',
    VrMemberDw: 'vr_member_dw',
    VrFamily: 'vr_family',
    VrFamilyD: 'vr_family_d',
    VrFamilyDw: 'vr_family_dw',
    VrActiveDuty: 'vr_active_duty',
    VrHousehold: 'vr_household'
} as const;

export type ShippingPaymentInfoUpdate200ResponseAllOfDataTierEnum = typeof ShippingPaymentInfoUpdate200ResponseAllOfDataTierEnum[keyof typeof ShippingPaymentInfoUpdate200ResponseAllOfDataTierEnum];

/**
 * 
 * @export
 * @interface ShippingPaymentInfoUpdateInput
 */
export interface ShippingPaymentInfoUpdateInput {
    /**
     * 
     * @type {ShippingPaymentInfoUpdateInputAddress}
     * @memberof ShippingPaymentInfoUpdateInput
     */
    'address': ShippingPaymentInfoUpdateInputAddress;
    /**
     * 
     * @type {ShippingPaymentInfoUpdateInputBilling}
     * @memberof ShippingPaymentInfoUpdateInput
     */
    'billing'?: ShippingPaymentInfoUpdateInputBilling | null;
    /**
     * Military rank ID. Accepted if address.type or billing.address.type is set to \"post\".
     * @type {string}
     * @memberof ShippingPaymentInfoUpdateInput
     */
    'rank'?: string | null;
    /**
     * Credit or debit card token ID. Required if user has no card set up, and no PaymentInfoExclude enroll adjuster applied.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdateInput
     */
    'card_token'?: string | null;
}
/**
 * Shipping address.
 * @export
 * @interface ShippingPaymentInfoUpdateInputAddress
 */
export interface ShippingPaymentInfoUpdateInputAddress {
    /**
     * Address type.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdateInputAddress
     */
    'type': ShippingPaymentInfoUpdateInputAddressTypeEnum;
    /**
     * Street address.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdateInputAddress
     */
    'line1': string;
    /**
     * Apartment, suite, box number, etc.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdateInputAddress
     */
    'line2'?: string | null;
    /**
     * Postal code, ZIP (or ZIP+4) code.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdateInputAddress
     */
    'postal_code': string;
    /**
     * Depending on address type: - home: city or town; - post: \"APO\", \"FPO\", or \"DPO\".
     * @type {string}
     * @memberof ShippingPaymentInfoUpdateInputAddress
     */
    'city': string;
    /**
     * State, province, region (ISO code when available).
     * @type {string}
     * @memberof ShippingPaymentInfoUpdateInputAddress
     */
    'state': string;
    /**
     * Country (2 character ISO code).
     * @type {string}
     * @memberof ShippingPaymentInfoUpdateInputAddress
     */
    'country'?: string | null;
}

export const ShippingPaymentInfoUpdateInputAddressTypeEnum = {
    Home: 'home',
    Post: 'post'
} as const;

export type ShippingPaymentInfoUpdateInputAddressTypeEnum = typeof ShippingPaymentInfoUpdateInputAddressTypeEnum[keyof typeof ShippingPaymentInfoUpdateInputAddressTypeEnum];

/**
 * Billing info.
 * @export
 * @interface ShippingPaymentInfoUpdateInputBilling
 */
export interface ShippingPaymentInfoUpdateInputBilling {
    /**
     * 
     * @type {ShippingPaymentInfoUpdateInputBillingInfoAddress}
     * @memberof ShippingPaymentInfoUpdateInputBilling
     */
    'address'?: ShippingPaymentInfoUpdateInputBillingInfoAddress | null;
}
/**
 * 
 * @export
 * @interface ShippingPaymentInfoUpdateInputBillingInfo
 */
export interface ShippingPaymentInfoUpdateInputBillingInfo {
    /**
     * 
     * @type {ShippingPaymentInfoUpdateInputBillingInfoAddress}
     * @memberof ShippingPaymentInfoUpdateInputBillingInfo
     */
    'address'?: ShippingPaymentInfoUpdateInputBillingInfoAddress | null;
}
/**
 * Billing address.
 * @export
 * @interface ShippingPaymentInfoUpdateInputBillingInfoAddress
 */
export interface ShippingPaymentInfoUpdateInputBillingInfoAddress {
    /**
     * Address type.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdateInputBillingInfoAddress
     */
    'type': ShippingPaymentInfoUpdateInputBillingInfoAddressTypeEnum;
    /**
     * Street address.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdateInputBillingInfoAddress
     */
    'line1': string;
    /**
     * Apartment, suite, box number, etc.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdateInputBillingInfoAddress
     */
    'line2'?: string | null;
    /**
     * Postal code, ZIP (or ZIP+4) code.
     * @type {string}
     * @memberof ShippingPaymentInfoUpdateInputBillingInfoAddress
     */
    'postal_code': string;
    /**
     * Depending on address type: - home: city or town; - post: \"APO\", \"FPO\", or \"DPO\".
     * @type {string}
     * @memberof ShippingPaymentInfoUpdateInputBillingInfoAddress
     */
    'city': string;
    /**
     * State, province, region (ISO code when available).
     * @type {string}
     * @memberof ShippingPaymentInfoUpdateInputBillingInfoAddress
     */
    'state': string;
    /**
     * Country (2 character ISO code).
     * @type {string}
     * @memberof ShippingPaymentInfoUpdateInputBillingInfoAddress
     */
    'country'?: string | null;
}

export const ShippingPaymentInfoUpdateInputBillingInfoAddressTypeEnum = {
    Home: 'home',
    Post: 'post'
} as const;

export type ShippingPaymentInfoUpdateInputBillingInfoAddressTypeEnum = typeof ShippingPaymentInfoUpdateInputBillingInfoAddressTypeEnum[keyof typeof ShippingPaymentInfoUpdateInputBillingInfoAddressTypeEnum];

/**
 * 
 * @export
 * @interface SsoMember200Response
 */
export interface SsoMember200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof SsoMember200Response
     */
    'status': SsoMember200ResponseStatusEnum;
    /**
     * 
     * @type {SsoMember200ResponseAllOfData}
     * @memberof SsoMember200Response
     */
    'data': SsoMember200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof SsoMember200Response
     */
    'warnings'?: Array<string>;
}

export const SsoMember200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type SsoMember200ResponseStatusEnum = typeof SsoMember200ResponseStatusEnum[keyof typeof SsoMember200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface SsoMember200ResponseAllOf
 */
export interface SsoMember200ResponseAllOf {
    /**
     * 
     * @type {SsoMember200ResponseAllOfData}
     * @memberof SsoMember200ResponseAllOf
     */
    'data'?: SsoMember200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface SsoMember200ResponseAllOfData
 */
export interface SsoMember200ResponseAllOfData {
    /**
     * Abenity SSO token url.
     * @type {string}
     * @memberof SsoMember200ResponseAllOfData
     */
    'token_url': string;
}
/**
 * 
 * @export
 * @interface SubscriptionOutputTravelProtectionInfo
 */
export interface SubscriptionOutputTravelProtectionInfo {
    /**
     * Travel protection product ID.
     * @type {string}
     * @memberof SubscriptionOutputTravelProtectionInfo
     */
    'id': string;
    /**
     * Travel protection autorenew.
     * @type {boolean}
     * @memberof SubscriptionOutputTravelProtectionInfo
     */
    'autorenew': boolean;
    /**
     * The date travel protection autorenew was disabled on in milliseconds.
     * @type {number}
     * @memberof SubscriptionOutputTravelProtectionInfo
     */
    'autorenew_disabled_on': number | null;
    /**
     * The date of travel protection expiration in milliseconds.
     * @type {number}
     * @memberof SubscriptionOutputTravelProtectionInfo
     */
    'expires': number;
}
/**
 * 
 * @export
 * @interface SuccessResponse
 */
export interface SuccessResponse {
    /**
     * Action status.
     * @type {string}
     * @memberof SuccessResponse
     */
    'status': SuccessResponseStatusEnum;
    /**
     * Action data.
     * @type {object}
     * @memberof SuccessResponse
     */
    'data': object;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof SuccessResponse
     */
    'warnings'?: Array<string>;
}

export const SuccessResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type SuccessResponseStatusEnum = typeof SuccessResponseStatusEnum[keyof typeof SuccessResponseStatusEnum];

/**
 * 
 * @export
 * @interface SuccessResponseEmpty
 */
export interface SuccessResponseEmpty {
    /**
     * 
     * @type {string}
     * @memberof SuccessResponseEmpty
     */
    'status': SuccessResponseEmptyStatusEnum;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof SuccessResponseEmpty
     */
    'warnings'?: Array<string>;
}

export const SuccessResponseEmptyStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type SuccessResponseEmptyStatusEnum = typeof SuccessResponseEmptyStatusEnum[keyof typeof SuccessResponseEmptyStatusEnum];

/**
 * 
 * @export
 * @interface TimePeriodOutput
 */
export interface TimePeriodOutput {
    /**
     * Time period unit.
     * @type {string}
     * @memberof TimePeriodOutput
     */
    'unit': TimePeriodOutputUnitEnum;
    /**
     * Number of time units in a period.
     * @type {number}
     * @memberof TimePeriodOutput
     */
    'number': number;
}

export const TimePeriodOutputUnitEnum = {
    Ms: 'ms',
    Second: 'second',
    Minute: 'minute',
    Hour: 'hour',
    Day: 'day',
    Week: 'week',
    Month: 'month',
    Year: 'year'
} as const;

export type TimePeriodOutputUnitEnum = typeof TimePeriodOutputUnitEnum[keyof typeof TimePeriodOutputUnitEnum];

/**
 * 
 * @export
 * @interface Timezones200Response
 */
export interface Timezones200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof Timezones200Response
     */
    'status': Timezones200ResponseStatusEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Timezones200Response
     */
    'data': { [key: string]: string; };
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof Timezones200Response
     */
    'warnings'?: Array<string>;
}

export const Timezones200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type Timezones200ResponseStatusEnum = typeof Timezones200ResponseStatusEnum[keyof typeof Timezones200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface Timezones200ResponseAllOf
 */
export interface Timezones200ResponseAllOf {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Timezones200ResponseAllOf
     */
    'data'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Token200Response
 */
export interface Token200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof Token200Response
     */
    'status': Token200ResponseStatusEnum;
    /**
     * 
     * @type {Token200ResponseAllOfData}
     * @memberof Token200Response
     */
    'data': Token200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof Token200Response
     */
    'warnings'?: Array<string>;
}

export const Token200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type Token200ResponseStatusEnum = typeof Token200ResponseStatusEnum[keyof typeof Token200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface Token200ResponseAllOf
 */
export interface Token200ResponseAllOf {
    /**
     * 
     * @type {Token200ResponseAllOfData}
     * @memberof Token200ResponseAllOf
     */
    'data'?: Token200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface Token200ResponseAllOfData
 */
export interface Token200ResponseAllOfData {
    /**
     * Kustomer auth token.
     * @type {string}
     * @memberof Token200ResponseAllOfData
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface UpsellInput
 */
export interface UpsellInput {
    /**
     * Upsell data encrypted string.
     * @type {string}
     * @memberof UpsellInput
     */
    'data': string;
}
/**
 * 
 * @export
 * @interface UpsellPromotionApply200Response
 */
export interface UpsellPromotionApply200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof UpsellPromotionApply200Response
     */
    'status': UpsellPromotionApply200ResponseStatusEnum;
    /**
     * 
     * @type {UpsellPromotionApply200ResponseAllOfData}
     * @memberof UpsellPromotionApply200Response
     */
    'data': UpsellPromotionApply200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof UpsellPromotionApply200Response
     */
    'warnings'?: Array<string>;
}

export const UpsellPromotionApply200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type UpsellPromotionApply200ResponseStatusEnum = typeof UpsellPromotionApply200ResponseStatusEnum[keyof typeof UpsellPromotionApply200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface UpsellPromotionApply200ResponseAllOf
 */
export interface UpsellPromotionApply200ResponseAllOf {
    /**
     * 
     * @type {UpsellPromotionApply200ResponseAllOfData}
     * @memberof UpsellPromotionApply200ResponseAllOf
     */
    'data'?: UpsellPromotionApply200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface UpsellPromotionApply200ResponseAllOfData
 */
export interface UpsellPromotionApply200ResponseAllOfData {
    /**
     * Redirect URL.
     * @type {string}
     * @memberof UpsellPromotionApply200ResponseAllOfData
     */
    'redirect_url'?: string;
}
/**
 * 
 * @export
 * @interface UserContactPreferencesInput
 */
export interface UserContactPreferencesInput {
    /**
     * News mailing list subscription flag.
     * @type {boolean}
     * @memberof UserContactPreferencesInput
     */
    'news': boolean;
}
/**
 * 
 * @export
 * @interface UserContactPreferencesOutput
 */
export interface UserContactPreferencesOutput {
    /**
     * News mailing list subscription flag.
     * @type {boolean}
     * @memberof UserContactPreferencesOutput
     */
    'news': boolean;
}
/**
 * 
 * @export
 * @interface UserHouseholdMemberOutput
 */
export interface UserHouseholdMemberOutput {
    /**
     * Household member relationship to a primary user.
     * @type {string}
     * @memberof UserHouseholdMemberOutput
     */
    'relationship': UserHouseholdMemberOutputRelationshipEnum;
    /**
     * Whether the household member is above 13 y.o.
     * @type {boolean}
     * @memberof UserHouseholdMemberOutput
     */
    'above_13': boolean;
    /**
     * Primary user ID.
     * @type {string}
     * @memberof UserHouseholdMemberOutput
     */
    'primary': string;
    /**
     * The time when the welcome email has been sent.
     * @type {number}
     * @memberof UserHouseholdMemberOutput
     */
    'invite_sent': number;
}

export const UserHouseholdMemberOutputRelationshipEnum = {
    Spouse: 'spouse',
    Father: 'father',
    Mother: 'mother',
    Child: 'child',
    Brother: 'brother',
    Sister: 'sister'
} as const;

export type UserHouseholdMemberOutputRelationshipEnum = typeof UserHouseholdMemberOutputRelationshipEnum[keyof typeof UserHouseholdMemberOutputRelationshipEnum];

/**
 * 
 * @export
 * @interface UserMilitaryInfoOutput
 */
export interface UserMilitaryInfoOutput {
    /**
     * User military branch ID.
     * @type {string}
     * @memberof UserMilitaryInfoOutput
     */
    'branch': string | null;
    /**
     * User military era ID.
     * @type {string}
     * @memberof UserMilitaryInfoOutput
     */
    'era': string | null;
    /**
     * User military rank ID.
     * @type {string}
     * @memberof UserMilitaryInfoOutput
     */
    'rank': string | null;
}
/**
 * 
 * @export
 * @interface UserMultiFactorAuthenticationOutput
 */
export interface UserMultiFactorAuthenticationOutput {
    /**
     * Flag is two factor authentication are enabled.
     * @type {boolean}
     * @memberof UserMultiFactorAuthenticationOutput
     */
    'enabled': boolean;
    /**
     * Secret code for two factor authentication.
     * @type {string}
     * @memberof UserMultiFactorAuthenticationOutput
     */
    'secret': string;
    /**
     * Date of adding secret key for the user.
     * @type {number}
     * @memberof UserMultiFactorAuthenticationOutput
     */
    'createdAt': number;
}
/**
 * 
 * @export
 * @interface UserOutput
 */
export interface UserOutput {
    /**
     * User affinity.
     * @type {string}
     * @memberof UserOutput
     */
    'affinity': UserOutputAffinityEnum;
    /**
     * User gender.
     * @type {string}
     * @memberof UserOutput
     */
    'gender': UserOutputGenderEnum;
    /**
     * User gender (other). Required if gender is \"other\".
     * @type {string}
     * @memberof UserOutput
     */
    'gender_other': string | null;
    /**
     * User honorific.
     * @type {string}
     * @memberof UserOutput
     */
    'honorific': UserOutputHonorificEnum;
    /**
     * User ID.
     * @type {string}
     * @memberof UserOutput
     */
    'id': string;
    /**
     * 
     * @type {UserOutputActiveDuty}
     * @memberof UserOutput
     */
    'active_duty': UserOutputActiveDuty;
    /**
     * User tier.
     * @type {string}
     * @memberof UserOutput
     */
    'tier': UserOutputTierEnum;
    /**
     * User first name.
     * @type {string}
     * @memberof UserOutput
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof UserOutput
     */
    'last_name': string;
    /**
     * User middle name.
     * @type {string}
     * @memberof UserOutput
     */
    'middle_name': string | null;
    /**
     * 
     * @type {FulfillmentRecordOutputAddress}
     * @memberof UserOutput
     */
    'address': FulfillmentRecordOutputAddress | null;
    /**
     * User phone.
     * @type {string}
     * @memberof UserOutput
     */
    'phone': string | null;
    /**
     * 
     * @type {UserOutputBilling}
     * @memberof UserOutput
     */
    'billing': UserOutputBilling | null;
    /**
     * User date of birth.
     * @type {string}
     * @memberof UserOutput
     */
    'dob': string | null;
    /**
     * 
     * @type {UserOutputProfilePhoto}
     * @memberof UserOutput
     */
    'profile_photo': UserOutputProfilePhoto;
    /**
     * 
     * @type {UserOutputReplacementCard}
     * @memberof UserOutput
     */
    'replacement_card': UserOutputReplacementCard;
    /**
     * User timezone.
     * @type {string}
     * @memberof UserOutput
     */
    'timezone': string | null;
    /**
     * 
     * @type {UserOutputMilitary}
     * @memberof UserOutput
     */
    'military': UserOutputMilitary;
    /**
     * 
     * @type {UserOutputMultiFactorAuth}
     * @memberof UserOutput
     */
    'multi_factor_auth': UserOutputMultiFactorAuth;
    /**
     * 
     * @type {UserOutputVerification}
     * @memberof UserOutput
     */
    'verification': UserOutputVerification;
    /**
     * 
     * @type {UserOutputIdentification}
     * @memberof UserOutput
     */
    'identification': UserOutputIdentification;
    /**
     * 
     * @type {UserOutputHouseholdMembers}
     * @memberof UserOutput
     */
    'household_members': UserOutputHouseholdMembers;
    /**
     * 
     * @type {UserOutputHouseholdMember}
     * @memberof UserOutput
     */
    'household_member': UserOutputHouseholdMember | null;
    /**
     * User email address. Required if user is a primary user or is a household member that is above 13 y.o.
     * @type {string}
     * @memberof UserOutput
     */
    'email': string | null;
    /**
     * User MRP program.
     * @type {string}
     * @memberof UserOutput
     */
    'mrp': string | null;
    /**
     * User MRP brand.
     * @type {string}
     * @memberof UserOutput
     */
    'mrp_brand': string | null;
    /**
     * User MRP company.
     * @type {string}
     * @memberof UserOutput
     */
    'mrp_company': string | null;
    /**
     * User MRP name.
     * @type {string}
     * @memberof UserOutput
     */
    'mrp_name': string | null;
    /**
     * 
     * @type {UserOutputPromotion}
     * @memberof UserOutput
     */
    'promotion': UserOutputPromotion;
    /**
     * 
     * @type {UserOutputSubscription}
     * @memberof UserOutput
     */
    'subscription': UserOutputSubscription | null;
    /**
     * The flag indicating whether the last user payment attempt failed.
     * @type {boolean}
     * @memberof UserOutput
     */
    'payment_failed': boolean;
    /**
     * An array of extra roles of the user.
     * @type {Array<string>}
     * @memberof UserOutput
     */
    'roles': Array<string>;
    /**
     * User member ID.
     * @type {string}
     * @memberof UserOutput
     */
    'member_id': string;
    /**
     * User travel code.
     * @type {string}
     * @memberof UserOutput
     */
    'travel_code': string;
    /**
     * Username of the user. Email or auto generated string (first name + last name + random digits) for household member that are not above 13 y.o.
     * @type {string}
     * @memberof UserOutput
     */
    'username': string;
    /**
     * The flag indicating whether the user has purchased a subscription.
     * @type {boolean}
     * @memberof UserOutput
     */
    'subscription_purchased': boolean;
    /**
     * The time when the user has purchased a subscription.
     * @type {number}
     * @memberof UserOutput
     */
    'subscription_purchased_time': number;
    /**
     * The flag indicating whether the user has purchased a travel protection product.
     * @type {boolean}
     * @memberof UserOutput
     */
    'tp_purchased': boolean;
    /**
     * The time when the user has purchased a travel protection product.
     * @type {number}
     * @memberof UserOutput
     */
    'tp_purchased_time': number;
    /**
     * The time when the user has purchased a subscription extension product.
     * @type {number}
     * @memberof UserOutput
     */
    'pe_purchased_time': number;
    /**
     * The flag indicating whether the user has used a trial subscription.
     * @type {boolean}
     * @memberof UserOutput
     */
    'trial_used': boolean;
    /**
     * 
     * @type {UserOutputContactPreferences}
     * @memberof UserOutput
     */
    'contact_preferences': UserOutputContactPreferences;
    /**
     * The date the user was created.
     * @type {number}
     * @memberof UserOutput
     */
    'created': number;
    /**
     * The date the user was last updated.
     * @type {number}
     * @memberof UserOutput
     */
    'updated': number;
    /**
     * 
     * @type {UserOutputSegment}
     * @memberof UserOutput
     */
    'segment': UserOutputSegment;
    /**
     * 
     * @type {UserOutputMarketing}
     * @memberof UserOutput
     */
    'marketing': UserOutputMarketing;
    /**
     * 
     * @type {UserOutputVerified}
     * @memberof UserOutput
     */
    'verified': UserOutputVerified;
}

export const UserOutputAffinityEnum = {
    Veteran: 'veteran',
    Relative: 'relative',
    Not: 'not',
    Commissioned: 'commissioned',
    ActiveDuty: 'active_duty',
    Academy: 'academy'
} as const;

export type UserOutputAffinityEnum = typeof UserOutputAffinityEnum[keyof typeof UserOutputAffinityEnum];
export const UserOutputGenderEnum = {
    Male: 'male',
    Female: 'female',
    Other: 'other'
} as const;

export type UserOutputGenderEnum = typeof UserOutputGenderEnum[keyof typeof UserOutputGenderEnum];
export const UserOutputHonorificEnum = {
    Dr: 'dr',
    Miss: 'miss',
    Mr: 'mr',
    Mrs: 'mrs',
    Ms: 'ms',
    Mstr: 'mstr',
    Prof: 'prof',
    Rev: 'rev',
    Sir: 'sir',
    Sr: 'sr'
} as const;

export type UserOutputHonorificEnum = typeof UserOutputHonorificEnum[keyof typeof UserOutputHonorificEnum];
export const UserOutputTierEnum = {
    VaUnconfirmed: 'va_unconfirmed',
    VaSupporter: 'va_supporter',
    VaMember: 'va_member',
    VaFamily: 'va_family',
    VaActiveDuty: 'va_active_duty',
    VrMember: 'vr_member',
    VrMemberD: 'vr_member_d',
    VrMemberDw: 'vr_member_dw',
    VrFamily: 'vr_family',
    VrFamilyD: 'vr_family_d',
    VrFamilyDw: 'vr_family_dw',
    VrActiveDuty: 'vr_active_duty',
    VrHousehold: 'vr_household'
} as const;

export type UserOutputTierEnum = typeof UserOutputTierEnum[keyof typeof UserOutputTierEnum];

/**
 * 
 * @export
 * @interface UserOutputActiveDuty
 */
export interface UserOutputActiveDuty {
    /**
     * 
     * @type {number}
     * @memberof UserOutputActiveDuty
     */
    'verified_on'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserOutputActiveDuty
     */
    'address'?: string;
}
/**
 * User billing info.
 * @export
 * @interface UserOutputBilling
 */
export interface UserOutputBilling {
    /**
     * 
     * @type {UserOutputBillingInfoAddress}
     * @memberof UserOutputBilling
     */
    'address': UserOutputBillingInfoAddress | null;
    /**
     * User billing phone.
     * @type {string}
     * @memberof UserOutputBilling
     */
    'phone': string | null;
}
/**
 * 
 * @export
 * @interface UserOutputBillingInfo
 */
export interface UserOutputBillingInfo {
    /**
     * 
     * @type {UserOutputBillingInfoAddress}
     * @memberof UserOutputBillingInfo
     */
    'address': UserOutputBillingInfoAddress | null;
    /**
     * User billing phone.
     * @type {string}
     * @memberof UserOutputBillingInfo
     */
    'phone': string | null;
}
/**
 * User billing address.
 * @export
 * @interface UserOutputBillingInfoAddress
 */
export interface UserOutputBillingInfoAddress {
    /**
     * Address type.
     * @type {string}
     * @memberof UserOutputBillingInfoAddress
     */
    'type': UserOutputBillingInfoAddressTypeEnum;
    /**
     * Street address.
     * @type {string}
     * @memberof UserOutputBillingInfoAddress
     */
    'line1': string;
    /**
     * Apartment, suite, box number, etc.
     * @type {string}
     * @memberof UserOutputBillingInfoAddress
     */
    'line2': string | null;
    /**
     * Postal code, ZIP (or ZIP+4) code.
     * @type {string}
     * @memberof UserOutputBillingInfoAddress
     */
    'postal_code': string;
    /**
     * Depending on address type: - home: city or town; - post: \"APO\", \"FPO\", or \"DPO\".
     * @type {string}
     * @memberof UserOutputBillingInfoAddress
     */
    'city': string;
    /**
     * State, province, region (ISO code when available).
     * @type {string}
     * @memberof UserOutputBillingInfoAddress
     */
    'state': string;
    /**
     * Country (2 character ISO code).
     * @type {string}
     * @memberof UserOutputBillingInfoAddress
     */
    'country': string;
}

export const UserOutputBillingInfoAddressTypeEnum = {
    Home: 'home',
    Post: 'post'
} as const;

export type UserOutputBillingInfoAddressTypeEnum = typeof UserOutputBillingInfoAddressTypeEnum[keyof typeof UserOutputBillingInfoAddressTypeEnum];

/**
 * User contact preferences info.
 * @export
 * @interface UserOutputContactPreferences
 */
export interface UserOutputContactPreferences {
    /**
     * News mailing list subscription flag.
     * @type {boolean}
     * @memberof UserOutputContactPreferences
     */
    'news': boolean;
}
/**
 * Household member info. Not null if this user is a household member.
 * @export
 * @interface UserOutputHouseholdMember
 */
export interface UserOutputHouseholdMember {
    /**
     * Household member relationship to a primary user.
     * @type {string}
     * @memberof UserOutputHouseholdMember
     */
    'relationship': UserOutputHouseholdMemberRelationshipEnum;
    /**
     * Whether the household member is above 13 y.o.
     * @type {boolean}
     * @memberof UserOutputHouseholdMember
     */
    'above_13': boolean;
    /**
     * Primary user ID.
     * @type {string}
     * @memberof UserOutputHouseholdMember
     */
    'primary': string;
    /**
     * The time when the welcome email has been sent.
     * @type {number}
     * @memberof UserOutputHouseholdMember
     */
    'invite_sent': number;
}

export const UserOutputHouseholdMemberRelationshipEnum = {
    Spouse: 'spouse',
    Father: 'father',
    Mother: 'mother',
    Child: 'child',
    Brother: 'brother',
    Sister: 'sister'
} as const;

export type UserOutputHouseholdMemberRelationshipEnum = typeof UserOutputHouseholdMemberRelationshipEnum[keyof typeof UserOutputHouseholdMemberRelationshipEnum];

/**
 * User household members.
 * @export
 * @interface UserOutputHouseholdMembers
 */
export interface UserOutputHouseholdMembers {
    /**
     * Active household member IDs.
     * @type {Set<string>}
     * @memberof UserOutputHouseholdMembers
     */
    'active': Set<string>;
    /**
     * Inactive household member IDs.
     * @type {Set<string>}
     * @memberof UserOutputHouseholdMembers
     */
    'inactive': Set<string>;
}
/**
 * 
 * @export
 * @interface UserOutputHouseholdMembersInfo
 */
export interface UserOutputHouseholdMembersInfo {
    /**
     * Active household member IDs.
     * @type {Set<string>}
     * @memberof UserOutputHouseholdMembersInfo
     */
    'active': Set<string>;
    /**
     * Inactive household member IDs.
     * @type {Set<string>}
     * @memberof UserOutputHouseholdMembersInfo
     */
    'inactive': Set<string>;
}
/**
 * Identification info.
 * @export
 * @interface UserOutputIdentification
 */
export interface UserOutputIdentification {
    /**
     * 
     * @type {IdentificationOutputIdentity}
     * @memberof UserOutputIdentification
     */
    'identity': IdentificationOutputIdentity;
    /**
     * 
     * @type {IdentificationOutputMilitary}
     * @memberof UserOutputIdentification
     */
    'military': IdentificationOutputMilitary;
}
/**
 * 
 * @export
 * @interface UserOutputMarketing
 */
export interface UserOutputMarketing {
    /**
     * 
     * @type {string}
     * @memberof UserOutputMarketing
     */
    'utm_source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserOutputMarketing
     */
    'utm_medium'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserOutputMarketing
     */
    'utm_campaign'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserOutputMarketing
     */
    'admitad_uid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserOutputMarketing
     */
    'changed'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserOutputMarketing
     */
    'order_id'?: string | null;
}
/**
 * User military info.
 * @export
 * @interface UserOutputMilitary
 */
export interface UserOutputMilitary {
    /**
     * User military branch ID.
     * @type {string}
     * @memberof UserOutputMilitary
     */
    'branch': string | null;
    /**
     * User military era ID.
     * @type {string}
     * @memberof UserOutputMilitary
     */
    'era': string | null;
    /**
     * User military rank ID.
     * @type {string}
     * @memberof UserOutputMilitary
     */
    'rank': string | null;
}
/**
 * User multi factor authentication.
 * @export
 * @interface UserOutputMultiFactorAuth
 */
export interface UserOutputMultiFactorAuth {
    /**
     * Flag is two factor authentication are enabled.
     * @type {boolean}
     * @memberof UserOutputMultiFactorAuth
     */
    'enabled': boolean;
    /**
     * Secret code for two factor authentication.
     * @type {string}
     * @memberof UserOutputMultiFactorAuth
     */
    'secret': string;
    /**
     * Date of adding secret key for the user.
     * @type {number}
     * @memberof UserOutputMultiFactorAuth
     */
    'createdAt': number;
}
/**
 * Profile photo info.
 * @export
 * @interface UserOutputProfilePhoto
 */
export interface UserOutputProfilePhoto {
    /**
     * Access token.
     * @type {string}
     * @memberof UserOutputProfilePhoto
     */
    'token': string;
    /**
     * 
     * @type {UserProfilePhotoOutputOptions}
     * @memberof UserOutputProfilePhoto
     */
    'options': UserProfilePhotoOutputOptions;
}
/**
 * User promotions info.
 * @export
 * @interface UserOutputPromotion
 */
export interface UserOutputPromotion {
    /**
     * 
     * @type {UserOutputPromotionsInfoEnroll}
     * @memberof UserOutputPromotion
     */
    'enroll': UserOutputPromotionsInfoEnroll | null;
    /**
     * 
     * @type {UserOutputPromotionsInfoRenew}
     * @memberof UserOutputPromotion
     */
    'renew': UserOutputPromotionsInfoRenew | null;
}
/**
 * 
 * @export
 * @interface UserOutputPromotionInfo
 */
export interface UserOutputPromotionInfo {
    /**
     * Promotion ID.
     * @type {string}
     * @memberof UserOutputPromotionInfo
     */
    'id': string;
    /**
     * The date promotion was set in milliseconds.
     * @type {number}
     * @memberof UserOutputPromotionInfo
     */
    'set_on': number | null;
}
/**
 * 
 * @export
 * @interface UserOutputPromotionsInfo
 */
export interface UserOutputPromotionsInfo {
    /**
     * 
     * @type {UserOutputPromotionsInfoEnroll}
     * @memberof UserOutputPromotionsInfo
     */
    'enroll': UserOutputPromotionsInfoEnroll | null;
    /**
     * 
     * @type {UserOutputPromotionsInfoRenew}
     * @memberof UserOutputPromotionsInfo
     */
    'renew': UserOutputPromotionsInfoRenew | null;
}
/**
 * Enroll promotion info.
 * @export
 * @interface UserOutputPromotionsInfoEnroll
 */
export interface UserOutputPromotionsInfoEnroll {
    /**
     * Promotion ID.
     * @type {string}
     * @memberof UserOutputPromotionsInfoEnroll
     */
    'id': string;
    /**
     * The date promotion was set in milliseconds.
     * @type {number}
     * @memberof UserOutputPromotionsInfoEnroll
     */
    'set_on': number | null;
}
/**
 * Renew promotion info.
 * @export
 * @interface UserOutputPromotionsInfoRenew
 */
export interface UserOutputPromotionsInfoRenew {
    /**
     * Promotion ID.
     * @type {string}
     * @memberof UserOutputPromotionsInfoRenew
     */
    'id': string;
    /**
     * The date promotion was set in milliseconds.
     * @type {number}
     * @memberof UserOutputPromotionsInfoRenew
     */
    'set_on': number | null;
}
/**
 * 
 * @export
 * @interface UserOutputReplacementCard
 */
export interface UserOutputReplacementCard {
    /**
     * 
     * @type {number}
     * @memberof UserOutputReplacementCard
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserOutputReplacementCard
     */
    'date'?: number;
}
/**
 * 
 * @export
 * @interface UserOutputSegment
 */
export interface UserOutputSegment {
    /**
     * 
     * @type {string}
     * @memberof UserOutputSegment
     */
    'fbc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserOutputSegment
     */
    'fbp'?: string | null;
}
/**
 * User subscription info. Required for premium tier and active household users.
 * @export
 * @interface UserOutputSubscription
 */
export interface UserOutputSubscription {
    /**
     * Subscription ID. Null for household members.
     * @type {string}
     * @memberof UserOutputSubscription
     */
    'id': string | null;
    /**
     * The flag indicating whether the subscription autorenew is enabled.
     * @type {boolean}
     * @memberof UserOutputSubscription
     */
    'autorenew': boolean;
    /**
     * The date subscription autorenew was disabled on in milliseconds.
     * @type {number}
     * @memberof UserOutputSubscription
     */
    'autorenew_disabled_on': number | null;
    /**
     * The subscription plan ID.
     * @type {string}
     * @memberof UserOutputSubscription
     */
    'plan': string;
    /**
     * The promotion ID.
     * @type {string}
     * @memberof UserOutputSubscription
     */
    'promotion': string | null;
    /**
     * The subscription extension product IDs.
     * @type {Array<string>}
     * @memberof UserOutputSubscription
     */
    'extensions': Array<string>;
    /**
     * 
     * @type {UserSubscriptionOutputTravelProtection}
     * @memberof UserOutputSubscription
     */
    'travel_protection': UserSubscriptionOutputTravelProtection | null;
    /**
     * The date of subscription expiration in milliseconds.
     * @type {number}
     * @memberof UserOutputSubscription
     */
    'expires': number;
    /**
     * The date subscription is paused until in milliseconds.
     * @type {number}
     * @memberof UserOutputSubscription
     */
    'paused_until': number | null;
    /**
     * The subscription saves number.
     * @type {number}
     * @memberof UserOutputSubscription
     */
    'sub_saves'?: number | null;
    /**
     * Whether the physical card will be provided to the user.
     * @type {boolean}
     * @memberof UserOutputSubscription
     */
    'vetrewards_card_physical': boolean;
}
/**
 * User verification info.
 * @export
 * @interface UserOutputVerification
 */
export interface UserOutputVerification {
    /**
     * User verification status.
     * @type {string}
     * @memberof UserOutputVerification
     */
    'status': UserOutputVerificationStatusEnum;
    /**
     * User email verified flag.
     * @type {boolean}
     * @memberof UserOutputVerification
     */
    'email': boolean;
    /**
     * User phone number verified flag.
     * @type {boolean}
     * @memberof UserOutputVerification
     */
    'phone': boolean;
}

export const UserOutputVerificationStatusEnum = {
    New: 'new',
    Pending: 'pending',
    Active: 'active',
    Proofed: 'proofed',
    Suspended: 'suspended',
    Revoked: 'revoked'
} as const;

export type UserOutputVerificationStatusEnum = typeof UserOutputVerificationStatusEnum[keyof typeof UserOutputVerificationStatusEnum];

/**
 * 
 * @export
 * @interface UserOutputVerificationInfo
 */
export interface UserOutputVerificationInfo {
    /**
     * User verification status.
     * @type {string}
     * @memberof UserOutputVerificationInfo
     */
    'status': UserOutputVerificationInfoStatusEnum;
    /**
     * User email verified flag.
     * @type {boolean}
     * @memberof UserOutputVerificationInfo
     */
    'email': boolean;
    /**
     * User phone number verified flag.
     * @type {boolean}
     * @memberof UserOutputVerificationInfo
     */
    'phone': boolean;
}

export const UserOutputVerificationInfoStatusEnum = {
    New: 'new',
    Pending: 'pending',
    Active: 'active',
    Proofed: 'proofed',
    Suspended: 'suspended',
    Revoked: 'revoked'
} as const;

export type UserOutputVerificationInfoStatusEnum = typeof UserOutputVerificationInfoStatusEnum[keyof typeof UserOutputVerificationInfoStatusEnum];

/**
 * 
 * @export
 * @interface UserOutputVerified
 */
export interface UserOutputVerified {
    /**
     * 
     * @type {boolean}
     * @memberof UserOutputVerified
     */
    'active'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserOutputVerified
     */
    'id'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserOutputVerified
     */
    'manual'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserOutputVerified
     */
    'quiz'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserOutputVerified
     */
    'social'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserOutputVerified
     */
    'status'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UserProfilePhotoOutput
 */
export interface UserProfilePhotoOutput {
    /**
     * Access token.
     * @type {string}
     * @memberof UserProfilePhotoOutput
     */
    'token': string;
    /**
     * 
     * @type {UserProfilePhotoOutputOptions}
     * @memberof UserProfilePhotoOutput
     */
    'options': UserProfilePhotoOutputOptions;
}
/**
 * Original image region extraction options.
 * @export
 * @interface UserProfilePhotoOutputOptions
 */
export interface UserProfilePhotoOutputOptions {
    /**
     * Left offset.
     * @type {number}
     * @memberof UserProfilePhotoOutputOptions
     */
    'left': number;
    /**
     * Top offset.
     * @type {number}
     * @memberof UserProfilePhotoOutputOptions
     */
    'top': number;
    /**
     * Region width.
     * @type {number}
     * @memberof UserProfilePhotoOutputOptions
     */
    'width': number;
    /**
     * Region height.
     * @type {number}
     * @memberof UserProfilePhotoOutputOptions
     */
    'height': number;
}
/**
 * 
 * @export
 * @interface UserSubscriptionOutput
 */
export interface UserSubscriptionOutput {
    /**
     * Subscription ID. Null for household members.
     * @type {string}
     * @memberof UserSubscriptionOutput
     */
    'id': string | null;
    /**
     * The flag indicating whether the subscription autorenew is enabled.
     * @type {boolean}
     * @memberof UserSubscriptionOutput
     */
    'autorenew': boolean;
    /**
     * The date subscription autorenew was disabled on in milliseconds.
     * @type {number}
     * @memberof UserSubscriptionOutput
     */
    'autorenew_disabled_on': number | null;
    /**
     * The subscription plan ID.
     * @type {string}
     * @memberof UserSubscriptionOutput
     */
    'plan': string;
    /**
     * The promotion ID.
     * @type {string}
     * @memberof UserSubscriptionOutput
     */
    'promotion': string | null;
    /**
     * The subscription extension product IDs.
     * @type {Array<string>}
     * @memberof UserSubscriptionOutput
     */
    'extensions': Array<string>;
    /**
     * 
     * @type {UserSubscriptionOutputTravelProtection}
     * @memberof UserSubscriptionOutput
     */
    'travel_protection': UserSubscriptionOutputTravelProtection | null;
    /**
     * The date of subscription expiration in milliseconds.
     * @type {number}
     * @memberof UserSubscriptionOutput
     */
    'expires': number;
    /**
     * The date subscription is paused until in milliseconds.
     * @type {number}
     * @memberof UserSubscriptionOutput
     */
    'paused_until': number | null;
    /**
     * The subscription saves number.
     * @type {number}
     * @memberof UserSubscriptionOutput
     */
    'sub_saves'?: number | null;
    /**
     * Whether the physical card will be provided to the user.
     * @type {boolean}
     * @memberof UserSubscriptionOutput
     */
    'vetrewards_card_physical': boolean;
}
/**
 * The subscription travel protection product info.
 * @export
 * @interface UserSubscriptionOutputTravelProtection
 */
export interface UserSubscriptionOutputTravelProtection {
    /**
     * Travel protection product ID.
     * @type {string}
     * @memberof UserSubscriptionOutputTravelProtection
     */
    'id': string;
    /**
     * Travel protection autorenew.
     * @type {boolean}
     * @memberof UserSubscriptionOutputTravelProtection
     */
    'autorenew': boolean;
    /**
     * The date travel protection autorenew was disabled on in milliseconds.
     * @type {number}
     * @memberof UserSubscriptionOutputTravelProtection
     */
    'autorenew_disabled_on': number | null;
    /**
     * The date of travel protection expiration in milliseconds.
     * @type {number}
     * @memberof UserSubscriptionOutputTravelProtection
     */
    'expires': number;
}
/**
 * 
 * @export
 * @interface Users
 */
export interface Users {
    /**
     * User id.
     * @type {string}
     * @memberof Users
     */
    'id': string;
    /**
     * User first name.
     * @type {string}
     * @memberof Users
     */
    'first_name': string;
    /**
     * User last name.
     * @type {string}
     * @memberof Users
     */
    'last_name': string;
    /**
     * User member ID.
     * @type {string}
     * @memberof Users
     */
    'member_id': string;
    /**
     * User replacement card count.
     * @type {number}
     * @memberof Users
     */
    'count': number;
    /**
     * Profile photo API path.
     * @type {string}
     * @memberof Users
     */
    'profile_photo': string | null;
}
/**
 * 
 * @export
 * @interface Validate200Response
 */
export interface Validate200Response {
    /**
     * Action status.
     * @type {string}
     * @memberof Validate200Response
     */
    'status': Validate200ResponseStatusEnum;
    /**
     * 
     * @type {Validate200ResponseAllOfData}
     * @memberof Validate200Response
     */
    'data': Validate200ResponseAllOfData;
    /**
     * Action warnings.
     * @type {Array<string>}
     * @memberof Validate200Response
     */
    'warnings'?: Array<string>;
}

export const Validate200ResponseStatusEnum = {
    Success: 'success',
    Error: 'error'
} as const;

export type Validate200ResponseStatusEnum = typeof Validate200ResponseStatusEnum[keyof typeof Validate200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface Validate200ResponseAllOf
 */
export interface Validate200ResponseAllOf {
    /**
     * 
     * @type {Validate200ResponseAllOfData}
     * @memberof Validate200ResponseAllOf
     */
    'data'?: Validate200ResponseAllOfData;
}
/**
 * 
 * @export
 * @interface Validate200ResponseAllOfData
 */
export interface Validate200ResponseAllOfData {
    /**
     * 
     * @type {ValidateOutputAddress}
     * @memberof Validate200ResponseAllOfData
     */
    'address': ValidateOutputAddress | null;
    /**
     * Validated email address.
     * @type {string}
     * @memberof Validate200ResponseAllOfData
     */
    'email': string | null;
    /**
     * Validated phone number.
     * @type {string}
     * @memberof Validate200ResponseAllOfData
     */
    'phone': string | null;
}
/**
 * 
 * @export
 * @interface ValidateInput
 */
export interface ValidateInput {
    /**
     * 
     * @type {ValidateInputAddress}
     * @memberof ValidateInput
     */
    'address'?: ValidateInputAddress | null;
    /**
     * Email address to validate.
     * @type {string}
     * @memberof ValidateInput
     */
    'email'?: string | null;
    /**
     * Phone number to validate.
     * @type {string}
     * @memberof ValidateInput
     */
    'phone'?: string | null;
}
/**
 * Physical address to validate.
 * @export
 * @interface ValidateInputAddress
 */
export interface ValidateInputAddress {
    /**
     * Address type.
     * @type {string}
     * @memberof ValidateInputAddress
     */
    'type': ValidateInputAddressTypeEnum;
    /**
     * Street address.
     * @type {string}
     * @memberof ValidateInputAddress
     */
    'line1': string;
    /**
     * Apartment, suite, box number, etc.
     * @type {string}
     * @memberof ValidateInputAddress
     */
    'line2'?: string | null;
    /**
     * Postal code, ZIP (or ZIP+4) code.
     * @type {string}
     * @memberof ValidateInputAddress
     */
    'postal_code': string;
    /**
     * Depending on address type: - home: city or town; - post: \"APO\", \"FPO\", or \"DPO\".
     * @type {string}
     * @memberof ValidateInputAddress
     */
    'city': string;
    /**
     * State, province, region (ISO code when available).
     * @type {string}
     * @memberof ValidateInputAddress
     */
    'state': string;
    /**
     * Country (2 character ISO code).
     * @type {string}
     * @memberof ValidateInputAddress
     */
    'country'?: string | null;
}

export const ValidateInputAddressTypeEnum = {
    Home: 'home',
    Post: 'post'
} as const;

export type ValidateInputAddressTypeEnum = typeof ValidateInputAddressTypeEnum[keyof typeof ValidateInputAddressTypeEnum];

/**
 * 
 * @export
 * @interface ValidateOutput
 */
export interface ValidateOutput {
    /**
     * 
     * @type {ValidateOutputAddress}
     * @memberof ValidateOutput
     */
    'address': ValidateOutputAddress | null;
    /**
     * Validated email address.
     * @type {string}
     * @memberof ValidateOutput
     */
    'email': string | null;
    /**
     * Validated phone number.
     * @type {string}
     * @memberof ValidateOutput
     */
    'phone': string | null;
}
/**
 * Validated physical address.
 * @export
 * @interface ValidateOutputAddress
 */
export interface ValidateOutputAddress {
    /**
     * Address type.
     * @type {string}
     * @memberof ValidateOutputAddress
     */
    'type': ValidateOutputAddressTypeEnum;
    /**
     * Street address.
     * @type {string}
     * @memberof ValidateOutputAddress
     */
    'line1': string;
    /**
     * Apartment, suite, box number, etc.
     * @type {string}
     * @memberof ValidateOutputAddress
     */
    'line2': string | null;
    /**
     * Postal code, ZIP (or ZIP+4) code.
     * @type {string}
     * @memberof ValidateOutputAddress
     */
    'postal_code': string;
    /**
     * Depending on address type: - home: city or town; - post: \"APO\", \"FPO\", or \"DPO\".
     * @type {string}
     * @memberof ValidateOutputAddress
     */
    'city': string;
    /**
     * State, province, region (ISO code when available).
     * @type {string}
     * @memberof ValidateOutputAddress
     */
    'state': string;
    /**
     * Country (2 character ISO code).
     * @type {string}
     * @memberof ValidateOutputAddress
     */
    'country': string;
}

export const ValidateOutputAddressTypeEnum = {
    Home: 'home',
    Post: 'post'
} as const;

export type ValidateOutputAddressTypeEnum = typeof ValidateOutputAddressTypeEnum[keyof typeof ValidateOutputAddressTypeEnum];


/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Member delete endpoint.
         * @summary 
         * @param {string} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (force: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'force' is not null or undefined
            assertParamExists('_delete', 'force', force)
            const localVarPath = `/member/delete/{uid}`
                .replace(`{${"force"}}`, encodeURIComponent(String(force)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member delete endpoint.
         * @summary 
         * @param {string} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete_1: async (force: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'force' is not null or undefined
            assertParamExists('_delete_1', 'force', force)
            const localVarPath = `/member/delete/{uid}/{force}`
                .replace(`{${"force"}}`, encodeURIComponent(String(force)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member charge refund endpoint.
         * @summary 
         * @param {string} charge 
         * @param {MemberChargeRefundInput} memberChargeRefundInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargeRefund: async (charge: string, memberChargeRefundInput: MemberChargeRefundInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'charge' is not null or undefined
            assertParamExists('chargeRefund', 'charge', charge)
            // verify required parameter 'memberChargeRefundInput' is not null or undefined
            assertParamExists('chargeRefund', 'memberChargeRefundInput', memberChargeRefundInput)
            const localVarPath = `/member/charges/{charge}/refund`
                .replace(`{${"charge"}}`, encodeURIComponent(String(charge)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberChargeRefundInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member Sendgrid emails info endpoint.
         * @summary 
         * @param {string} uid User ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailsInfo: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('emailsInfo', 'uid', uid)
            const localVarPath = `/member/{uid}/emails`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member invoice info endpoint.
         * @summary 
         * @param {string} invoice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInfo: async (invoice: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoice' is not null or undefined
            assertParamExists('invoiceInfo', 'invoice', invoice)
            const localVarPath = `/member/invoices/{invoice}`
                .replace(`{${"invoice"}}`, encodeURIComponent(String(invoice)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Masquerade auth token endpoint.
         * @summary 
         * @param {MasqueradeApplyDataInput} masqueradeApplyDataInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masquerade: async (masqueradeApplyDataInput: MasqueradeApplyDataInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'masqueradeApplyDataInput' is not null or undefined
            assertParamExists('masquerade', 'masqueradeApplyDataInput', masqueradeApplyDataInput)
            const localVarPath = `/admin/masquerade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(masqueradeApplyDataInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member admin roles set endpoint.
         * @summary 
         * @param {MemberAdminRolesSetInput} memberAdminRolesSetInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAdminRolesSet: async (memberAdminRolesSetInput: MemberAdminRolesSetInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberAdminRolesSetInput' is not null or undefined
            assertParamExists('memberAdminRolesSet', 'memberAdminRolesSetInput', memberAdminRolesSetInput)
            const localVarPath = `/member/admin-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAdminRolesSetInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets member\'s email.
         * @summary 
         * @param {MemberAuthEmailSetInput} memberAuthEmailSetInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthEmailSet: async (memberAuthEmailSetInput: MemberAuthEmailSetInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberAuthEmailSetInput' is not null or undefined
            assertParamExists('memberAuthEmailSet', 'memberAuthEmailSetInput', memberAuthEmailSetInput)
            const localVarPath = `/member/auth/email/set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAuthEmailSetInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member info endpoint.
         * @summary 
         * @param {string} uid User ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberInfo: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('memberInfo', 'uid', uid)
            const localVarPath = `/members/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member profile update endpoint.
         * @summary 
         * @param {string} uid User ID.
         * @param {MemberProfileInput} memberProfileInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberProfileUpdate: async (uid: string, memberProfileInput: MemberProfileInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('memberProfileUpdate', 'uid', uid)
            // verify required parameter 'memberProfileInput' is not null or undefined
            assertParamExists('memberProfileUpdate', 'memberProfileInput', memberProfileInput)
            const localVarPath = `/member/{uid}/profile`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberProfileInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of members.
         * @summary 
         * @param {number} [limit] Number of items to return.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {any} [value] Field value to filter with.
         * @param {any} [operation] One of these &lt;, &lt;&#x3D;, &#x3D;&#x3D;, !&#x3D;, &gt;&#x3D;, &gt;, array-contains, in, not-in, array-contains-any.
         * @param {any} [direction] Sort direction either desc or asc.
         * @param {any} [field] The field name filtered or sorted on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        membersList: async (limit?: number, before?: any, after?: any, value?: any, operation?: any, direction?: any, field?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }

            if (operation !== undefined) {
                localVarQueryParameter['operation'] = operation;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Masquerade auth token endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmasquerade: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/unmasquerade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * Member delete endpoint.
         * @summary 
         * @param {string} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(force: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Delete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member delete endpoint.
         * @summary 
         * @param {string} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete_1(force: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Delete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete_1(force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member charge refund endpoint.
         * @summary 
         * @param {string} charge 
         * @param {MemberChargeRefundInput} memberChargeRefundInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chargeRefund(charge: string, memberChargeRefundInput: MemberChargeRefundInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chargeRefund(charge, memberChargeRefundInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member Sendgrid emails info endpoint.
         * @summary 
         * @param {string} uid User ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailsInfo(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailsInfo200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailsInfo(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member invoice info endpoint.
         * @summary 
         * @param {string} invoice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoiceInfo(invoice: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceInfo200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoiceInfo(invoice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Masquerade auth token endpoint.
         * @summary 
         * @param {MasqueradeApplyDataInput} masqueradeApplyDataInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async masquerade(masqueradeApplyDataInput: MasqueradeApplyDataInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.masquerade(masqueradeApplyDataInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member admin roles set endpoint.
         * @summary 
         * @param {MemberAdminRolesSetInput} memberAdminRolesSetInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAdminRolesSet(memberAdminRolesSetInput: MemberAdminRolesSetInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAdminRolesSet(memberAdminRolesSetInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sets member\'s email.
         * @summary 
         * @param {MemberAuthEmailSetInput} memberAuthEmailSetInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAuthEmailSet(memberAuthEmailSetInput: MemberAuthEmailSetInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAuthEmailSet(memberAuthEmailSetInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member info endpoint.
         * @summary 
         * @param {string} uid User ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberInfo(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberInfo200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberInfo(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member profile update endpoint.
         * @summary 
         * @param {string} uid User ID.
         * @param {MemberProfileInput} memberProfileInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberProfileUpdate(uid: string, memberProfileInput: MemberProfileInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberProfileUpdate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberProfileUpdate(uid, memberProfileInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of members.
         * @summary 
         * @param {number} [limit] Number of items to return.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {any} [value] Field value to filter with.
         * @param {any} [operation] One of these &lt;, &lt;&#x3D;, &#x3D;&#x3D;, !&#x3D;, &gt;&#x3D;, &gt;, array-contains, in, not-in, array-contains-any.
         * @param {any} [direction] Sort direction either desc or asc.
         * @param {any} [field] The field name filtered or sorted on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async membersList(limit?: number, before?: any, after?: any, value?: any, operation?: any, direction?: any, field?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembersList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.membersList(limit, before, after, value, operation, direction, field, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Masquerade auth token endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unmasquerade(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unmasquerade(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * Member delete endpoint.
         * @summary 
         * @param {string} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(force: string, options?: any): AxiosPromise<Delete200Response> {
            return localVarFp._delete(force, options).then((request) => request(axios, basePath));
        },
        /**
         * Member delete endpoint.
         * @summary 
         * @param {string} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete_1(force: string, options?: any): AxiosPromise<Delete200Response> {
            return localVarFp._delete_1(force, options).then((request) => request(axios, basePath));
        },
        /**
         * Member charge refund endpoint.
         * @summary 
         * @param {string} charge 
         * @param {MemberChargeRefundInput} memberChargeRefundInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargeRefund(charge: string, memberChargeRefundInput: MemberChargeRefundInput, options?: any): AxiosPromise<void> {
            return localVarFp.chargeRefund(charge, memberChargeRefundInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member Sendgrid emails info endpoint.
         * @summary 
         * @param {string} uid User ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailsInfo(uid: string, options?: any): AxiosPromise<EmailsInfo200Response> {
            return localVarFp.emailsInfo(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * Member invoice info endpoint.
         * @summary 
         * @param {string} invoice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceInfo(invoice: string, options?: any): AxiosPromise<InvoiceInfo200Response> {
            return localVarFp.invoiceInfo(invoice, options).then((request) => request(axios, basePath));
        },
        /**
         * Masquerade auth token endpoint.
         * @summary 
         * @param {MasqueradeApplyDataInput} masqueradeApplyDataInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masquerade(masqueradeApplyDataInput: MasqueradeApplyDataInput, options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.masquerade(masqueradeApplyDataInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member admin roles set endpoint.
         * @summary 
         * @param {MemberAdminRolesSetInput} memberAdminRolesSetInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAdminRolesSet(memberAdminRolesSetInput: MemberAdminRolesSetInput, options?: any): AxiosPromise<void> {
            return localVarFp.memberAdminRolesSet(memberAdminRolesSetInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Sets member\'s email.
         * @summary 
         * @param {MemberAuthEmailSetInput} memberAuthEmailSetInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthEmailSet(memberAuthEmailSetInput: MemberAuthEmailSetInput, options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.memberAuthEmailSet(memberAuthEmailSetInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member info endpoint.
         * @summary 
         * @param {string} uid User ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberInfo(uid: string, options?: any): AxiosPromise<MemberInfo200Response> {
            return localVarFp.memberInfo(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * Member profile update endpoint.
         * @summary 
         * @param {string} uid User ID.
         * @param {MemberProfileInput} memberProfileInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberProfileUpdate(uid: string, memberProfileInput: MemberProfileInput, options?: any): AxiosPromise<MemberProfileUpdate200Response> {
            return localVarFp.memberProfileUpdate(uid, memberProfileInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of members.
         * @summary 
         * @param {number} [limit] Number of items to return.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {any} [value] Field value to filter with.
         * @param {any} [operation] One of these &lt;, &lt;&#x3D;, &#x3D;&#x3D;, !&#x3D;, &gt;&#x3D;, &gt;, array-contains, in, not-in, array-contains-any.
         * @param {any} [direction] Sort direction either desc or asc.
         * @param {any} [field] The field name filtered or sorted on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        membersList(limit?: number, before?: any, after?: any, value?: any, operation?: any, direction?: any, field?: any, options?: any): AxiosPromise<MembersList200Response> {
            return localVarFp.membersList(limit, before, after, value, operation, direction, field, options).then((request) => request(axios, basePath));
        },
        /**
         * Masquerade auth token endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmasquerade(options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.unmasquerade(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * Member delete endpoint.
     * @summary 
     * @param {string} force 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public _delete(force: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)._delete(force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member delete endpoint.
     * @summary 
     * @param {string} force 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public _delete_1(force: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)._delete_1(force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member charge refund endpoint.
     * @summary 
     * @param {string} charge 
     * @param {MemberChargeRefundInput} memberChargeRefundInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public chargeRefund(charge: string, memberChargeRefundInput: MemberChargeRefundInput, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).chargeRefund(charge, memberChargeRefundInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member Sendgrid emails info endpoint.
     * @summary 
     * @param {string} uid User ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public emailsInfo(uid: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).emailsInfo(uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member invoice info endpoint.
     * @summary 
     * @param {string} invoice 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public invoiceInfo(invoice: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).invoiceInfo(invoice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Masquerade auth token endpoint.
     * @summary 
     * @param {MasqueradeApplyDataInput} masqueradeApplyDataInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public masquerade(masqueradeApplyDataInput: MasqueradeApplyDataInput, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).masquerade(masqueradeApplyDataInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member admin roles set endpoint.
     * @summary 
     * @param {MemberAdminRolesSetInput} memberAdminRolesSetInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public memberAdminRolesSet(memberAdminRolesSetInput: MemberAdminRolesSetInput, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).memberAdminRolesSet(memberAdminRolesSetInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sets member\'s email.
     * @summary 
     * @param {MemberAuthEmailSetInput} memberAuthEmailSetInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public memberAuthEmailSet(memberAuthEmailSetInput: MemberAuthEmailSetInput, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).memberAuthEmailSet(memberAuthEmailSetInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member info endpoint.
     * @summary 
     * @param {string} uid User ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public memberInfo(uid: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).memberInfo(uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member profile update endpoint.
     * @summary 
     * @param {string} uid User ID.
     * @param {MemberProfileInput} memberProfileInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public memberProfileUpdate(uid: string, memberProfileInput: MemberProfileInput, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).memberProfileUpdate(uid, memberProfileInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of members.
     * @summary 
     * @param {number} [limit] Number of items to return.
     * @param {any} [before] ID of the item to return items before.
     * @param {any} [after] ID of the item to return items after.
     * @param {any} [value] Field value to filter with.
     * @param {any} [operation] One of these &lt;, &lt;&#x3D;, &#x3D;&#x3D;, !&#x3D;, &gt;&#x3D;, &gt;, array-contains, in, not-in, array-contains-any.
     * @param {any} [direction] Sort direction either desc or asc.
     * @param {any} [field] The field name filtered or sorted on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public membersList(limit?: number, before?: any, after?: any, value?: any, operation?: any, direction?: any, field?: any, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).membersList(limit, before, after, value, operation, direction, field, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Masquerade auth token endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public unmasquerade(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).unmasquerade(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Abenity sso token endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoMember: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/abenity/sso`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Kustomer auth token endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        token: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/kustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * Abenity sso token endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoMember(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SsoMember200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoMember(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Kustomer auth token endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async token(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.token(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * Abenity sso token endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoMember(options?: any): AxiosPromise<SsoMember200Response> {
            return localVarFp.ssoMember(options).then((request) => request(axios, basePath));
        },
        /**
         * Kustomer auth token endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        token(options?: any): AxiosPromise<Token200Response> {
            return localVarFp.token(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * Abenity sso token endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public ssoMember(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).ssoMember(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Kustomer auth token endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public token(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).token(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CheckoutApi - axios parameter creator
 * @export
 */
export const CheckoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Card replacement purchase.
         * @summary 
         * @param {CardReplacementPreviewInput} cardReplacementPreviewInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardReplacement: async (cardReplacementPreviewInput: CardReplacementPreviewInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardReplacementPreviewInput' is not null or undefined
            assertParamExists('cardReplacement', 'cardReplacementPreviewInput', cardReplacementPreviewInput)
            const localVarPath = `/member/card-replacement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cardReplacementPreviewInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get card replacement information for the client with households.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardReplacementInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/card-replacement/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Card replacement preview.
         * @summary 
         * @param {CardReplacementPreviewInput} cardReplacementPreviewInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardReplacementPreview: async (cardReplacementPreviewInput: CardReplacementPreviewInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardReplacementPreviewInput' is not null or undefined
            assertParamExists('cardReplacementPreview', 'cardReplacementPreviewInput', cardReplacementPreviewInput)
            const localVarPath = `/member/card-replacement/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cardReplacementPreviewInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends Gift of VetRewards.
         * @summary 
         * @param {ContributionInput} contributionInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contribute: async (contributionInput: ContributionInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contributionInput' is not null or undefined
            assertParamExists('contribute', 'contributionInput', contributionInput)
            const localVarPath = `/contribute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contributionInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member subscription enroll overview endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/subscription/enroll/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member subscription enroll overview endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollInfo_1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/enroll/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enroll preview endpoint.
         * @summary 
         * @param {EnrollInput} enrollInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollPreview: async (enrollInput: EnrollInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enrollInput' is not null or undefined
            assertParamExists('enrollPreview', 'enrollInput', enrollInput)
            const localVarPath = `/enroll/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enrollInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enroll run endpoint.
         * @summary 
         * @param {EnrollInput} enrollInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollRun: async (enrollInput: EnrollInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enrollInput' is not null or undefined
            assertParamExists('enrollRun', 'enrollInput', enrollInput)
            const localVarPath = `/enroll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enrollInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Promote HHM to Primary endpoint.
         * @summary 
         * @param {MemberPromoteInput} memberPromoteInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPromote: async (memberPromoteInput: MemberPromoteInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberPromoteInput' is not null or undefined
            assertParamExists('memberPromote', 'memberPromoteInput', memberPromoteInput)
            const localVarPath = `/member/promote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberPromoteInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Applies given promo to the user.
         * @summary 
         * @param {MemberSubscriptionPromotionApplyInput} memberSubscriptionPromotionApplyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPromotionApply: async (memberSubscriptionPromotionApplyInput: MemberSubscriptionPromotionApplyInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberSubscriptionPromotionApplyInput' is not null or undefined
            assertParamExists('memberPromotionApply', 'memberSubscriptionPromotionApplyInput', memberSubscriptionPromotionApplyInput)
            const localVarPath = `/member/promotion/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberSubscriptionPromotionApplyInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resets given user promo type.
         * @summary 
         * @param {MemberSubscriptionPromotionResetInput} memberSubscriptionPromotionResetInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPromotionReset: async (memberSubscriptionPromotionResetInput: MemberSubscriptionPromotionResetInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberSubscriptionPromotionResetInput' is not null or undefined
            assertParamExists('memberPromotionReset', 'memberSubscriptionPromotionResetInput', memberSubscriptionPromotionResetInput)
            const localVarPath = `/member/promotion/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberSubscriptionPromotionResetInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member subscription addon endpoint.
         * @summary 
         * @param {MemberSubscriptionAddonInput} memberSubscriptionAddonInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionAddon: async (memberSubscriptionAddonInput: MemberSubscriptionAddonInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberSubscriptionAddonInput' is not null or undefined
            assertParamExists('memberSubscriptionAddon', 'memberSubscriptionAddonInput', memberSubscriptionAddonInput)
            const localVarPath = `/member/subscription/addon`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberSubscriptionAddonInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member subscription addon preview endpoint.
         * @summary 
         * @param {MemberSubscriptionAddonPreviewInput} memberSubscriptionAddonPreviewInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionAddonPreview: async (memberSubscriptionAddonPreviewInput: MemberSubscriptionAddonPreviewInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberSubscriptionAddonPreviewInput' is not null or undefined
            assertParamExists('memberSubscriptionAddonPreview', 'memberSubscriptionAddonPreviewInput', memberSubscriptionAddonPreviewInput)
            const localVarPath = `/member/subscription/addon/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberSubscriptionAddonPreviewInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member subscription cancel endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionCancel: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/subscription/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member subscription set expiry date endpoint.
         * @summary 
         * @param {MemberSubscriptionSetExpiresInput} memberSubscriptionSetExpiresInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionExpiryDate: async (memberSubscriptionSetExpiresInput: MemberSubscriptionSetExpiresInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberSubscriptionSetExpiresInput' is not null or undefined
            assertParamExists('memberSubscriptionExpiryDate', 'memberSubscriptionSetExpiresInput', memberSubscriptionSetExpiresInput)
            const localVarPath = `/member/subscription/expiry-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberSubscriptionSetExpiresInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member subscription saves free months endpoint.
         * @summary 
         * @param {MemberSubscriptionFreeInput} memberSubscriptionFreeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionFree: async (memberSubscriptionFreeInput: MemberSubscriptionFreeInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberSubscriptionFreeInput' is not null or undefined
            assertParamExists('memberSubscriptionFree', 'memberSubscriptionFreeInput', memberSubscriptionFreeInput)
            const localVarPath = `/member/subscription/free`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberSubscriptionFreeInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member subscription info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member subscription upgrade endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionPastDueCancel: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/subscription/pastdue-cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member subscription save endpoint.
         * @summary 
         * @param {MemberSubscriptionSaveInput} memberSubscriptionSaveInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionSave: async (memberSubscriptionSaveInput: MemberSubscriptionSaveInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberSubscriptionSaveInput' is not null or undefined
            assertParamExists('memberSubscriptionSave', 'memberSubscriptionSaveInput', memberSubscriptionSaveInput)
            const localVarPath = `/member/subscription/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberSubscriptionSaveInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member subscription set renewal promotion endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionSetPromotion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/subscription/promo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member subscription update endpoint.
         * @summary 
         * @param {MemberSubscriptionUpdateInput} memberSubscriptionUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionUpdate: async (memberSubscriptionUpdateInput: MemberSubscriptionUpdateInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberSubscriptionUpdateInput' is not null or undefined
            assertParamExists('memberSubscriptionUpdate', 'memberSubscriptionUpdateInput', memberSubscriptionUpdateInput)
            const localVarPath = `/member/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberSubscriptionUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member subscription upgrade endpoint.
         * @summary 
         * @param {EnrollInput} enrollInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionUpgrade: async (enrollInput: EnrollInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enrollInput' is not null or undefined
            assertParamExists('memberSubscriptionUpgrade', 'enrollInput', enrollInput)
            const localVarPath = `/member/subscription/upgrade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enrollInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member subscription upgrade overview endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionUpgradeOverview: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/subscription/upgrade/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member subscription upgrade preview endpoint.
         * @summary 
         * @param {EnrollInput} enrollInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionUpgradePreview: async (enrollInput: EnrollInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enrollInput' is not null or undefined
            assertParamExists('memberSubscriptionUpgradePreview', 'enrollInput', enrollInput)
            const localVarPath = `/member/subscription/upgrade/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enrollInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Apply MRP promotion endpoint.
         * @summary 
         * @param {MrpInput} mrpInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mrpPromotionApply: async (mrpInput: MrpInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mrpInput' is not null or undefined
            assertParamExists('mrpPromotionApply', 'mrpInput', mrpInput)
            const localVarPath = `/enroll/mrp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mrpInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Apply promotion endpoint.
         * @summary 
         * @param {PromoInput} promoInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionApply: async (promoInput: PromoInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promoInput' is not null or undefined
            assertParamExists('promotionApply', 'promoInput', promoInput)
            const localVarPath = `/enroll/promo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promoInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of promotions.
         * @summary 
         * @param {string} [type] Promotion type: enroll OR renew.
         * @param {number} [limit] Number of items to return.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsList: async (type?: string, limit?: number, before?: any, after?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Shipping/payment info update endpoint.
         * @summary 
         * @param {ShippingPaymentInfoUpdateInput} shippingPaymentInfoUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingPaymentInfoUpdate: async (shippingPaymentInfoUpdateInput: ShippingPaymentInfoUpdateInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shippingPaymentInfoUpdateInput' is not null or undefined
            assertParamExists('shippingPaymentInfoUpdate', 'shippingPaymentInfoUpdateInput', shippingPaymentInfoUpdateInput)
            const localVarPath = `/enroll/shipping-payment-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shippingPaymentInfoUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Apply upsell promotion endpoint.
         * @summary 
         * @param {UpsellInput} upsellInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellPromotionApply: async (upsellInput: UpsellInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsellInput' is not null or undefined
            assertParamExists('upsellPromotionApply', 'upsellInput', upsellInput)
            const localVarPath = `/enroll/upsell`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsellInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckoutApi - functional programming interface
 * @export
 */
export const CheckoutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckoutApiAxiosParamCreator(configuration)
    return {
        /**
         * Card replacement purchase.
         * @summary 
         * @param {CardReplacementPreviewInput} cardReplacementPreviewInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cardReplacement(cardReplacementPreviewInput: CardReplacementPreviewInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardReplacement200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cardReplacement(cardReplacementPreviewInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get card replacement information for the client with households.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cardReplacementInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardReplacementInfo200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cardReplacementInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Card replacement preview.
         * @summary 
         * @param {CardReplacementPreviewInput} cardReplacementPreviewInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cardReplacementPreview(cardReplacementPreviewInput: CardReplacementPreviewInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardReplacementPreview200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cardReplacementPreview(cardReplacementPreviewInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sends Gift of VetRewards.
         * @summary 
         * @param {ContributionInput} contributionInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contribute(contributionInput: ContributionInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contribute(contributionInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member subscription enroll overview endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enrollInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnrollInfo200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enrollInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member subscription enroll overview endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enrollInfo_1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnrollInfo200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enrollInfo_1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enroll preview endpoint.
         * @summary 
         * @param {EnrollInput} enrollInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enrollPreview(enrollInput: EnrollInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnrollPreview200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enrollPreview(enrollInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enroll run endpoint.
         * @summary 
         * @param {EnrollInput} enrollInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enrollRun(enrollInput: EnrollInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnrollRun200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enrollRun(enrollInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Promote HHM to Primary endpoint.
         * @summary 
         * @param {MemberPromoteInput} memberPromoteInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberPromote(memberPromoteInput: MemberPromoteInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberPromote(memberPromoteInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Applies given promo to the user.
         * @summary 
         * @param {MemberSubscriptionPromotionApplyInput} memberSubscriptionPromotionApplyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberPromotionApply(memberSubscriptionPromotionApplyInput: MemberSubscriptionPromotionApplyInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberPromotionApply(memberSubscriptionPromotionApplyInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Resets given user promo type.
         * @summary 
         * @param {MemberSubscriptionPromotionResetInput} memberSubscriptionPromotionResetInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberPromotionReset(memberSubscriptionPromotionResetInput: MemberSubscriptionPromotionResetInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberPromotionReset(memberSubscriptionPromotionResetInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member subscription addon endpoint.
         * @summary 
         * @param {MemberSubscriptionAddonInput} memberSubscriptionAddonInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberSubscriptionAddon(memberSubscriptionAddonInput: MemberSubscriptionAddonInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSubscriptionFree200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberSubscriptionAddon(memberSubscriptionAddonInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member subscription addon preview endpoint.
         * @summary 
         * @param {MemberSubscriptionAddonPreviewInput} memberSubscriptionAddonPreviewInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberSubscriptionAddonPreview(memberSubscriptionAddonPreviewInput: MemberSubscriptionAddonPreviewInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSubscriptionAddonPreview200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberSubscriptionAddonPreview(memberSubscriptionAddonPreviewInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member subscription cancel endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberSubscriptionCancel(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberSubscriptionCancel(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member subscription set expiry date endpoint.
         * @summary 
         * @param {MemberSubscriptionSetExpiresInput} memberSubscriptionSetExpiresInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberSubscriptionExpiryDate(memberSubscriptionSetExpiresInput: MemberSubscriptionSetExpiresInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberSubscriptionExpiryDate(memberSubscriptionSetExpiresInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member subscription saves free months endpoint.
         * @summary 
         * @param {MemberSubscriptionFreeInput} memberSubscriptionFreeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberSubscriptionFree(memberSubscriptionFreeInput: MemberSubscriptionFreeInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSubscriptionFree200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberSubscriptionFree(memberSubscriptionFreeInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member subscription info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberSubscriptionInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSubscriptionInfo200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberSubscriptionInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member subscription upgrade endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberSubscriptionPastDueCancel(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberSubscriptionPastDueCancel(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member subscription save endpoint.
         * @summary 
         * @param {MemberSubscriptionSaveInput} memberSubscriptionSaveInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberSubscriptionSave(memberSubscriptionSaveInput: MemberSubscriptionSaveInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSubscriptionFree200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberSubscriptionSave(memberSubscriptionSaveInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member subscription set renewal promotion endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberSubscriptionSetPromotion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberSubscriptionSetPromotion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member subscription update endpoint.
         * @summary 
         * @param {MemberSubscriptionUpdateInput} memberSubscriptionUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberSubscriptionUpdate(memberSubscriptionUpdateInput: MemberSubscriptionUpdateInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSubscriptionUpdate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberSubscriptionUpdate(memberSubscriptionUpdateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member subscription upgrade endpoint.
         * @summary 
         * @param {EnrollInput} enrollInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberSubscriptionUpgrade(enrollInput: EnrollInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnrollRun200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberSubscriptionUpgrade(enrollInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member subscription upgrade overview endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberSubscriptionUpgradeOverview(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnrollInfo200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberSubscriptionUpgradeOverview(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member subscription upgrade preview endpoint.
         * @summary 
         * @param {EnrollInput} enrollInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberSubscriptionUpgradePreview(enrollInput: EnrollInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnrollPreview200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberSubscriptionUpgradePreview(enrollInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Apply MRP promotion endpoint.
         * @summary 
         * @param {MrpInput} mrpInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mrpPromotionApply(mrpInput: MrpInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MrpPromotionApply200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mrpPromotionApply(mrpInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Apply promotion endpoint.
         * @summary 
         * @param {PromoInput} promoInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionApply(promoInput: PromoInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionApply200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionApply(promoInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of promotions.
         * @summary 
         * @param {string} [type] Promotion type: enroll OR renew.
         * @param {number} [limit] Number of items to return.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsList(type?: string, limit?: number, before?: any, after?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsList(type, limit, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Shipping/payment info update endpoint.
         * @summary 
         * @param {ShippingPaymentInfoUpdateInput} shippingPaymentInfoUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippingPaymentInfoUpdate(shippingPaymentInfoUpdateInput: ShippingPaymentInfoUpdateInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShippingPaymentInfoUpdate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippingPaymentInfoUpdate(shippingPaymentInfoUpdateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Apply upsell promotion endpoint.
         * @summary 
         * @param {UpsellInput} upsellInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsellPromotionApply(upsellInput: UpsellInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsellPromotionApply200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsellPromotionApply(upsellInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CheckoutApi - factory interface
 * @export
 */
export const CheckoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckoutApiFp(configuration)
    return {
        /**
         * Card replacement purchase.
         * @summary 
         * @param {CardReplacementPreviewInput} cardReplacementPreviewInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardReplacement(cardReplacementPreviewInput: CardReplacementPreviewInput, options?: any): AxiosPromise<CardReplacement200Response> {
            return localVarFp.cardReplacement(cardReplacementPreviewInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Get card replacement information for the client with households.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardReplacementInfo(options?: any): AxiosPromise<CardReplacementInfo200Response> {
            return localVarFp.cardReplacementInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Card replacement preview.
         * @summary 
         * @param {CardReplacementPreviewInput} cardReplacementPreviewInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardReplacementPreview(cardReplacementPreviewInput: CardReplacementPreviewInput, options?: any): AxiosPromise<CardReplacementPreview200Response> {
            return localVarFp.cardReplacementPreview(cardReplacementPreviewInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends Gift of VetRewards.
         * @summary 
         * @param {ContributionInput} contributionInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contribute(contributionInput: ContributionInput, options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.contribute(contributionInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member subscription enroll overview endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollInfo(options?: any): AxiosPromise<EnrollInfo200Response> {
            return localVarFp.enrollInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Member subscription enroll overview endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollInfo_1(options?: any): AxiosPromise<EnrollInfo200Response> {
            return localVarFp.enrollInfo_1(options).then((request) => request(axios, basePath));
        },
        /**
         * Enroll preview endpoint.
         * @summary 
         * @param {EnrollInput} enrollInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollPreview(enrollInput: EnrollInput, options?: any): AxiosPromise<EnrollPreview200Response> {
            return localVarFp.enrollPreview(enrollInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Enroll run endpoint.
         * @summary 
         * @param {EnrollInput} enrollInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollRun(enrollInput: EnrollInput, options?: any): AxiosPromise<EnrollRun200Response> {
            return localVarFp.enrollRun(enrollInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Promote HHM to Primary endpoint.
         * @summary 
         * @param {MemberPromoteInput} memberPromoteInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPromote(memberPromoteInput: MemberPromoteInput, options?: any): AxiosPromise<void> {
            return localVarFp.memberPromote(memberPromoteInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Applies given promo to the user.
         * @summary 
         * @param {MemberSubscriptionPromotionApplyInput} memberSubscriptionPromotionApplyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPromotionApply(memberSubscriptionPromotionApplyInput: MemberSubscriptionPromotionApplyInput, options?: any): AxiosPromise<void> {
            return localVarFp.memberPromotionApply(memberSubscriptionPromotionApplyInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Resets given user promo type.
         * @summary 
         * @param {MemberSubscriptionPromotionResetInput} memberSubscriptionPromotionResetInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPromotionReset(memberSubscriptionPromotionResetInput: MemberSubscriptionPromotionResetInput, options?: any): AxiosPromise<void> {
            return localVarFp.memberPromotionReset(memberSubscriptionPromotionResetInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member subscription addon endpoint.
         * @summary 
         * @param {MemberSubscriptionAddonInput} memberSubscriptionAddonInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionAddon(memberSubscriptionAddonInput: MemberSubscriptionAddonInput, options?: any): AxiosPromise<MemberSubscriptionFree200Response> {
            return localVarFp.memberSubscriptionAddon(memberSubscriptionAddonInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member subscription addon preview endpoint.
         * @summary 
         * @param {MemberSubscriptionAddonPreviewInput} memberSubscriptionAddonPreviewInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionAddonPreview(memberSubscriptionAddonPreviewInput: MemberSubscriptionAddonPreviewInput, options?: any): AxiosPromise<MemberSubscriptionAddonPreview200Response> {
            return localVarFp.memberSubscriptionAddonPreview(memberSubscriptionAddonPreviewInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member subscription cancel endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionCancel(options?: any): AxiosPromise<void> {
            return localVarFp.memberSubscriptionCancel(options).then((request) => request(axios, basePath));
        },
        /**
         * Member subscription set expiry date endpoint.
         * @summary 
         * @param {MemberSubscriptionSetExpiresInput} memberSubscriptionSetExpiresInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionExpiryDate(memberSubscriptionSetExpiresInput: MemberSubscriptionSetExpiresInput, options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.memberSubscriptionExpiryDate(memberSubscriptionSetExpiresInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member subscription saves free months endpoint.
         * @summary 
         * @param {MemberSubscriptionFreeInput} memberSubscriptionFreeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionFree(memberSubscriptionFreeInput: MemberSubscriptionFreeInput, options?: any): AxiosPromise<MemberSubscriptionFree200Response> {
            return localVarFp.memberSubscriptionFree(memberSubscriptionFreeInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member subscription info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionInfo(options?: any): AxiosPromise<MemberSubscriptionInfo200Response> {
            return localVarFp.memberSubscriptionInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Member subscription upgrade endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionPastDueCancel(options?: any): AxiosPromise<void> {
            return localVarFp.memberSubscriptionPastDueCancel(options).then((request) => request(axios, basePath));
        },
        /**
         * Member subscription save endpoint.
         * @summary 
         * @param {MemberSubscriptionSaveInput} memberSubscriptionSaveInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionSave(memberSubscriptionSaveInput: MemberSubscriptionSaveInput, options?: any): AxiosPromise<MemberSubscriptionFree200Response> {
            return localVarFp.memberSubscriptionSave(memberSubscriptionSaveInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member subscription set renewal promotion endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionSetPromotion(options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.memberSubscriptionSetPromotion(options).then((request) => request(axios, basePath));
        },
        /**
         * Member subscription update endpoint.
         * @summary 
         * @param {MemberSubscriptionUpdateInput} memberSubscriptionUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionUpdate(memberSubscriptionUpdateInput: MemberSubscriptionUpdateInput, options?: any): AxiosPromise<MemberSubscriptionUpdate200Response> {
            return localVarFp.memberSubscriptionUpdate(memberSubscriptionUpdateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member subscription upgrade endpoint.
         * @summary 
         * @param {EnrollInput} enrollInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionUpgrade(enrollInput: EnrollInput, options?: any): AxiosPromise<EnrollRun200Response> {
            return localVarFp.memberSubscriptionUpgrade(enrollInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member subscription upgrade overview endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionUpgradeOverview(options?: any): AxiosPromise<EnrollInfo200Response> {
            return localVarFp.memberSubscriptionUpgradeOverview(options).then((request) => request(axios, basePath));
        },
        /**
         * Member subscription upgrade preview endpoint.
         * @summary 
         * @param {EnrollInput} enrollInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSubscriptionUpgradePreview(enrollInput: EnrollInput, options?: any): AxiosPromise<EnrollPreview200Response> {
            return localVarFp.memberSubscriptionUpgradePreview(enrollInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Apply MRP promotion endpoint.
         * @summary 
         * @param {MrpInput} mrpInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mrpPromotionApply(mrpInput: MrpInput, options?: any): AxiosPromise<MrpPromotionApply200Response> {
            return localVarFp.mrpPromotionApply(mrpInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Apply promotion endpoint.
         * @summary 
         * @param {PromoInput} promoInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionApply(promoInput: PromoInput, options?: any): AxiosPromise<PromotionApply200Response> {
            return localVarFp.promotionApply(promoInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of promotions.
         * @summary 
         * @param {string} [type] Promotion type: enroll OR renew.
         * @param {number} [limit] Number of items to return.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsList(type?: string, limit?: number, before?: any, after?: any, options?: any): AxiosPromise<PromotionsList200Response> {
            return localVarFp.promotionsList(type, limit, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * Shipping/payment info update endpoint.
         * @summary 
         * @param {ShippingPaymentInfoUpdateInput} shippingPaymentInfoUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingPaymentInfoUpdate(shippingPaymentInfoUpdateInput: ShippingPaymentInfoUpdateInput, options?: any): AxiosPromise<ShippingPaymentInfoUpdate200Response> {
            return localVarFp.shippingPaymentInfoUpdate(shippingPaymentInfoUpdateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Apply upsell promotion endpoint.
         * @summary 
         * @param {UpsellInput} upsellInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsellPromotionApply(upsellInput: UpsellInput, options?: any): AxiosPromise<UpsellPromotionApply200Response> {
            return localVarFp.upsellPromotionApply(upsellInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CheckoutApi - object-oriented interface
 * @export
 * @class CheckoutApi
 * @extends {BaseAPI}
 */
export class CheckoutApi extends BaseAPI {
    /**
     * Card replacement purchase.
     * @summary 
     * @param {CardReplacementPreviewInput} cardReplacementPreviewInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public cardReplacement(cardReplacementPreviewInput: CardReplacementPreviewInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).cardReplacement(cardReplacementPreviewInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get card replacement information for the client with households.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public cardReplacementInfo(options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).cardReplacementInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Card replacement preview.
     * @summary 
     * @param {CardReplacementPreviewInput} cardReplacementPreviewInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public cardReplacementPreview(cardReplacementPreviewInput: CardReplacementPreviewInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).cardReplacementPreview(cardReplacementPreviewInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends Gift of VetRewards.
     * @summary 
     * @param {ContributionInput} contributionInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public contribute(contributionInput: ContributionInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).contribute(contributionInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member subscription enroll overview endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public enrollInfo(options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).enrollInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member subscription enroll overview endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public enrollInfo_1(options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).enrollInfo_1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enroll preview endpoint.
     * @summary 
     * @param {EnrollInput} enrollInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public enrollPreview(enrollInput: EnrollInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).enrollPreview(enrollInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enroll run endpoint.
     * @summary 
     * @param {EnrollInput} enrollInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public enrollRun(enrollInput: EnrollInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).enrollRun(enrollInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Promote HHM to Primary endpoint.
     * @summary 
     * @param {MemberPromoteInput} memberPromoteInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public memberPromote(memberPromoteInput: MemberPromoteInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).memberPromote(memberPromoteInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Applies given promo to the user.
     * @summary 
     * @param {MemberSubscriptionPromotionApplyInput} memberSubscriptionPromotionApplyInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public memberPromotionApply(memberSubscriptionPromotionApplyInput: MemberSubscriptionPromotionApplyInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).memberPromotionApply(memberSubscriptionPromotionApplyInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resets given user promo type.
     * @summary 
     * @param {MemberSubscriptionPromotionResetInput} memberSubscriptionPromotionResetInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public memberPromotionReset(memberSubscriptionPromotionResetInput: MemberSubscriptionPromotionResetInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).memberPromotionReset(memberSubscriptionPromotionResetInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member subscription addon endpoint.
     * @summary 
     * @param {MemberSubscriptionAddonInput} memberSubscriptionAddonInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public memberSubscriptionAddon(memberSubscriptionAddonInput: MemberSubscriptionAddonInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).memberSubscriptionAddon(memberSubscriptionAddonInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member subscription addon preview endpoint.
     * @summary 
     * @param {MemberSubscriptionAddonPreviewInput} memberSubscriptionAddonPreviewInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public memberSubscriptionAddonPreview(memberSubscriptionAddonPreviewInput: MemberSubscriptionAddonPreviewInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).memberSubscriptionAddonPreview(memberSubscriptionAddonPreviewInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member subscription cancel endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public memberSubscriptionCancel(options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).memberSubscriptionCancel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member subscription set expiry date endpoint.
     * @summary 
     * @param {MemberSubscriptionSetExpiresInput} memberSubscriptionSetExpiresInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public memberSubscriptionExpiryDate(memberSubscriptionSetExpiresInput: MemberSubscriptionSetExpiresInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).memberSubscriptionExpiryDate(memberSubscriptionSetExpiresInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member subscription saves free months endpoint.
     * @summary 
     * @param {MemberSubscriptionFreeInput} memberSubscriptionFreeInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public memberSubscriptionFree(memberSubscriptionFreeInput: MemberSubscriptionFreeInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).memberSubscriptionFree(memberSubscriptionFreeInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member subscription info endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public memberSubscriptionInfo(options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).memberSubscriptionInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member subscription upgrade endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public memberSubscriptionPastDueCancel(options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).memberSubscriptionPastDueCancel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member subscription save endpoint.
     * @summary 
     * @param {MemberSubscriptionSaveInput} memberSubscriptionSaveInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public memberSubscriptionSave(memberSubscriptionSaveInput: MemberSubscriptionSaveInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).memberSubscriptionSave(memberSubscriptionSaveInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member subscription set renewal promotion endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public memberSubscriptionSetPromotion(options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).memberSubscriptionSetPromotion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member subscription update endpoint.
     * @summary 
     * @param {MemberSubscriptionUpdateInput} memberSubscriptionUpdateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public memberSubscriptionUpdate(memberSubscriptionUpdateInput: MemberSubscriptionUpdateInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).memberSubscriptionUpdate(memberSubscriptionUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member subscription upgrade endpoint.
     * @summary 
     * @param {EnrollInput} enrollInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public memberSubscriptionUpgrade(enrollInput: EnrollInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).memberSubscriptionUpgrade(enrollInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member subscription upgrade overview endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public memberSubscriptionUpgradeOverview(options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).memberSubscriptionUpgradeOverview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member subscription upgrade preview endpoint.
     * @summary 
     * @param {EnrollInput} enrollInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public memberSubscriptionUpgradePreview(enrollInput: EnrollInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).memberSubscriptionUpgradePreview(enrollInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Apply MRP promotion endpoint.
     * @summary 
     * @param {MrpInput} mrpInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public mrpPromotionApply(mrpInput: MrpInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).mrpPromotionApply(mrpInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Apply promotion endpoint.
     * @summary 
     * @param {PromoInput} promoInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public promotionApply(promoInput: PromoInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).promotionApply(promoInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of promotions.
     * @summary 
     * @param {string} [type] Promotion type: enroll OR renew.
     * @param {number} [limit] Number of items to return.
     * @param {any} [before] ID of the item to return items before.
     * @param {any} [after] ID of the item to return items after.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public promotionsList(type?: string, limit?: number, before?: any, after?: any, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).promotionsList(type, limit, before, after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Shipping/payment info update endpoint.
     * @summary 
     * @param {ShippingPaymentInfoUpdateInput} shippingPaymentInfoUpdateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public shippingPaymentInfoUpdate(shippingPaymentInfoUpdateInput: ShippingPaymentInfoUpdateInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).shippingPaymentInfoUpdate(shippingPaymentInfoUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Apply upsell promotion endpoint.
     * @summary 
     * @param {UpsellInput} upsellInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public upsellPromotionApply(upsellInput: UpsellInput, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).upsellPromotionApply(upsellInput, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FulfillmentApi - axios parameter creator
 * @export
 */
export const FulfillmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fulfillment records action endpoint.
         * @summary 
         * @param {FulfillmentInput} fulfillmentInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillmentAction: async (fulfillmentInput: FulfillmentInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fulfillmentInput' is not null or undefined
            assertParamExists('fulfillmentAction', 'fulfillmentInput', fulfillmentInput)
            const localVarPath = `/fulfillment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fulfillmentInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fulfillment batch action endpoint.
         * @summary 
         * @param {FulfillmentBatchInput} fulfillmentBatchInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillmentBatchAction: async (fulfillmentBatchInput: FulfillmentBatchInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fulfillmentBatchInput' is not null or undefined
            assertParamExists('fulfillmentBatchAction', 'fulfillmentBatchInput', fulfillmentBatchInput)
            const localVarPath = `/fulfillment-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fulfillmentBatchInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fulfillment batch download as CSV endpoint.
         * @summary 
         * @param {string} batch A valid Fulfilment Batch ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillmentBatchDownload: async (batch: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batch' is not null or undefined
            assertParamExists('fulfillmentBatchDownload', 'batch', batch)
            const localVarPath = `/fulfillment-batch/{batch}`
                .replace(`{${"batch"}}`, encodeURIComponent(String(batch)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of fulfillment batches.
         * @summary 
         * @param {number} [limit] Number of items to return.
         * @param {any} [value] Field value to filter with.
         * @param {any} [operation] One of these &lt;, &lt;&#x3D;, &#x3D;&#x3D;, !&#x3D;, &gt;&#x3D;, &gt;, array-contains, in, not-in, array-contains-any.
         * @param {any} [direction] Sort direction either desc or asc.
         * @param {any} [field] The field name filtered or sorted on.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillmentBatchesList: async (limit?: number, value?: any, operation?: any, direction?: any, field?: any, before?: any, after?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fulfillment-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }

            if (operation !== undefined) {
                localVarQueryParameter['operation'] = operation;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of fulfillment records.
         * @summary 
         * @param {string} [status] Fulfillment records status: backlog, on_hold, approved, or sent.
         * @param {string} [batch] Fulfillment Batch ID.
         * @param {number} [limit] Number of items to return.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {any} [value] Field value to filter with.
         * @param {any} [operation] One of these &lt;, &lt;&#x3D;, &#x3D;&#x3D;, !&#x3D;, &gt;&#x3D;, &gt;, array-contains, in, not-in, array-contains-any.
         * @param {any} [direction] Sort direction either desc or asc.
         * @param {any} [field] The field name filtered or sorted on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillmentRecordsList: async (status?: string, batch?: string, limit?: number, before?: any, after?: any, value?: any, operation?: any, direction?: any, field?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fulfillment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (batch !== undefined) {
                localVarQueryParameter['batch'] = batch;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }

            if (operation !== undefined) {
                localVarQueryParameter['operation'] = operation;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FulfillmentApi - functional programming interface
 * @export
 */
export const FulfillmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FulfillmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Fulfillment records action endpoint.
         * @summary 
         * @param {FulfillmentInput} fulfillmentInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fulfillmentAction(fulfillmentInput: FulfillmentInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fulfillmentAction(fulfillmentInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fulfillment batch action endpoint.
         * @summary 
         * @param {FulfillmentBatchInput} fulfillmentBatchInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fulfillmentBatchAction(fulfillmentBatchInput: FulfillmentBatchInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fulfillmentBatchAction(fulfillmentBatchInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fulfillment batch download as CSV endpoint.
         * @summary 
         * @param {string} batch A valid Fulfilment Batch ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fulfillmentBatchDownload(batch: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fulfillmentBatchDownload(batch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of fulfillment batches.
         * @summary 
         * @param {number} [limit] Number of items to return.
         * @param {any} [value] Field value to filter with.
         * @param {any} [operation] One of these &lt;, &lt;&#x3D;, &#x3D;&#x3D;, !&#x3D;, &gt;&#x3D;, &gt;, array-contains, in, not-in, array-contains-any.
         * @param {any} [direction] Sort direction either desc or asc.
         * @param {any} [field] The field name filtered or sorted on.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fulfillmentBatchesList(limit?: number, value?: any, operation?: any, direction?: any, field?: any, before?: any, after?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FulfillmentBatchesList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fulfillmentBatchesList(limit, value, operation, direction, field, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of fulfillment records.
         * @summary 
         * @param {string} [status] Fulfillment records status: backlog, on_hold, approved, or sent.
         * @param {string} [batch] Fulfillment Batch ID.
         * @param {number} [limit] Number of items to return.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {any} [value] Field value to filter with.
         * @param {any} [operation] One of these &lt;, &lt;&#x3D;, &#x3D;&#x3D;, !&#x3D;, &gt;&#x3D;, &gt;, array-contains, in, not-in, array-contains-any.
         * @param {any} [direction] Sort direction either desc or asc.
         * @param {any} [field] The field name filtered or sorted on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fulfillmentRecordsList(status?: string, batch?: string, limit?: number, before?: any, after?: any, value?: any, operation?: any, direction?: any, field?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FulfillmentRecordsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fulfillmentRecordsList(status, batch, limit, before, after, value, operation, direction, field, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FulfillmentApi - factory interface
 * @export
 */
export const FulfillmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FulfillmentApiFp(configuration)
    return {
        /**
         * Fulfillment records action endpoint.
         * @summary 
         * @param {FulfillmentInput} fulfillmentInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillmentAction(fulfillmentInput: FulfillmentInput, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.fulfillmentAction(fulfillmentInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Fulfillment batch action endpoint.
         * @summary 
         * @param {FulfillmentBatchInput} fulfillmentBatchInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillmentBatchAction(fulfillmentBatchInput: FulfillmentBatchInput, options?: any): AxiosPromise<object> {
            return localVarFp.fulfillmentBatchAction(fulfillmentBatchInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Fulfillment batch download as CSV endpoint.
         * @summary 
         * @param {string} batch A valid Fulfilment Batch ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillmentBatchDownload(batch: string, options?: any): AxiosPromise<File> {
            return localVarFp.fulfillmentBatchDownload(batch, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of fulfillment batches.
         * @summary 
         * @param {number} [limit] Number of items to return.
         * @param {any} [value] Field value to filter with.
         * @param {any} [operation] One of these &lt;, &lt;&#x3D;, &#x3D;&#x3D;, !&#x3D;, &gt;&#x3D;, &gt;, array-contains, in, not-in, array-contains-any.
         * @param {any} [direction] Sort direction either desc or asc.
         * @param {any} [field] The field name filtered or sorted on.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillmentBatchesList(limit?: number, value?: any, operation?: any, direction?: any, field?: any, before?: any, after?: any, options?: any): AxiosPromise<FulfillmentBatchesList200Response> {
            return localVarFp.fulfillmentBatchesList(limit, value, operation, direction, field, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of fulfillment records.
         * @summary 
         * @param {string} [status] Fulfillment records status: backlog, on_hold, approved, or sent.
         * @param {string} [batch] Fulfillment Batch ID.
         * @param {number} [limit] Number of items to return.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {any} [value] Field value to filter with.
         * @param {any} [operation] One of these &lt;, &lt;&#x3D;, &#x3D;&#x3D;, !&#x3D;, &gt;&#x3D;, &gt;, array-contains, in, not-in, array-contains-any.
         * @param {any} [direction] Sort direction either desc or asc.
         * @param {any} [field] The field name filtered or sorted on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillmentRecordsList(status?: string, batch?: string, limit?: number, before?: any, after?: any, value?: any, operation?: any, direction?: any, field?: any, options?: any): AxiosPromise<FulfillmentRecordsList200Response> {
            return localVarFp.fulfillmentRecordsList(status, batch, limit, before, after, value, operation, direction, field, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FulfillmentApi - object-oriented interface
 * @export
 * @class FulfillmentApi
 * @extends {BaseAPI}
 */
export class FulfillmentApi extends BaseAPI {
    /**
     * Fulfillment records action endpoint.
     * @summary 
     * @param {FulfillmentInput} fulfillmentInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FulfillmentApi
     */
    public fulfillmentAction(fulfillmentInput: FulfillmentInput, options?: AxiosRequestConfig) {
        return FulfillmentApiFp(this.configuration).fulfillmentAction(fulfillmentInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fulfillment batch action endpoint.
     * @summary 
     * @param {FulfillmentBatchInput} fulfillmentBatchInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FulfillmentApi
     */
    public fulfillmentBatchAction(fulfillmentBatchInput: FulfillmentBatchInput, options?: AxiosRequestConfig) {
        return FulfillmentApiFp(this.configuration).fulfillmentBatchAction(fulfillmentBatchInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fulfillment batch download as CSV endpoint.
     * @summary 
     * @param {string} batch A valid Fulfilment Batch ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FulfillmentApi
     */
    public fulfillmentBatchDownload(batch: string, options?: AxiosRequestConfig) {
        return FulfillmentApiFp(this.configuration).fulfillmentBatchDownload(batch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of fulfillment batches.
     * @summary 
     * @param {number} [limit] Number of items to return.
     * @param {any} [value] Field value to filter with.
     * @param {any} [operation] One of these &lt;, &lt;&#x3D;, &#x3D;&#x3D;, !&#x3D;, &gt;&#x3D;, &gt;, array-contains, in, not-in, array-contains-any.
     * @param {any} [direction] Sort direction either desc or asc.
     * @param {any} [field] The field name filtered or sorted on.
     * @param {any} [before] ID of the item to return items before.
     * @param {any} [after] ID of the item to return items after.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FulfillmentApi
     */
    public fulfillmentBatchesList(limit?: number, value?: any, operation?: any, direction?: any, field?: any, before?: any, after?: any, options?: AxiosRequestConfig) {
        return FulfillmentApiFp(this.configuration).fulfillmentBatchesList(limit, value, operation, direction, field, before, after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of fulfillment records.
     * @summary 
     * @param {string} [status] Fulfillment records status: backlog, on_hold, approved, or sent.
     * @param {string} [batch] Fulfillment Batch ID.
     * @param {number} [limit] Number of items to return.
     * @param {any} [before] ID of the item to return items before.
     * @param {any} [after] ID of the item to return items after.
     * @param {any} [value] Field value to filter with.
     * @param {any} [operation] One of these &lt;, &lt;&#x3D;, &#x3D;&#x3D;, !&#x3D;, &gt;&#x3D;, &gt;, array-contains, in, not-in, array-contains-any.
     * @param {any} [direction] Sort direction either desc or asc.
     * @param {any} [field] The field name filtered or sorted on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FulfillmentApi
     */
    public fulfillmentRecordsList(status?: string, batch?: string, limit?: number, before?: any, after?: any, value?: any, operation?: any, direction?: any, field?: any, options?: AxiosRequestConfig) {
        return FulfillmentApiFp(this.configuration).fulfillmentRecordsList(status, batch, limit, before, after, value, operation, direction, field, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MemberApi - axios parameter creator
 * @export
 */
export const MemberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Destroy iron session.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSessionDestroy: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get iron session.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSessionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set iron session.
         * @summary 
         * @param {MemberAuthSessionSetInput} memberAuthSessionSetInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSessionSet: async (memberAuthSessionSetInput: MemberAuthSessionSetInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberAuthSessionSetInput' is not null or undefined
            assertParamExists('authSessionSet', 'memberAuthSessionSetInput', memberAuthSessionSetInput)
            const localVarPath = `/auth/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAuthSessionSetInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Data privacy endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPrivacy: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data-privacy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member remove token of google two factor authentication.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSecret: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/auth-settings/2fa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member set google two factor authentication.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSecret: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/auth-settings/2fa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member verification status endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberActiveProgram: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/program`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifies token and updates member\'s email.
         * @summary 
         * @param {MemberAuthEmailConfirmInput} memberAuthEmailConfirmInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthEmailConfirm: async (memberAuthEmailConfirmInput: MemberAuthEmailConfirmInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberAuthEmailConfirmInput' is not null or undefined
            assertParamExists('memberAuthEmailConfirm', 'memberAuthEmailConfirmInput', memberAuthEmailConfirmInput)
            const localVarPath = `/member/auth/email/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAuthEmailConfirmInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifies member\'s email.
         * @summary 
         * @param {MemberAuthEmailVerifyInput} memberAuthEmailVerifyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthEmailUpdate: async (memberAuthEmailVerifyInput: MemberAuthEmailVerifyInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberAuthEmailVerifyInput' is not null or undefined
            assertParamExists('memberAuthEmailUpdate', 'memberAuthEmailVerifyInput', memberAuthEmailVerifyInput)
            const localVarPath = `/member/auth/email/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAuthEmailVerifyInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends welcome email to the given user.
         * @summary 
         * @param {string} uid User ID.
         * @param {MemberAuthEmailVerifyInput} memberAuthEmailVerifyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthInvite: async (uid: string, memberAuthEmailVerifyInput: MemberAuthEmailVerifyInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('memberAuthInvite', 'uid', uid)
            // verify required parameter 'memberAuthEmailVerifyInput' is not null or undefined
            assertParamExists('memberAuthInvite', 'memberAuthEmailVerifyInput', memberAuthEmailVerifyInput)
            const localVarPath = `/member/auth/invite/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAuthEmailVerifyInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Confirms member\'s invitation.
         * @summary 
         * @param {MemberAuthEmailConfirmInput} memberAuthEmailConfirmInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthInviteConfirm: async (memberAuthEmailConfirmInput: MemberAuthEmailConfirmInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberAuthEmailConfirmInput' is not null or undefined
            assertParamExists('memberAuthInviteConfirm', 'memberAuthEmailConfirmInput', memberAuthEmailConfirmInput)
            const localVarPath = `/member/auth/invite/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAuthEmailConfirmInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send an email for newsletter sign-up.
         * @summary 
         * @param {MemberAuthNewsletterSignupInput} memberAuthNewsletterSignupInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthNewsletterSignup: async (memberAuthNewsletterSignupInput: MemberAuthNewsletterSignupInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberAuthNewsletterSignupInput' is not null or undefined
            assertParamExists('memberAuthNewsletterSignup', 'memberAuthNewsletterSignupInput', memberAuthNewsletterSignupInput)
            const localVarPath = `/member/auth/newsletter-signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAuthNewsletterSignupInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Confirm newsletter sign-up.
         * @summary 
         * @param {any} token The signed token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthNewsletterSignupConfirm: async (token: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('memberAuthNewsletterSignupConfirm', 'token', token)
            const localVarPath = `/member/auth/newsletter-signup/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates member\'s password.
         * @summary 
         * @param {MemberAuthPasswordUpdateInput} memberAuthPasswordUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthPasswordUpdate: async (memberAuthPasswordUpdateInput: MemberAuthPasswordUpdateInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberAuthPasswordUpdateInput' is not null or undefined
            assertParamExists('memberAuthPasswordUpdate', 'memberAuthPasswordUpdateInput', memberAuthPasswordUpdateInput)
            const localVarPath = `/member/auth/password/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAuthPasswordUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send an authentication code to the user\'s phone to validate their phone number.
         * @summary 
         * @param {MemberAuthPhoneSendCodeInput} memberAuthPhoneSendCodeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthPhoneSendCode: async (memberAuthPhoneSendCodeInput: MemberAuthPhoneSendCodeInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberAuthPhoneSendCodeInput' is not null or undefined
            assertParamExists('memberAuthPhoneSendCode', 'memberAuthPhoneSendCodeInput', memberAuthPhoneSendCodeInput)
            const localVarPath = `/member/auth/phone/send-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAuthPhoneSendCodeInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate the SMS code sent to the user\'s phone.
         * @summary 
         * @param {MemberAuthPhoneValidateCodeInput} memberAuthPhoneValidateCodeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthPhoneValidateCode: async (memberAuthPhoneValidateCodeInput: MemberAuthPhoneValidateCodeInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberAuthPhoneValidateCodeInput' is not null or undefined
            assertParamExists('memberAuthPhoneValidateCode', 'memberAuthPhoneValidateCodeInput', memberAuthPhoneValidateCodeInput)
            const localVarPath = `/member/auth/phone/validate-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAuthPhoneValidateCodeInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send an email for preregistered user.
         * @summary 
         * @param {MemberAuthPreregisterInput} memberAuthPreregisterInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthPreregister: async (memberAuthPreregisterInput: MemberAuthPreregisterInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberAuthPreregisterInput' is not null or undefined
            assertParamExists('memberAuthPreregister', 'memberAuthPreregisterInput', memberAuthPreregisterInput)
            const localVarPath = `/member/auth/preregister`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAuthPreregisterInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Confirm preregistered user registration.
         * @summary 
         * @param {any} token Preregister token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthPreregisterConfirm: async (token: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('memberAuthPreregisterConfirm', 'token', token)
            const localVarPath = `/member/auth/preregister/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes Social provider.
         * @summary 
         * @param {MemberAuthProviderUnlinkInput} memberAuthProviderUnlinkInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthProviderUnlink: async (memberAuthProviderUnlinkInput: MemberAuthProviderUnlinkInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberAuthProviderUnlinkInput' is not null or undefined
            assertParamExists('memberAuthProviderUnlink', 'memberAuthProviderUnlinkInput', memberAuthProviderUnlinkInput)
            const localVarPath = `/member/auth/provider/unlink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAuthProviderUnlinkInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Recaptcha verifier endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthRecaptchaVerifier: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/auth/recaptcha-verifier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifies token and resend password reset.
         * @summary 
         * @param {MemberAuthResendPasswordResetInput} memberAuthResendPasswordResetInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthResendPasswordResetEmail: async (memberAuthResendPasswordResetInput: MemberAuthResendPasswordResetInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberAuthResendPasswordResetInput' is not null or undefined
            assertParamExists('memberAuthResendPasswordResetEmail', 'memberAuthResendPasswordResetInput', memberAuthResendPasswordResetInput)
            const localVarPath = `/member/auth/resend/password-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAuthResendPasswordResetInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Email domain and usage verification endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthVerifyEmailDomain: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/auth/verify-email-domain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member consent sessions endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberConsentSessions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/consent-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member consent sessions delete endpoint.
         * @summary 
         * @param {string} [client] Consent session client ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberConsentSessionsDelete: async (client?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/consent-sessions/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member contact preferences info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberContactPreferences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/contact-preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member contact preferences update endpoint.
         * @summary 
         * @param {UserContactPreferencesInput} userContactPreferencesInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberContactPreferencesUpdate: async (userContactPreferencesInput: UserContactPreferencesInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userContactPreferencesInput' is not null or undefined
            assertParamExists('memberContactPreferencesUpdate', 'userContactPreferencesInput', userContactPreferencesInput)
            const localVarPath = `/member/contact-preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userContactPreferencesInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member invoices endpoint.
         * @summary 
         * @param {number} [limit] Number of items to return.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberInvoices: async (limit?: number, before?: any, after?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member military credentials info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberMilitaryCredentials: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/military-credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member payment method endpoint.
         * @summary 
         * @param {string} id Payment method ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPaymentMethod: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('memberPaymentMethod', 'id', id)
            const localVarPath = `/member/payment-methods/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member payment method create endpoint.
         * @summary 
         * @param {MemberPaymentMethodCreateInput} memberPaymentMethodCreateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPaymentMethodCreate: async (memberPaymentMethodCreateInput: MemberPaymentMethodCreateInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberPaymentMethodCreateInput' is not null or undefined
            assertParamExists('memberPaymentMethodCreate', 'memberPaymentMethodCreateInput', memberPaymentMethodCreateInput)
            const localVarPath = `/member/payment-methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberPaymentMethodCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member payment method delete endpoint.
         * @summary 
         * @param {string} id Payment method ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPaymentMethodDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('memberPaymentMethodDelete', 'id', id)
            const localVarPath = `/member/payment-methods/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member payment method update endpoint.
         * @summary 
         * @param {any} id Payment method ID.
         * @param {MemberPaymentMethodUpdateInput} memberPaymentMethodUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPaymentMethodUpdate: async (id: any, memberPaymentMethodUpdateInput: MemberPaymentMethodUpdateInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('memberPaymentMethodUpdate', 'id', id)
            // verify required parameter 'memberPaymentMethodUpdateInput' is not null or undefined
            assertParamExists('memberPaymentMethodUpdate', 'memberPaymentMethodUpdateInput', memberPaymentMethodUpdateInput)
            const localVarPath = `/member/payment-methods/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberPaymentMethodUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member payment methods endpoint.
         * @summary 
         * @param {number} [limit] Number of items to return.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPaymentMethods: async (limit?: number, before?: any, after?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/payment-methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member profile endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member profile photo delete endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberProfilePhotoDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/profile-photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member profile photo file endpoint.
         * @summary 
         * @param {string} token Member profile photo token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberProfilePhotoDownload: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('memberProfilePhotoDownload', 'token', token)
            const localVarPath = `/files/member/profile-photo/{token}.png`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member profile photo info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberProfilePhotoInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/profile-photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member profile photo upload endpoint.
         * @summary 
         * @param {MemberProfilePhotoInput} memberProfilePhotoInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberProfilePhotoUpload: async (memberProfilePhotoInput: MemberProfilePhotoInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberProfilePhotoInput' is not null or undefined
            assertParamExists('memberProfilePhotoUpload', 'memberProfilePhotoInput', memberProfilePhotoInput)
            const localVarPath = `/member/profile-photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberProfilePhotoInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member profile update endpoint.
         * @summary 
         * @param {MemberProfileInput} memberProfileInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberProfileUpdate: async (memberProfileInput: MemberProfileInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberProfileInput' is not null or undefined
            assertParamExists('memberProfileUpdate', 'memberProfileInput', memberProfileInput)
            const localVarPath = `/member/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberProfileInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member verification status endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberVerificationStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/verification-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Military branches endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        militaryBranches: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/military-branches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Military ranks endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        militaryRanks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/military-ranks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member store token of google two factor authentication.
         * @summary 
         * @param {MemberTokenDto} memberTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeSecret: async (memberTokenDto: MemberTokenDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberTokenDto' is not null or undefined
            assertParamExists('storeSecret', 'memberTokenDto', memberTokenDto)
            const localVarPath = `/member/auth-settings/2fa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Timezones endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timezones: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/timezones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Auxiliary validation endpoint.
         * @summary 
         * @param {ValidateInput} validateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate: async (validateInput: ValidateInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateInput' is not null or undefined
            assertParamExists('validate', 'validateInput', validateInput)
            const localVarPath = `/member/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberApi - functional programming interface
 * @export
 */
export const MemberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberApiAxiosParamCreator(configuration)
    return {
        /**
         * Destroy iron session.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSessionDestroy(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authSessionDestroy(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get iron session.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSessionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authSessionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set iron session.
         * @summary 
         * @param {MemberAuthSessionSetInput} memberAuthSessionSetInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSessionSet(memberAuthSessionSetInput: MemberAuthSessionSetInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authSessionSet(memberAuthSessionSetInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Data privacy endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataPrivacy(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataPrivacy200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataPrivacy(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member remove token of google two factor authentication.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSecret(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSecret(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member set google two factor authentication.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateSecret(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateSecret200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateSecret(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member verification status endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberActiveProgram(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberActiveProgram200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberActiveProgram(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verifies token and updates member\'s email.
         * @summary 
         * @param {MemberAuthEmailConfirmInput} memberAuthEmailConfirmInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAuthEmailConfirm(memberAuthEmailConfirmInput: MemberAuthEmailConfirmInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAuthEmailConfirm(memberAuthEmailConfirmInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verifies member\'s email.
         * @summary 
         * @param {MemberAuthEmailVerifyInput} memberAuthEmailVerifyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAuthEmailUpdate(memberAuthEmailVerifyInput: MemberAuthEmailVerifyInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAuthEmailUpdate(memberAuthEmailVerifyInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sends welcome email to the given user.
         * @summary 
         * @param {string} uid User ID.
         * @param {MemberAuthEmailVerifyInput} memberAuthEmailVerifyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAuthInvite(uid: string, memberAuthEmailVerifyInput: MemberAuthEmailVerifyInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAuthInvite(uid, memberAuthEmailVerifyInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Confirms member\'s invitation.
         * @summary 
         * @param {MemberAuthEmailConfirmInput} memberAuthEmailConfirmInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAuthInviteConfirm(memberAuthEmailConfirmInput: MemberAuthEmailConfirmInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAuthInviteConfirm(memberAuthEmailConfirmInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send an email for newsletter sign-up.
         * @summary 
         * @param {MemberAuthNewsletterSignupInput} memberAuthNewsletterSignupInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAuthNewsletterSignup(memberAuthNewsletterSignupInput: MemberAuthNewsletterSignupInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAuthNewsletterSignup(memberAuthNewsletterSignupInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Confirm newsletter sign-up.
         * @summary 
         * @param {any} token The signed token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAuthNewsletterSignupConfirm(token: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAuthNewsletterSignupConfirm(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates member\'s password.
         * @summary 
         * @param {MemberAuthPasswordUpdateInput} memberAuthPasswordUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAuthPasswordUpdate(memberAuthPasswordUpdateInput: MemberAuthPasswordUpdateInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAuthPasswordUpdate(memberAuthPasswordUpdateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send an authentication code to the user\'s phone to validate their phone number.
         * @summary 
         * @param {MemberAuthPhoneSendCodeInput} memberAuthPhoneSendCodeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAuthPhoneSendCode(memberAuthPhoneSendCodeInput: MemberAuthPhoneSendCodeInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberAuthPhoneSendCode200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAuthPhoneSendCode(memberAuthPhoneSendCodeInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validate the SMS code sent to the user\'s phone.
         * @summary 
         * @param {MemberAuthPhoneValidateCodeInput} memberAuthPhoneValidateCodeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAuthPhoneValidateCode(memberAuthPhoneValidateCodeInput: MemberAuthPhoneValidateCodeInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberAuthPhoneValidateCode200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAuthPhoneValidateCode(memberAuthPhoneValidateCodeInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send an email for preregistered user.
         * @summary 
         * @param {MemberAuthPreregisterInput} memberAuthPreregisterInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAuthPreregister(memberAuthPreregisterInput: MemberAuthPreregisterInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAuthPreregister(memberAuthPreregisterInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Confirm preregistered user registration.
         * @summary 
         * @param {any} token Preregister token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAuthPreregisterConfirm(token: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAuthPreregisterConfirm(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes Social provider.
         * @summary 
         * @param {MemberAuthProviderUnlinkInput} memberAuthProviderUnlinkInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAuthProviderUnlink(memberAuthProviderUnlinkInput: MemberAuthProviderUnlinkInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAuthProviderUnlink(memberAuthProviderUnlinkInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Recaptcha verifier endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAuthRecaptchaVerifier(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAuthRecaptchaVerifier(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verifies token and resend password reset.
         * @summary 
         * @param {MemberAuthResendPasswordResetInput} memberAuthResendPasswordResetInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAuthResendPasswordResetEmail(memberAuthResendPasswordResetInput: MemberAuthResendPasswordResetInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAuthResendPasswordResetEmail(memberAuthResendPasswordResetInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Email domain and usage verification endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAuthVerifyEmailDomain(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAuthVerifyEmailDomain(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member consent sessions endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberConsentSessions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberConsentSessions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberConsentSessions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member consent sessions delete endpoint.
         * @summary 
         * @param {string} [client] Consent session client ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberConsentSessionsDelete(client?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberConsentSessionsDelete(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member contact preferences info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberContactPreferences(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberContactPreferences200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberContactPreferences(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member contact preferences update endpoint.
         * @summary 
         * @param {UserContactPreferencesInput} userContactPreferencesInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberContactPreferencesUpdate(userContactPreferencesInput: UserContactPreferencesInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberContactPreferences200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberContactPreferencesUpdate(userContactPreferencesInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberInfo200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member invoices endpoint.
         * @summary 
         * @param {number} [limit] Number of items to return.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberInvoices(limit?: number, before?: any, after?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberInvoices200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberInvoices(limit, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member military credentials info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberMilitaryCredentials(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberMilitaryCredentials200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberMilitaryCredentials(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member payment method endpoint.
         * @summary 
         * @param {string} id Payment method ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberPaymentMethod(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberPaymentMethodCreate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberPaymentMethod(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member payment method create endpoint.
         * @summary 
         * @param {MemberPaymentMethodCreateInput} memberPaymentMethodCreateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberPaymentMethodCreate(memberPaymentMethodCreateInput: MemberPaymentMethodCreateInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberPaymentMethodCreate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberPaymentMethodCreate(memberPaymentMethodCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member payment method delete endpoint.
         * @summary 
         * @param {string} id Payment method ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberPaymentMethodDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberPaymentMethodDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member payment method update endpoint.
         * @summary 
         * @param {any} id Payment method ID.
         * @param {MemberPaymentMethodUpdateInput} memberPaymentMethodUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberPaymentMethodUpdate(id: any, memberPaymentMethodUpdateInput: MemberPaymentMethodUpdateInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberPaymentMethodCreate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberPaymentMethodUpdate(id, memberPaymentMethodUpdateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member payment methods endpoint.
         * @summary 
         * @param {number} [limit] Number of items to return.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberPaymentMethods(limit?: number, before?: any, after?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberPaymentMethods200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberPaymentMethods(limit, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member profile endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberProfileUpdate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member profile photo delete endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberProfilePhotoDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberProfilePhotoDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member profile photo file endpoint.
         * @summary 
         * @param {string} token Member profile photo token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberProfilePhotoDownload(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberProfilePhotoDownload(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member profile photo info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberProfilePhotoInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberProfilePhotoInfo200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberProfilePhotoInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member profile photo upload endpoint.
         * @summary 
         * @param {MemberProfilePhotoInput} memberProfilePhotoInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberProfilePhotoUpload(memberProfilePhotoInput: MemberProfilePhotoInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberProfilePhotoUpload200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberProfilePhotoUpload(memberProfilePhotoInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member profile update endpoint.
         * @summary 
         * @param {MemberProfileInput} memberProfileInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberProfileUpdate(memberProfileInput: MemberProfileInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberProfileUpdate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberProfileUpdate(memberProfileInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member verification status endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberVerificationStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberVerificationStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberVerificationStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Military branches endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async militaryBranches(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilitaryBranches200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.militaryBranches(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Military ranks endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async militaryRanks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilitaryRanks200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.militaryRanks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member store token of google two factor authentication.
         * @summary 
         * @param {MemberTokenDto} memberTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeSecret(memberTokenDto: MemberTokenDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseEmpty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeSecret(memberTokenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Timezones endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timezones(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Timezones200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timezones(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Auxiliary validation endpoint.
         * @summary 
         * @param {ValidateInput} validateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validate(validateInput: ValidateInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Validate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validate(validateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MemberApi - factory interface
 * @export
 */
export const MemberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberApiFp(configuration)
    return {
        /**
         * Destroy iron session.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSessionDestroy(options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.authSessionDestroy(options).then((request) => request(axios, basePath));
        },
        /**
         * Get iron session.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSessionGet(options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.authSessionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Set iron session.
         * @summary 
         * @param {MemberAuthSessionSetInput} memberAuthSessionSetInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSessionSet(memberAuthSessionSetInput: MemberAuthSessionSetInput, options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.authSessionSet(memberAuthSessionSetInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Data privacy endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataPrivacy(options?: any): AxiosPromise<DataPrivacy200Response> {
            return localVarFp.dataPrivacy(options).then((request) => request(axios, basePath));
        },
        /**
         * Member remove token of google two factor authentication.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSecret(options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.deleteSecret(options).then((request) => request(axios, basePath));
        },
        /**
         * Member set google two factor authentication.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSecret(options?: any): AxiosPromise<GenerateSecret200Response> {
            return localVarFp.generateSecret(options).then((request) => request(axios, basePath));
        },
        /**
         * Member verification status endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberActiveProgram(options?: any): AxiosPromise<MemberActiveProgram200Response> {
            return localVarFp.memberActiveProgram(options).then((request) => request(axios, basePath));
        },
        /**
         * Verifies token and updates member\'s email.
         * @summary 
         * @param {MemberAuthEmailConfirmInput} memberAuthEmailConfirmInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthEmailConfirm(memberAuthEmailConfirmInput: MemberAuthEmailConfirmInput, options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.memberAuthEmailConfirm(memberAuthEmailConfirmInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Verifies member\'s email.
         * @summary 
         * @param {MemberAuthEmailVerifyInput} memberAuthEmailVerifyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthEmailUpdate(memberAuthEmailVerifyInput: MemberAuthEmailVerifyInput, options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.memberAuthEmailUpdate(memberAuthEmailVerifyInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends welcome email to the given user.
         * @summary 
         * @param {string} uid User ID.
         * @param {MemberAuthEmailVerifyInput} memberAuthEmailVerifyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthInvite(uid: string, memberAuthEmailVerifyInput: MemberAuthEmailVerifyInput, options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.memberAuthInvite(uid, memberAuthEmailVerifyInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Confirms member\'s invitation.
         * @summary 
         * @param {MemberAuthEmailConfirmInput} memberAuthEmailConfirmInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthInviteConfirm(memberAuthEmailConfirmInput: MemberAuthEmailConfirmInput, options?: any): AxiosPromise<string> {
            return localVarFp.memberAuthInviteConfirm(memberAuthEmailConfirmInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Send an email for newsletter sign-up.
         * @summary 
         * @param {MemberAuthNewsletterSignupInput} memberAuthNewsletterSignupInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthNewsletterSignup(memberAuthNewsletterSignupInput: MemberAuthNewsletterSignupInput, options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.memberAuthNewsletterSignup(memberAuthNewsletterSignupInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Confirm newsletter sign-up.
         * @summary 
         * @param {any} token The signed token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthNewsletterSignupConfirm(token: any, options?: any): AxiosPromise<string> {
            return localVarFp.memberAuthNewsletterSignupConfirm(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates member\'s password.
         * @summary 
         * @param {MemberAuthPasswordUpdateInput} memberAuthPasswordUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthPasswordUpdate(memberAuthPasswordUpdateInput: MemberAuthPasswordUpdateInput, options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.memberAuthPasswordUpdate(memberAuthPasswordUpdateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Send an authentication code to the user\'s phone to validate their phone number.
         * @summary 
         * @param {MemberAuthPhoneSendCodeInput} memberAuthPhoneSendCodeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthPhoneSendCode(memberAuthPhoneSendCodeInput: MemberAuthPhoneSendCodeInput, options?: any): AxiosPromise<MemberAuthPhoneSendCode200Response> {
            return localVarFp.memberAuthPhoneSendCode(memberAuthPhoneSendCodeInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Validate the SMS code sent to the user\'s phone.
         * @summary 
         * @param {MemberAuthPhoneValidateCodeInput} memberAuthPhoneValidateCodeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthPhoneValidateCode(memberAuthPhoneValidateCodeInput: MemberAuthPhoneValidateCodeInput, options?: any): AxiosPromise<MemberAuthPhoneValidateCode200Response> {
            return localVarFp.memberAuthPhoneValidateCode(memberAuthPhoneValidateCodeInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Send an email for preregistered user.
         * @summary 
         * @param {MemberAuthPreregisterInput} memberAuthPreregisterInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthPreregister(memberAuthPreregisterInput: MemberAuthPreregisterInput, options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.memberAuthPreregister(memberAuthPreregisterInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Confirm preregistered user registration.
         * @summary 
         * @param {any} token Preregister token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthPreregisterConfirm(token: any, options?: any): AxiosPromise<string> {
            return localVarFp.memberAuthPreregisterConfirm(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes Social provider.
         * @summary 
         * @param {MemberAuthProviderUnlinkInput} memberAuthProviderUnlinkInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthProviderUnlink(memberAuthProviderUnlinkInput: MemberAuthProviderUnlinkInput, options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.memberAuthProviderUnlink(memberAuthProviderUnlinkInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Recaptcha verifier endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthRecaptchaVerifier(options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.memberAuthRecaptchaVerifier(options).then((request) => request(axios, basePath));
        },
        /**
         * Verifies token and resend password reset.
         * @summary 
         * @param {MemberAuthResendPasswordResetInput} memberAuthResendPasswordResetInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthResendPasswordResetEmail(memberAuthResendPasswordResetInput: MemberAuthResendPasswordResetInput, options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.memberAuthResendPasswordResetEmail(memberAuthResendPasswordResetInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Email domain and usage verification endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAuthVerifyEmailDomain(options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.memberAuthVerifyEmailDomain(options).then((request) => request(axios, basePath));
        },
        /**
         * Member consent sessions endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberConsentSessions(options?: any): AxiosPromise<MemberConsentSessions200Response> {
            return localVarFp.memberConsentSessions(options).then((request) => request(axios, basePath));
        },
        /**
         * Member consent sessions delete endpoint.
         * @summary 
         * @param {string} [client] Consent session client ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberConsentSessionsDelete(client?: string, options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.memberConsentSessionsDelete(client, options).then((request) => request(axios, basePath));
        },
        /**
         * Member contact preferences info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberContactPreferences(options?: any): AxiosPromise<MemberContactPreferences200Response> {
            return localVarFp.memberContactPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * Member contact preferences update endpoint.
         * @summary 
         * @param {UserContactPreferencesInput} userContactPreferencesInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberContactPreferencesUpdate(userContactPreferencesInput: UserContactPreferencesInput, options?: any): AxiosPromise<MemberContactPreferences200Response> {
            return localVarFp.memberContactPreferencesUpdate(userContactPreferencesInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberInfo(options?: any): AxiosPromise<MemberInfo200Response> {
            return localVarFp.memberInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Member invoices endpoint.
         * @summary 
         * @param {number} [limit] Number of items to return.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberInvoices(limit?: number, before?: any, after?: any, options?: any): AxiosPromise<MemberInvoices200Response> {
            return localVarFp.memberInvoices(limit, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * Member military credentials info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberMilitaryCredentials(options?: any): AxiosPromise<MemberMilitaryCredentials200Response> {
            return localVarFp.memberMilitaryCredentials(options).then((request) => request(axios, basePath));
        },
        /**
         * Member payment method endpoint.
         * @summary 
         * @param {string} id Payment method ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPaymentMethod(id: string, options?: any): AxiosPromise<MemberPaymentMethodCreate200Response> {
            return localVarFp.memberPaymentMethod(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Member payment method create endpoint.
         * @summary 
         * @param {MemberPaymentMethodCreateInput} memberPaymentMethodCreateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPaymentMethodCreate(memberPaymentMethodCreateInput: MemberPaymentMethodCreateInput, options?: any): AxiosPromise<MemberPaymentMethodCreate200Response> {
            return localVarFp.memberPaymentMethodCreate(memberPaymentMethodCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member payment method delete endpoint.
         * @summary 
         * @param {string} id Payment method ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPaymentMethodDelete(id: string, options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.memberPaymentMethodDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Member payment method update endpoint.
         * @summary 
         * @param {any} id Payment method ID.
         * @param {MemberPaymentMethodUpdateInput} memberPaymentMethodUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPaymentMethodUpdate(id: any, memberPaymentMethodUpdateInput: MemberPaymentMethodUpdateInput, options?: any): AxiosPromise<MemberPaymentMethodCreate200Response> {
            return localVarFp.memberPaymentMethodUpdate(id, memberPaymentMethodUpdateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member payment methods endpoint.
         * @summary 
         * @param {number} [limit] Number of items to return.
         * @param {any} [before] ID of the item to return items before.
         * @param {any} [after] ID of the item to return items after.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPaymentMethods(limit?: number, before?: any, after?: any, options?: any): AxiosPromise<MemberPaymentMethods200Response> {
            return localVarFp.memberPaymentMethods(limit, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * Member profile endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberProfile(options?: any): AxiosPromise<MemberProfileUpdate200Response> {
            return localVarFp.memberProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * Member profile photo delete endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberProfilePhotoDelete(options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.memberProfilePhotoDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Member profile photo file endpoint.
         * @summary 
         * @param {string} token Member profile photo token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberProfilePhotoDownload(token: string, options?: any): AxiosPromise<File> {
            return localVarFp.memberProfilePhotoDownload(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Member profile photo info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberProfilePhotoInfo(options?: any): AxiosPromise<MemberProfilePhotoInfo200Response> {
            return localVarFp.memberProfilePhotoInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Member profile photo upload endpoint.
         * @summary 
         * @param {MemberProfilePhotoInput} memberProfilePhotoInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberProfilePhotoUpload(memberProfilePhotoInput: MemberProfilePhotoInput, options?: any): AxiosPromise<MemberProfilePhotoUpload200Response> {
            return localVarFp.memberProfilePhotoUpload(memberProfilePhotoInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member profile update endpoint.
         * @summary 
         * @param {MemberProfileInput} memberProfileInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberProfileUpdate(memberProfileInput: MemberProfileInput, options?: any): AxiosPromise<MemberProfileUpdate200Response> {
            return localVarFp.memberProfileUpdate(memberProfileInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Member verification status endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberVerificationStatus(options?: any): AxiosPromise<MemberVerificationStatus200Response> {
            return localVarFp.memberVerificationStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * Military branches endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        militaryBranches(options?: any): AxiosPromise<MilitaryBranches200Response> {
            return localVarFp.militaryBranches(options).then((request) => request(axios, basePath));
        },
        /**
         * Military ranks endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        militaryRanks(options?: any): AxiosPromise<MilitaryRanks200Response> {
            return localVarFp.militaryRanks(options).then((request) => request(axios, basePath));
        },
        /**
         * Member store token of google two factor authentication.
         * @summary 
         * @param {MemberTokenDto} memberTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeSecret(memberTokenDto: MemberTokenDto, options?: any): AxiosPromise<SuccessResponseEmpty> {
            return localVarFp.storeSecret(memberTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Timezones endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timezones(options?: any): AxiosPromise<Timezones200Response> {
            return localVarFp.timezones(options).then((request) => request(axios, basePath));
        },
        /**
         * Auxiliary validation endpoint.
         * @summary 
         * @param {ValidateInput} validateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate(validateInput: ValidateInput, options?: any): AxiosPromise<Validate200Response> {
            return localVarFp.validate(validateInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MemberApi - object-oriented interface
 * @export
 * @class MemberApi
 * @extends {BaseAPI}
 */
export class MemberApi extends BaseAPI {
    /**
     * Destroy iron session.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public authSessionDestroy(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).authSessionDestroy(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get iron session.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public authSessionGet(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).authSessionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set iron session.
     * @summary 
     * @param {MemberAuthSessionSetInput} memberAuthSessionSetInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public authSessionSet(memberAuthSessionSetInput: MemberAuthSessionSetInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).authSessionSet(memberAuthSessionSetInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Data privacy endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public dataPrivacy(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).dataPrivacy(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member remove token of google two factor authentication.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public deleteSecret(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).deleteSecret(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member set google two factor authentication.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public generateSecret(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).generateSecret(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member verification status endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberActiveProgram(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberActiveProgram(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifies token and updates member\'s email.
     * @summary 
     * @param {MemberAuthEmailConfirmInput} memberAuthEmailConfirmInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberAuthEmailConfirm(memberAuthEmailConfirmInput: MemberAuthEmailConfirmInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberAuthEmailConfirm(memberAuthEmailConfirmInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifies member\'s email.
     * @summary 
     * @param {MemberAuthEmailVerifyInput} memberAuthEmailVerifyInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberAuthEmailUpdate(memberAuthEmailVerifyInput: MemberAuthEmailVerifyInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberAuthEmailUpdate(memberAuthEmailVerifyInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends welcome email to the given user.
     * @summary 
     * @param {string} uid User ID.
     * @param {MemberAuthEmailVerifyInput} memberAuthEmailVerifyInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberAuthInvite(uid: string, memberAuthEmailVerifyInput: MemberAuthEmailVerifyInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberAuthInvite(uid, memberAuthEmailVerifyInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Confirms member\'s invitation.
     * @summary 
     * @param {MemberAuthEmailConfirmInput} memberAuthEmailConfirmInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberAuthInviteConfirm(memberAuthEmailConfirmInput: MemberAuthEmailConfirmInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberAuthInviteConfirm(memberAuthEmailConfirmInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send an email for newsletter sign-up.
     * @summary 
     * @param {MemberAuthNewsletterSignupInput} memberAuthNewsletterSignupInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberAuthNewsletterSignup(memberAuthNewsletterSignupInput: MemberAuthNewsletterSignupInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberAuthNewsletterSignup(memberAuthNewsletterSignupInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Confirm newsletter sign-up.
     * @summary 
     * @param {any} token The signed token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberAuthNewsletterSignupConfirm(token: any, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberAuthNewsletterSignupConfirm(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates member\'s password.
     * @summary 
     * @param {MemberAuthPasswordUpdateInput} memberAuthPasswordUpdateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberAuthPasswordUpdate(memberAuthPasswordUpdateInput: MemberAuthPasswordUpdateInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberAuthPasswordUpdate(memberAuthPasswordUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send an authentication code to the user\'s phone to validate their phone number.
     * @summary 
     * @param {MemberAuthPhoneSendCodeInput} memberAuthPhoneSendCodeInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberAuthPhoneSendCode(memberAuthPhoneSendCodeInput: MemberAuthPhoneSendCodeInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberAuthPhoneSendCode(memberAuthPhoneSendCodeInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate the SMS code sent to the user\'s phone.
     * @summary 
     * @param {MemberAuthPhoneValidateCodeInput} memberAuthPhoneValidateCodeInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberAuthPhoneValidateCode(memberAuthPhoneValidateCodeInput: MemberAuthPhoneValidateCodeInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberAuthPhoneValidateCode(memberAuthPhoneValidateCodeInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send an email for preregistered user.
     * @summary 
     * @param {MemberAuthPreregisterInput} memberAuthPreregisterInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberAuthPreregister(memberAuthPreregisterInput: MemberAuthPreregisterInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberAuthPreregister(memberAuthPreregisterInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Confirm preregistered user registration.
     * @summary 
     * @param {any} token Preregister token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberAuthPreregisterConfirm(token: any, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberAuthPreregisterConfirm(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes Social provider.
     * @summary 
     * @param {MemberAuthProviderUnlinkInput} memberAuthProviderUnlinkInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberAuthProviderUnlink(memberAuthProviderUnlinkInput: MemberAuthProviderUnlinkInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberAuthProviderUnlink(memberAuthProviderUnlinkInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Recaptcha verifier endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberAuthRecaptchaVerifier(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberAuthRecaptchaVerifier(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifies token and resend password reset.
     * @summary 
     * @param {MemberAuthResendPasswordResetInput} memberAuthResendPasswordResetInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberAuthResendPasswordResetEmail(memberAuthResendPasswordResetInput: MemberAuthResendPasswordResetInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberAuthResendPasswordResetEmail(memberAuthResendPasswordResetInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Email domain and usage verification endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberAuthVerifyEmailDomain(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberAuthVerifyEmailDomain(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member consent sessions endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberConsentSessions(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberConsentSessions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member consent sessions delete endpoint.
     * @summary 
     * @param {string} [client] Consent session client ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberConsentSessionsDelete(client?: string, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberConsentSessionsDelete(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member contact preferences info endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberContactPreferences(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberContactPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member contact preferences update endpoint.
     * @summary 
     * @param {UserContactPreferencesInput} userContactPreferencesInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberContactPreferencesUpdate(userContactPreferencesInput: UserContactPreferencesInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberContactPreferencesUpdate(userContactPreferencesInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member info endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberInfo(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member invoices endpoint.
     * @summary 
     * @param {number} [limit] Number of items to return.
     * @param {any} [before] ID of the item to return items before.
     * @param {any} [after] ID of the item to return items after.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberInvoices(limit?: number, before?: any, after?: any, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberInvoices(limit, before, after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member military credentials info endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberMilitaryCredentials(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberMilitaryCredentials(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member payment method endpoint.
     * @summary 
     * @param {string} id Payment method ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberPaymentMethod(id: string, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberPaymentMethod(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member payment method create endpoint.
     * @summary 
     * @param {MemberPaymentMethodCreateInput} memberPaymentMethodCreateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberPaymentMethodCreate(memberPaymentMethodCreateInput: MemberPaymentMethodCreateInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberPaymentMethodCreate(memberPaymentMethodCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member payment method delete endpoint.
     * @summary 
     * @param {string} id Payment method ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberPaymentMethodDelete(id: string, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberPaymentMethodDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member payment method update endpoint.
     * @summary 
     * @param {any} id Payment method ID.
     * @param {MemberPaymentMethodUpdateInput} memberPaymentMethodUpdateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberPaymentMethodUpdate(id: any, memberPaymentMethodUpdateInput: MemberPaymentMethodUpdateInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberPaymentMethodUpdate(id, memberPaymentMethodUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member payment methods endpoint.
     * @summary 
     * @param {number} [limit] Number of items to return.
     * @param {any} [before] ID of the item to return items before.
     * @param {any} [after] ID of the item to return items after.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberPaymentMethods(limit?: number, before?: any, after?: any, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberPaymentMethods(limit, before, after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member profile endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberProfile(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member profile photo delete endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberProfilePhotoDelete(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberProfilePhotoDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member profile photo file endpoint.
     * @summary 
     * @param {string} token Member profile photo token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberProfilePhotoDownload(token: string, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberProfilePhotoDownload(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member profile photo info endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberProfilePhotoInfo(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberProfilePhotoInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member profile photo upload endpoint.
     * @summary 
     * @param {MemberProfilePhotoInput} memberProfilePhotoInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberProfilePhotoUpload(memberProfilePhotoInput: MemberProfilePhotoInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberProfilePhotoUpload(memberProfilePhotoInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member profile update endpoint.
     * @summary 
     * @param {MemberProfileInput} memberProfileInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberProfileUpdate(memberProfileInput: MemberProfileInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberProfileUpdate(memberProfileInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member verification status endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberVerificationStatus(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberVerificationStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Military branches endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public militaryBranches(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).militaryBranches(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Military ranks endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public militaryRanks(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).militaryRanks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member store token of google two factor authentication.
     * @summary 
     * @param {MemberTokenDto} memberTokenDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public storeSecret(memberTokenDto: MemberTokenDto, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).storeSecret(memberTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Timezones endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public timezones(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).timezones(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Auxiliary validation endpoint.
     * @summary 
     * @param {ValidateInput} validateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public validate(validateInput: ValidateInput, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).validate(validateInput, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerApi - axios parameter creator
 * @export
 */
export const PartnerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Member info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/userinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Member info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberInfo_1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/member`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerApi - functional programming interface
 * @export
 */
export const PartnerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerApiAxiosParamCreator(configuration)
    return {
        /**
         * Member info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberInfo200Response1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Member info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberInfo_1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberInfo200Response1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberInfo_1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerApi - factory interface
 * @export
 */
export const PartnerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerApiFp(configuration)
    return {
        /**
         * Member info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberInfo(options?: any): AxiosPromise<MemberInfo200Response1> {
            return localVarFp.memberInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Member info endpoint.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberInfo_1(options?: any): AxiosPromise<MemberInfo200Response1> {
            return localVarFp.memberInfo_1(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerApi - object-oriented interface
 * @export
 * @class PartnerApi
 * @extends {BaseAPI}
 */
export class PartnerApi extends BaseAPI {
    /**
     * Member info endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public memberInfo(options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).memberInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Member info endpoint.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public memberInfo_1(options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).memberInfo_1(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QRCodeApi - axios parameter creator
 * @export
 */
export const QRCodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * A member public profile URL QR code endpoint.
         * @summary 
         * @param {string} token A valid UUID representing the member\&#39;s public profile token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPublicProfile: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('memberPublicProfile', 'token', token)
            const localVarPath = `/qr-code/member-public-profile/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QRCodeApi - functional programming interface
 * @export
 */
export const QRCodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QRCodeApiAxiosParamCreator(configuration)
    return {
        /**
         * A member public profile URL QR code endpoint.
         * @summary 
         * @param {string} token A valid UUID representing the member\&#39;s public profile token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberPublicProfile(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberPublicProfile(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QRCodeApi - factory interface
 * @export
 */
export const QRCodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QRCodeApiFp(configuration)
    return {
        /**
         * A member public profile URL QR code endpoint.
         * @summary 
         * @param {string} token A valid UUID representing the member\&#39;s public profile token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberPublicProfile(token: string, options?: any): AxiosPromise<File> {
            return localVarFp.memberPublicProfile(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QRCodeApi - object-oriented interface
 * @export
 * @class QRCodeApi
 * @extends {BaseAPI}
 */
export class QRCodeApi extends BaseAPI {
    /**
     * A member public profile URL QR code endpoint.
     * @summary 
     * @param {string} token A valid UUID representing the member\&#39;s public profile token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QRCodeApi
     */
    public memberPublicProfile(token: string, options?: AxiosRequestConfig) {
        return QRCodeApiFp(this.configuration).memberPublicProfile(token, options).then((request) => request(this.axios, this.basePath));
    }
}


